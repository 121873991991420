import * as ticket from '../Actions/ticketActions'
import { getTranslate } from './getTranslate'
const jcc = require('json-case-convertor')


export const getTicket = async (code,keycode,store) => {

    var codeTicket = code + "$" + keycode;
    const apiUrl = './api/ticket/' + codeTicket;

    const response = await fetch(apiUrl, {
        method: 'GET',
        credentials: 'same-origin',
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            'Access-Control-Allow-Origin': '*'
        }
    });
   
    var result = await response.json();
    console.log("result  : ", result);

    result = jcc.camelCaseKeys(JSON.parse(result));
    console.log("result  camelCaseKeys: ", result);

    if (result !== null) {
        if (result.message !== null) {
            store.dispatch(ticket.updateCode('',''));
        }
        else {

            store.dispatch(ticket.updateState(result));
            store.dispatch(ticket.updateCode(result.code, result.keyCode));

            store.dispatch(ticket.updateLegalEntity(result.legalEntity));
            store.dispatch(ticket.updateisPrevalutation(result.isPrevalutation));
            store.dispatch(ticket.updateCanale(result.canale));

            if (result.state === 'f44dda94-00ff-47dc-b257-5f168225dacd') // Stato inoltrata
                store.dispatch(ticket.showDialogState(true));

            store.dispatch(ticket.setAppoint(result.appoint));
            store.dispatch(ticket.setDocuments(result.reqDocument));
            
            if (result.reqInformation !== null && result.reqInformation.request !== null) {
                //console.log("Call getTranslate");
                getTranslate(result.reqInformation.request, true,store).then(async (res) => {
                    result.reqInformation.request = res;
                    store.dispatch(ticket.setInfo(result.reqInformation));
                });
            }
           
            if (result.appoint !== null && result.appoint.isVisible && !result.appoint.isRefused && !result.appoint.isApproved) {
                store.dispatch(ticket.showDialogConvocation(true));                
            }
            
            if (result.reqInformation !== null && result.reqInformation.isVisible)
                store.dispatch(ticket.showDialogInfo(true)); 

            if (result.reqDocument !== null && result.reqDocument.isVisible && !result.reqDocument.isReceived) {
                store.dispatch(ticket.showDialogDocument(true));  
                                                
                if (result.reqDocument.list !== null) {
                    var i = 1;
                    for (let item of result.reqDocument.list) {
                        //store.dispatch(ticket.addAdditionalDocument(i, item, ''));                    
                        store.dispatch(ticket.addAdditionalDocument(item));
                        i++;
                    }
                }
            }           
        } 
    }          
  };