import React, { Component } from 'react'
import * as Helper from '../Helper';
import WizardPage0 from './WizardPage0';
import WizardPage1 from './WizardPage1';
import WizardPage2 from './WizardPage2';
import WizardPage3 from './WizardPage3';
import WizardPage4 from './WizardPage4';
import WizardPage5 from './WizardPage5';
import WizardPage6 from './WizardPage6';
import WizardPage7 from './WizardPage7';
import WizardPage8 from './WizardPage8';
//import WizardPage9 from './WizardPage9';

export class WizardPageComponents extends Component {
    render() {
        //console.log(this.props.step);        

        if(this.props.step === 0){
            return <WizardPage0 
                store = {this.props.store}
                namePrevious={Helper.BUTTON_NAME_EXIT} 
                nameNext={Helper.BUTTON_NAME_NEXT}
                nextPage={this.props.nextPage}
                previousPage = {this.props.previousPage}
                />; 
        }

        if(this.props.step === 1){
            return <WizardPage1 
                store = {this.props.store}
                disabled ={false}
                namePrevious={Helper.BUTTON_NAME_PREVIOUS} 
                nameNext={Helper.BUTTON_NAME_NEXT}
                nextPage={this.props.nextPage}
                previousPage = {this.props.previousPage}            
            />; 
        }       

        if(this.props.step === 2){     
            return <WizardPage2 
                store = {this.props.store}
                disabled ={false}
                namePrevious={Helper.BUTTON_NAME_PREVIOUS} 
                nameNext={Helper.BUTTON_NAME_NEXT}
                nextPage={this.props.nextPage}
                previousPage = {this.props.previousPage}              
                />; 
        }

        if(this.props.step === 3 ){     
            return <WizardPage3 
                store = {this.props.store}
                disabled ={false}
                namePrevious={Helper.BUTTON_NAME_PREVIOUS} 
                nameNext={Helper.BUTTON_NAME_NEXT}
                nextPage={this.props.nextPage}
                previousPage = {this.props.previousPage}              
                />; 
        }
        
        if(this.props.step === 4){     
            return <WizardPage4
                store = {this.props.store}
                disabled ={false}
                namePrevious={Helper.BUTTON_NAME_PREVIOUS} 
                nameNext={Helper.BUTTON_NAME_NEXT}
                nextPage={this.props.nextPage}
                previousPage = {this.props.previousPage}              
                />;
        }

        if(this.props.step === 4){     
            return <WizardPage4
                store = {this.props.store}
                disabled ={false}
                namePrevious={Helper.BUTTON_NAME_PREVIOUS} 
                nameNext={Helper.BUTTON_NAME_NEXT}
                nextPage={this.props.nextPage}
                previousPage = {this.props.previousPage}              
                />;                                  
        }

        if(this.props.step === 5 ){     
            return <WizardPage5
                store = {this.props.store}
                disabled ={false}
                namePrevious={Helper.BUTTON_NAME_PREVIOUS} 
                nameNext={Helper.BUTTON_NAME_NEXT}
                nextPage={this.props.nextPage}
                previousPage = {this.props.previousPage}              
                />;                                  
        }
        
        if(this.props.step === 6 ){     
            return <WizardPage6
                store = {this.props.store}
                disabled ={false}
                namePrevious={Helper.BUTTON_NAME_PREVIOUS} 
                nameNext={Helper.BUTTON_NAME_NEXT}
                nextPage={this.props.nextPage}
                previousPage = {this.props.previousPage}              
                />;                                       
        }

        if(this.props.step === 7 ){     
            return <WizardPage7
                store = {this.props.store}
                disabled ={false}
                namePrevious={Helper.BUTTON_NAME_PREVIOUS} 
                nameNext={Helper.BUTTON_NAME_SEND}
                nextPage={this.props.nextPage}
                previousPage = {this.props.previousPage}              
                />;        
        } 

        if(this.props.step === 8 ){     
            return <WizardPage8
                store = {this.props.store}          
                />;        
        }         
        
        return '';
        
    }
}

export default WizardPageComponents
