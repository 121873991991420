import * as actions from '../Actions/actionStep1';
const jcc = require('json-case-convertor')

/**
 * 
 * @param {any} store - redux store
 * @param {any} legalEntity - Selected LegalEntity
 * @description Get Channel from KRC
 */
export const getCanale = async (store, legalEntity) => {
    
    const apiUrl = './api/Canal?legalEntity=' + legalEntity + "&language="  + store.getState().ticketReducer.language;    

    await fetch(apiUrl, {
        method: 'GET',
        credentials: 'same-origin',
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            'Access-Control-Allow-Origin': '*'
        }
    })
    .then(response => response.json())
        .then((result) => {
            //console.log("getCanale : ", result);

            //if (result.length !== undefined) {
            //    store.dispatch(actions.loadCanali(jcc.camelCaseKeys(JSON.parse(result))));
            //}
            //else {
            //    store.dispatch(actions.loadCanali(null));
            //}
        
    })
    .catch((error) => {
        console.log('Fecth failed : ', error);
        return Promise.reject();
    });
};
