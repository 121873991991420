import * as actions from '../Actions/actionStep1';
const jcc = require('json-case-convertor')

/**
 * 
 * @param {any} store - redux store
 * @param {any} legalEntity - Selected LegalEntity
 */
export const getInfoForLegalEntity = async (store, legalEntity) => {

    const apiUrl = './api/InfoForLegalEntity?legalEntity=' + legalEntity;

    await fetch(apiUrl, {
        method: 'GET',
        credentials: 'same-origin',
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            'Access-Control-Allow-Origin': '*'
        }
    })
        .then(response => response.json())
        .then((result) => {
            console.log("getInfoForLegalEntity : ", result.canali);
            console.log("getInfoForLegalEntity : ", result.explicitConsent);
            console.log("getInfoForLegalEntity : ", result.signallerDataObligation);
            console.log("getInfoForLegalEntity : ", result.signallerIdentityConsent);

            if (result.canali.length !== undefined) {
                store.dispatch(actions.loadCanali(jcc.camelCaseKeys(JSON.parse(result.canali))));
                store.dispatch(actions.setExplicitConsent(result.explicitConsent));
                store.dispatch(actions.setSignallerDataObligation(result.signallerDataObligation));
                store.dispatch(actions.setSignallerIdentityConsent(result.signallerIdentityConsent));
            }
            else {
                store.dispatch(actions.loadCanali(null));
                store.dispatch(actions.setExplicitConsent("true"));
                store.dispatch(actions.setSignallerDataObligation("false"));
                store.dispatch(actions.setSignallerIdentityConsent("false"));
            }


        })
        .catch((error) => {
            console.log('Fecth failed : ', error);
            return Promise.reject();
        });
}