import * as actionTypes from './actionTypes';

export function updateLegalEntity(value) {
    return {
        type: 'UPDATE_LEGAL_ENTITY',
        payload: value
    }
}

export function updateisPrevalutation(value) {
    return {
        type: 'UPDATE_PREVALUTATION',
        payload: value
    }
}

export function updateCanale(value) {
    return {
        type: 'UPDATE_CANALE',
        payload: value
    }
}

export function updateResources(value) {
    return {
        type: 'LOAD_RESOURCES',
        payload: value
    }
}

export function updateRecorderAudio(value) {
    return {
        type: 'SET_RECORDER_AUDIO',
        payload: value
    }
}

export function updaterecorderAudioFile(value) {
    return {
        type: 'SET_RECORDER_AUDIO_FILE',
        payload: value
    }
}

export function setRecorderAudioUrl(value) {    
    return {
        type: 'SET_AUDIO_URL',
        payload: value
    }
}

export function setDisableRecorder(value) {
    return {
        type: 'DISABLE_RECORDER',
        payload: value
    }
}

export function updateState(result ) {    
    return {
        type: actionTypes.UPDATE_STATE,
        payload: {
            state: result.state,
            closed: result.closed,
            closeNotes: result.closeNotes,
            notesToReporting: result.notesToReporting
        }
    }
 }


 export function showDialogState(data) {    
    return {
       type: actionTypes.SHOW_DIALOG_STATE,
       payload : data
    }
 }

export function showDialogInfo(data) {
    return {
        type: actionTypes.SHOW_DIALOG_INFO,
        payload: data
    }
}

 export function showDialogConvocation(data) {    
    return {
       type: actionTypes.SHOW_DIALOG_CONVOCATION,
       payload : data
    }
 }

 export function showDialogDocument(data) {    
    return {
       type: actionTypes.SHOW_DIALOG_DOCUMENT,
       payload : data
    }
 }
/*
 export function addAdditionalDocument(id,description,note) {    
    return {
       type: actionTypes.ADD_ADDITIONAL_DOC,
       payload : {id : id, description : description, note : note}
    }
}
*/
export function addAdditionalDocument(doc) {
    return {
        type: actionTypes.ADD_ADDITIONAL_DOC,
        payload: doc
    }
}

export function updatePrivacy(value) {
    return {
        type: actionTypes.UPDATE_PRIVACY,
        payload: value
    }
}
export function updateIdentity(value) {
    return {
        type: actionTypes.UPDATE_IDENTITY,
        payload: value
    }
}

export function loadState(value) {
    return {
        type: actionTypes.LOAD_STATE,
        payload: value
    }
}

export function setAppoint(value) {
    return {
        type: actionTypes.SET_APPOINT,
        payload: value
    }
}

export function setDocuments(value) {
    return {
        type: actionTypes.SET_DOCUMENTS,
        payload: value
    }
}

export function setInfo(value) {
    return {
        type: actionTypes.SET_INFO,
        payload: value
    }
}

export function readKey(value) {
    return {
        type: actionTypes.READ_KEY,
        payload: value
    }
}

export function loading(value) {    
    return {
        type: actionTypes.LOADING,
        payload: value
    }
}

export function error(value) {
    return {
        type: actionTypes.ERROR,
        payload: value
    }
}

export function setEncryptedEsclude(value) {
    return {
        type: actionTypes.SET_ENCRYPTED_ESC,
        payload: value
    }
}

export function setLink(value) {
    return {
        type: actionTypes.SET_LINK,
        payload: value
    }
}

export function setFileMaxSize(value) {
    return {
        type: actionTypes.SET_FILE_MAX_SIZE,
        payload: value
    }
}

export function setlanguage(value) {
    return {
        type: "LANGUAGE",
        payload: value
    }
}
export function setCuture(value) {
    return {
        type: "CULTURE",
        payload: value
    }
}
export function setlanguageDefault(value) {
    return {
        type: "LANGUAGE_DEFAULT",
        payload: value
    }
}

export function setDataFormatlanguage(value) {
    return {
        type: "DATA_FORMAT_LANGUAGE",
        payload: value
    }
}

export function setTimeFormatlanguage(value) {
    return {
        type: "TIME_FORMAT_LANGUAGE",
        payload: value
    }
}

export function setLanguageInstalled(value) {
    return {
        type: "LANGUAGE_INSTALLED",
        payload: value
    }
}

export function setDisablePrevalutation(value) {
    return {
        type: "DISABLE_PREVALUTATION",
        payload: value
    }
}

export function setDisablePublicAdmin(value) {
    return {
        type: "DISABLE_PUBLIC_ADMIN",
        payload: value
    }
}

export function clean() {
    return {
        type: actionTypes.CLEAN,
    }
}

export function cleanInfo() {
    return {
        type: "CLEAN_INFO",
    }
}

export function LoadLegalEntity(value) {
    return {
        type: "LOAD_LEGALENTITY",
        payload: value
    }
}

export function setPublicAdmin(value) {
    return {
        type: "UPDATE_PUBLIC_ADMIN",
        payload: value
    }
}

export function updateCode(code, keyCode) {
    return {
        type: "UPDATE_CODE",
        payload: { code: code, keyCode: keyCode } 
    }
}

export function setMaxMinutes(value) {
    return {
        type: 'SET_MAX_MINUTES_TO_REGISTRATION',
        payload: value
    }
}

export function setTimeSecondsPre(value) {
    return {
        type: 'SET_TIME_SECONDS_PRE_TO_REGISTRATION',
        payload: value
    }
}







