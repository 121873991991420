import * as actionTypes from '../Actions/actionTypes'

const initialState = {
    Description: '',
    disabledNext : true
}


const step4Reducer = (state = initialState, action) => {
    switch (action.type) {

        case actionTypes.CLEAN:
            {
                return {
                    ...initialState,
                    disabledNext: true
                }
            }

        case "UPDATE_DISABLED_STEP5":
            {
                return {
                    ...state,
                    disabledNext: action.payload
                }
            }

        case actionTypes.UPDATE_DESCRIPTION: 
        {          
          return {
            ...state, 
            Description : action.payload
          }         
        }
                            
        default: 
          return state;
    }

  }
  
 export default step4Reducer;