import * as actionTypes from '../Actions/actionTypes'


const initialState = {
    Canale: '',
    Canali: '',
    ExplicitConsent:true,
    isPrevalutation: '',
    disabledNext: true,
    SignallerDataObligation: false,
    SignallerIdentityConsent: false
}


const step2Reducer = (state = initialState, action) => {
    switch (action.type) {

        case actionTypes.CLEAN:
            {
                return {
                    ...initialState,
                }
            }

        case "EXPLICIT_CONSENT":
            {
                return {
                    ...state,
                    ExplicitConsent: action.payload
                }
            }

        case "UPDATE_DISABLED_STEP2":
            {
                return {
                    ...state,
                    disabledNext: action.payload
                }
            }
            
        case actionTypes.SET_PREVALUTATION:
            {
                return {
                    ...state,
                    isPrevalutation: action.payload
                }
            }
        case actionTypes.LOAD_CANALI:
        {
                return {
                    ...state,
                    Canali: action.payload
            }
        }

       case actionTypes.UPDATE_CANALE: 
       {     
          const obj = {
              Canale : action.payload
          }             
          return Object.assign({}, state, obj) 
       }

        case actionTypes.SIGNALLERDATA_OBLIGATION:
            {
                return {
                    ...state,
                    SignallerDataObligation: action.payload
                }
            }

        case actionTypes.SIGNALLERIDENTITY_CONSENT:
            {
                return {
                    ...state,
                    SignallerIdentityConsent: action.payload
                }
            }

       default:
         return state;
    }

  }
  
 export default step2Reducer;