import React, { Component } from 'react'

import TextField from '@material-ui/core/TextField';
import {
    withRouter
  } from "react-router-dom";

import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Typography from '@material-ui/core/Typography'

import CircularProgress from '@material-ui/core/CircularProgress';

import * as api from '../API/getTicket'
import { CleanStore } from '../Actions/clean';
import { withTranslation } from "react-i18next";

class StepHome extends Component {
    constructor(props){
        super(props);
                    
        this.state = {        
            CodeTicket: '',
            KeyCodeTicket:'',
            Code: this.props.store.getState().ticketReducer.Code,
            KeyCode: this.props.store.getState().ticketReducer.KeyCode,
            openDialog: false,
            loading: false
        }         
    }

    componentWillUnmount() {
        this.unsubscribe();          
    } 

    componentDidMount = () => {
        this.unsubscribe = this.props.store.subscribe(() => {
            this.setState({
                Code: this.props.store.getState().ticketReducer.Code,
                KeyCode: this.props.store.getState().ticketReducer.KeyCode,
            })
        })         
    }

    handleClose = () => {
        this.setState({ openDialog: false})
      }     
    onShowAlert = () => {        
        this.setState({ openDialog: true})        
      } 
    onChange_codeTicket = (e) => {
        this.props.onTextChangeHandle(e.target.value);

        this.setState({CodeTicket : e.target.value})        
    }

    onChange_codeKeyTicket = (e) => {       
        this.props.onTextKeyChangeHandle(e.target.value);
        this.setState({ KeyCodeTicket: e.target.value })
    }
    onClick_ViewTicket =  () => {
        //this.handleNavBar();
        //console.log(this.state);
        if (this.state.CodeTicket.length <= 0 || this.state.KeyCodeTicket.length <= 0){
            this.onShowAlert()
        }
        else
        {        
            CleanStore(this.props.store);
            this.setState({ loading: true });

            api.getTicket(this.state.CodeTicket, this.state.KeyCodeTicket, this.props.store).then(() => {
                this.setState({ loading: false });
                if (this.state.Code.length <= 0 || this.state.KeyCode.length <=0 )
                    this.onShowAlert();
                else
                    //this.props.history.push('/View');
                    this.props.history.push({
                        pathname: '/View',
                        store: this.props.store
                    });
            });
        }  
     } 
    onClick_InsertTicket = () => {
        CleanStore(this.props.store);
        this.props.history.push('/Wizard')
      }   

    render() {
        const { t } = this.props;
        return (
            <div className="content">   
                {this.state.loading ?
                    <div className="centerSpinner">
                        <CircularProgress size={100} />
                    </div>
                    :
                    <div className="main">
                        <div className="row">
                            <Typography variant="h6">{t('Welcome')}</Typography>                            
                        </div>
                        <div className="divider"></div>
                        <div className="row">
                            <div className="col-6">
                                <Typography variant="p">{t('Click_View_Message')}</Typography>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-2">
                                <TextField id="codeTicket" label={t('lblCode')} value={this.state.CodeTicket} onChange={this.onChange_codeTicket} />
                            </div>
                            <div className="col-2">
                                <TextField id="keyTicket" label={t('lblKey')} value={this.state.KeyCodeTicket} onChange={this.onChange_codeKeyTicket} />
                            </div>
                            <div className="col-6">
                            </div>
                            <div className="col-2">
                                <Button className="buttonView" onClick={this.onClick_ViewTicket} color="primary" variant="contained" autoFocus>
                                    {t('Click_View')}
                                </Button>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-2">                                
                            </div>
                            <div className="col-2">
                            </div>
                            <div className="col-6">
                            </div>
                            <div className="col-2">
                                <Button className="buttonInsert" onClick={this.onClick_InsertTicket} color="primary" variant="contained" autoFocus>
                                    {t('Click_Insert')}
                                </Button>
                            </div>
                        </div>
                    </div>
                }

                <Dialog
                    open={this.state.openDialog}
                    onClose={this.handleClose}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description">
                    <DialogTitle id="alert-dialog-title">{t('MessageValidationHeader')}</DialogTitle>
                    <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        {t('MessageValidation')}
                    </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                    <Button onClick={this.handleClose} color="primary" autoFocus>
                        OK
                    </Button>
                    </DialogActions>
                </Dialog>                             
            </div>
        )
    }   
}

export default withRouter(withTranslation()(StepHome));
