import * as actionTypes from '../Actions/actionTypes'

const initialState = {
    Name : '',
    Surname : '',
    Email : '',
    Phone : '',
    isSend: '',
    idWantTransmitYourContacts:'',
    disabledNext: false,
    formErrors: {
        Name: '',
        Surname: '',
        Email: '',
        Phone: '',
    },
}


const step7Reducer = (state = initialState, action) => {
    switch (action.type) {

        case actionTypes.CLEAN:
            {
                return {
                    ...initialState,
                }
            }

        case actionTypes.UPDATE_NAME: 
        {          
          return {
            ...state, 
            Name : action.payload
          }         
        }
        case actionTypes.UPDATE_SURNAME: 
        {          
          return {
            ...state, 
            Surname : action.payload
          }         
        }  
        case actionTypes.UPDATE_EMAIL: 
        {          
          return {
            ...state, 
            Email : action.payload
          }         
        } 
        case actionTypes.UPDATE_PHONE: 
        {          
          return {
            ...state, 
            Phone : action.payload
          }         
        }
        case actionTypes.UPDATE_SEND: 
            {                   
                return {
                ...state, 
                    isSend: action.payload,
                    idWantTransmitYourContacts: (action.payload == 'Si' || action.payload == 'Yes'  ? 'Y' : 'N')
            }         
        }                                  

        case actionTypes.UPDATE_DISABLED_STEP8:
            {
                return {
                    ...state,
                    disabledNext: action.payload
                }
            }
            
        default: 
          return state;
    }

  }
  
 export default step7Reducer;