import * as actionTypes from './actionTypes';

export function next(Process) {
    return {
       type: actionTypes.NEXT_3,
       payload : {Process}
    }
 }

 export function remove(ProcessItem) {
   return {
      type: actionTypes.REMOVE_3,
      payload : ProcessItem
   }
} 

export function Add(ProcessItem) {
   return {
      type: actionTypes.ADD_3,
      payload : ProcessItem
   }
} 

export function AddNewProcess(newProcess) {    
    return {
       type: actionTypes.ADD_NEW_PROCESS,
       payload : newProcess
    }
 } 

 export function updateRows(rows) {    
    return {
       type: actionTypes.UPDATE_ROWS,
       payload : rows
    }
}

export function LoadProcess(value) {
    return {
        type: actionTypes.LOAD_PROCESS,
        payload: value
    }
}

export function setCheckNewProcessActive(value) {
    return {
        type: "UPDATE_CHECK_NEW_PROCESS",
        payload: value
    }
}

export function setDisabled(value) {
    return {
        type: "UPDATE_DISABLED",
        payload: value
    }
}

export function clean() {
    return {
        type: actionTypes.CLEAN,
    }
}



 
  
 
