import * as actionTypes from './actionTypes';

export function addFile(file) {    
    return {
       type: actionTypes.ADD_FILE,
       payload : file
    }
 }

 export function removeFile(file) {    
    return {
       type: actionTypes.REMOVE_FILE,
       payload : file
    }
}

export function clean() {
    return {
        type: actionTypes.CLEAN,
    }
}


