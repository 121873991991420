//import { createMuiTheme } from '@material-ui/core/styles';
import { createTheme } from '@material-ui/core/styles'

const theme = createTheme({
    defaultProps: {
        variant: {
            h1: 'h2',
            h2: 'h2',
            h3: 'h2',
            h4: 'h2',
            h5: 'h2',
            h6: 'h2',
            subtitle1: 'h2',
            subtitle2: 'h2',
            body1: 'span',
            body2: 'span',
        },
    },
    palette: {

        type: 'light',
        primary: {
            main: '#cd0e16',
            contrastText: "#FFFFFF",

        },
        secondary: {
            main: '#ff5040',
        },
        text: {
            primary: '#000000',
            disabled: '#000000',
        },
        
    },

    typography: {
        fontFamily: 'roboto',
        fontSize: 11.5,
        body1: {
            fontSize: 11.5,
        },
        button: {
            fontSize: 11
        },
    },

    props: {
        MuiCheckbox: {
            color: 'primary'
        },
        MuiRadio: {
            color: 'primary'
        },
        MuiSelect: {
            MenuProps: {                
                anchorOrigin: {
                    vertical: "bottom",
                    horizontal: "left"
                },
                getContentAnchorEl: null,
                PaperProps: {
                        style: {
                        maxHeight: 360,
                        maxWidth: 250,
                        paddingLeft: '10px'
                    },
                },
            },
        },
    },

   
});

export default theme