
import React from 'react'
//import { AudioRecorder, useAudioRecorder } from 'react-audio-voice-recorder';
import useAudioRecorder from "../hooks/useAudioRecorder";
import { AudioRecorder } from './AudioRecordingComponent';

/*
 * Component for recorder audio.
 * @details : property ArrayBytes, return arraybytes from file recorder
 * @details : captionLabel is caption visible in page
 * 
 * @prop labelSaveRecording
 * @prop labelStartRecording
 * @prop labelResumeRecording
 * @prop labelPauseRecording
 * @prop labelDiscardRecording
 * @prop captionLabel
 * @prop Audio
 * 
 * Function
 * @prop LoadAudio  - return url
 * @prop FileLoad   - return file 
 */
export default  function KrcAudioRecorder(props) {

    const recorderControls = useAudioRecorder();
    const [Url, setUrl] = React.useState(props.Audio);  
    const [isMessage, setIsMessage] = React.useState(false);
    const [countDown, setCountDown] = React.useState(0);    

    const addAudioElement = async (blob, props) => {
        
        const url = URL.createObjectURL(blob);
        setUrl(url)
        props.LoadAudio(url); 

        const audiofile = new File([recorderControls.recordingBlob], "audiofile.mp3", {
            type: "audio/mpeg",
        });

        props.FileLoad(audiofile);     
    };

    function getMessage(props, num) {
        var array = props.Message.split("$");

        if (array[num])
            return array[num];
        else {
            if (num === 0)
                return "Registration closes in ";
            else
                return "seconds!";
        }
    }

    React.useEffect(() => {

        var seconds = props.MaxMinutes * 60;

        if (countDown === 0)
            setCountDown(seconds);
        else
            setCountDown(countDown => countDown - 1);

        if (recorderControls.recordingTime > (seconds - props.TimeSecondsPre)) {
            setIsMessage(true);
        }

        if (recorderControls.recordingTime > seconds) {
            setIsMessage(false);
            recorderControls.stopRecording();
        }

    }, [recorderControls.recordingTime], countDown);

    return (
        <div id="recorderDetail">
            <AudioRecorder
                onRecordingComplete={(blob) => addAudioElement(blob, props)}
                recorderControls={recorderControls}
                labelSaveRecording={props.labelSaveRecording}
                labelStartRecording={props.labelStartRecording}
                labelResumeRecording={props.labelResumeRecording}
                labelPauseRecording={props.labelPauseRecording}
                labelDiscardRecording={props.labelDiscardRecording}
            />
            {Url && 
                <audio src={Url} controls="true" id="audioId" type="audio/mp3" />
            }
            {isMessage &&
                <div>
                    <br/>
                    <div>{getMessage(props, 0)}<span id="time">{countDown}</span>{getMessage(props, 1)}</div>
                </div>
            }
        </div>

    );
}
