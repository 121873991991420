
import React, { useState } from 'react'

import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import { useTranslation, withTranslation } from "react-i18next";
import Typography from '@material-ui/core/Typography'
import moment from 'moment'

function MessageInfo(props) {
    const Information = props.Information;
    const { t } = useTranslation();

    const [response, setResponse] = useState('');

    const localeFormatMap = props.Store.getState().ticketReducer.dataFormatLanguage;
    //const dateTime = new Date(props.dateExpiring).toUTCString();
    const dateTime = moment.utc(props.dateExpiring, 'yyyy/MM/DD hh:mm:ss').toDate();

    const handleClose = () => {
        props.handleClose();
    }

    const onChange_response = (e) => {
        setResponse(e.target.value);
    }

    const handleSend = () => {
        props.handleSend(response);
    }
    
    return (
        <div>
            {(props.openDialog === true &&
                <div className="popup-box">
                    <div>
                        <div className="box-header">
                            {/*<h3 id="dbox-header">{t('Label_MessageInfo_Header')}</h3>*/}
                            <Typography variant="h6">{t('Label_MessageInfo_Header')}</Typography>
                        </div>
                        <div className="box-header">
                            <div>
                                <div className="Row">
                                    <Typography variant="p">{t('Label_MessageInfo_RowDate1')} <b>{moment(dateTime).format(localeFormatMap.replace("dd", "DD").replace("d", "D"))}</b> {t('Label_MessageInfo_RowDate2')}</Typography>
                                </div>
                                <div className="row">
                                    <Typography variant="p">{t('Label_MessageInfo_Row1')}</Typography>
                                </div>
                                <div className="row">
                                    <Typography variant="p"><span dangerouslySetInnerHTML={{ __html: Information.request.replaceAll("\n", "<br>") }} /> </Typography>                   
                                </div>
                            </div>
                            <div className="row">
                            <TextField multiline={true} rows={10} className="width100" id="descEvent" label={t('Label_Response')} value={response} onChange={e => onChange_response(e)} />
                            </div> 
                            <div style={{ display: "flex" }}>
                                <Button onClick={handleSend} color="primary" autoFocus style={{ marginLeft: "auto" }}>
                                    {t('Button_Send')}
                                </Button>
                                <Button onClick={handleClose} color="primary" autoFocus style={{ marginLeft: "auto" }}>
                                    {t('Button_Close')}
                                </Button>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </div>
    )
}

export default withTranslation()(MessageInfo)
