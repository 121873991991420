import * as actionTypes from '../Actions/actionTypes'

const initialState = {
    DocumentiSegnalati : '',
    DocumentiPercorso : '',
    DocumentiAltraModalita : '',
    DocumentiAssenti : false,
    SegnalazioneAutorita: '',
    QualeAutorita: ''
}


const step6Reducer = (state = initialState, action) => {
    switch (action.type) {

        case actionTypes.CLEAN:
            {
                return {
                    ...initialState,
                }
            }


        case actionTypes.ADD_DOC_SEGNALATI: 
        {          
          return {
            ...state, 
            DocumentiSegnalati : action.payload
          }         
        }
        case actionTypes.ADD_DOC_PERCORSO: 
        {          
              return {
                ...state, 
                DocumentiPercorso : action.payload
              }         
        }  
        case actionTypes.ADD_DOC_ALTRA_MODALITA: 
        {          
              return {
                ...state, 
                DocumentiAltraModalita : action.payload
              }         
        } 
        case actionTypes.ADD_SEGNALA_AUTORITA: 
        {          
              return {
                ...state, 
                SegnalazioneAutorita : action.payload
              }         
        }
        case actionTypes.ADD_QUALE_AUTORITA:
        {
            return {
                ...state,
                QualeAutorita: action.payload
            }
        }
        case actionTypes.ADD_DOC_ASSENTI: 
        {          
          return {
            ...state, 
            DocumentiAssenti : action.payload
          }         
        }                                  
                                  
        default: 
          return state;
    }

  }
  
 export default step6Reducer;