import { Component } from 'react';
import React, { Suspense } from 'react';

import * as ticket from './Actions/ticketActions'

import './Custom.css';
import Wizard from './Container/Wizard';
import WizardPage8 from './Container/WizardPage8';
import StepHome from './Container/StepHome';
import ViewTicket from './Container/ViewTicket';
import {
  Switch,
  Route,
  Link,
  Redirect,
  withRouter
} from "react-router-dom";

import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import { Select, MenuItem, Grid } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import CircularProgress from '@material-ui/core/CircularProgress';
import { ThemeProvider } from '@material-ui/core/styles';
import theme from './Theme'

import { getLegalEntity } from './API/getLegalEntity';
import { getCategory } from './API/getCategory';
import { getViolazioni } from './API/getViolazioni';
import { getProcess } from './API/getProcess';
import { getTicket } from './API/getTicket';
import { setTicket } from './API/setTicket';
import { getState } from './API/getState';
import { getConfiguration } from './API/getConfiguration';
import { CleanStore } from './Actions/clean';
import ErrorPage from './Container/ErrorPage';
import ErrorConfigPage from './Container/ErrorConfigPage';
import { withTranslation } from "react-i18next";
import consoleOverride from "./Utils/console";

consoleOverride();

class App extends Component{
  constructor(props){
    super(props);
      
      this.state = {            
            loading: this.props.store.getState().ticketReducer.loading,
            error: this.props.store.getState().ticketReducer.error,
            language: this.props.store.getState().ticketReducer.language,
            culture: this.props.store.getState().ticketReducer.language,
            countries: this.props.store.getState().ticketReducer.languageInstalled,          
            activeStep : 0,
            CodeTicket: '',
            KeyTicket:'',
            openDialog : false,
            isActive: false            
      };
  }

    componentWillUnmount() {
        this.unsubscribe();
    }  


    componentDidMount = () => {
        this.unsubscribe = this.props.store.subscribe(() => {
            this.setState({
                loading: this.props.store.getState().ticketReducer.loading,
                error: this.props.store.getState().ticketReducer.error,
                language: this.props.store.getState().ticketReducer.language,
                culture: this.props.store.getState().ticketReducer.culture,
                countries: this.props.store.getState().ticketReducer.languageInstalled
            })
        })

        this.loadItems("");
    }


    loadItems = (language) => {    
        this.props.store.dispatch(ticket.loading(true));  

        getConfiguration(this.props.store, language)
            .then(() => {               
                Promise.all([
                    getLegalEntity(this.props.store),
                    getCategory(this.props.store),
                    getViolazioni(this.props.store),
                    getProcess(this.props.store),
                    getState(this.props.store),
                ]).then(() => {
                        this.props.store.dispatch(ticket.loading(false));

                    }).catch((e) => {
                        console.log("APP : ",e);

                        this.props.store.dispatch(ticket.error(true));
                        this.props.store.dispatch(ticket.loading(false));

                        this.props.history.push('/Error')
                    }); 
            }).catch((e) => {                                
                console.log("APP :", e);

                this.props.store.dispatch(ticket.error(true));
                this.props.store.dispatch(ticket.loading(false));                
                this.props.history.push('/ErrorConfig')
            });       
    }

  onStep = (step) => {    
    this.setState({activeStep:step})
  }
  handleClose = () => {
    this.setState({ openDialog: false})
  }  

  handleNavBar = () => {
    this.setState({ isActive: !this.state.isActive });  
  }
  onClick_ViewTicket = () => {
    //this.handleNavBar();

      if(this.state.CodeTicket.length <= 0){
          this.onShowAlert()
      }
      else
      {
        getTicket(this.state.CodeTicket,this.props.store) 
      }  
  }
  onShowAlert = () => {
    this.setState({ openDialog: true})
    }

    onTextChangeHandle = (value) => {
        this.setState({CodeTicket : value})
    }

    onTextKeyChangeHandle = (value) => {
        this.setState({ KeyTicket: value })
    }

    handleSubmit = () => {
      this.props.store.dispatch(ticket.loading(true));  

      setTicket(this.props.store)
          .then(() => {
              this.props.store.dispatch(ticket.loading(false));  
              this.props.history.push('/SendTicket')
          })
          .catch(() => {
              this.props.store.dispatch(ticket.error(true));
              this.props.store.dispatch(ticket.loading(false));  
              this.props.history.push('/Error')
          });    
    }
    handle_Home = () => {
        CleanStore(this.props.store);
    }
    language_handleChange = (e) => {   
        console.log("Language : ", e.target.value);
        //getLanguage(this.props.store, e.target.value);

        this.loadItems(e.target.value);        
    }

    getLinkHome = () => {
        if (this.state.error === true)
            return "#";
        else
            return "/";
    }

    render() {        
        const { t } = this.props;
        return (            
            <Suspense fallback="loading">                
                <ThemeProvider theme={theme}>
                    <div className="box">
                        <div>
                            <AppBar className='nav-bar' position="static" style={{ background: '#FFFF' }}  elevation={0}>
                                <Toolbar style={{ display: "flex", justifyContent: "space-between" }}>
                                    <div style={{ width: "100%" }}>
                                        <div className="col-3">                                            
                                            <Link to={this.getLinkHome()}>
                                                <img src='logo.png' />
                                            </Link>  
                                         </div>
                                    
                                        <div className="col-6">
                                            <Select
                                                name="country"
                                                onChange={this.language_handleChange}
                                                value={this.state.culture}
                                                style={{ bottom: 0}}
                                            >                                                
                                                {this.state.countries.map((item, key) => (
                                                    <MenuItem value={item.id} key={key}>
                                                        <img src={item.src} alt={item.label} />
                                                    </MenuItem>
                                                ))}
                                            </Select>
                                        </div>
                                        <div className="col-2">                                  
                                            <Link to={this.getLinkHome()}>
                                                <img src='whistleblowing.png' float="right" />
                                            </Link>                                    
                                        </div>
                                     </div>
                                </Toolbar>                                                     
                             </AppBar>   
                        </div>
                        <div className="content" id="contentDiv">
                            <div>
                                {this.state.loading ?
                                    <div className="centerSpinner">
                                        <CircularProgress size={100} />
                                    </div>
                                    :
                                    <div>
                                        <Switch>
                                            <Route exact path="/"
                                                render={() => {
                                                    return (
                                                        <Redirect to="/Home" />
                                                    )
                                                }}
                                            />
                                            <Route path="/Home">
                                                <StepHome CodeTicket={this.state.CodeTicket} KeyCodeTicket={this.state.KeyTicket} store={this.props.store} onTextChangeHandle={this.onTextChangeHandle} onTextKeyChangeHandle={this.onTextKeyChangeHandle}/>
                                            </Route>
                                            <Route path="/Wizard">
                                                <Wizard store={this.props.store} activeStep={this.state.activeStep} onStep={this.onStep} onSubmit={this.handleSubmit} />
                                            </Route>
                                            <Route exact path="/View">{
                                                this.state.CodeTicket.length > 0 ?
                                                    <ViewTicket
                                                        store={this.props.store}
                                                        CodeTicket={this.state.CodeTicket}
                                                        namePrevious='' nameNext='' nextPage='' previousPage='' /> :
                                                    <Redirect to={{
                                                        pathname: "/Home",
                                                        CodeTicket: this.state.CodeTicket
                                                    }} />
                                            }
                                            </Route>
                                            <Route exact path="/SendTicket">
                                                <WizardPage8 store={this.props.store} />
                                            </Route>
                                            <Route path="/Error">
                                                <ErrorPage />
                                            </Route>
                                            <Route path="/ErrorConfig">
                                                <ErrorConfigPage />
                                            </Route>
                                        </Switch>
                                    </div>
                                }
                            </div>
                            <Dialog
                                open={this.state.openDialog}
                                onClose={this.handleClose}
                                aria-labelledby="alert-dialog-title"
                                aria-describedby="alert-dialog-description"
                            >
                                <DialogTitle id="alert-dialog-title">{"Messaggio di anomalia"}</DialogTitle>
                                <DialogContent>
                                    <DialogContentText id="alert-dialog-description">
                                        {t('MessageValidation')}
                                    </DialogContentText>
                                </DialogContent>
                                <DialogActions>
                                    <Button onClick={this.handleClose} color="primary" autoFocus>
                                        OK
                                </Button>
                                </DialogActions>
                            </Dialog>
                        </div>
                    </div>
              
                </ThemeProvider>
            </Suspense>
            
      )      
  }
}

export default withRouter(withTranslation()(App));
