import React, { Component } from 'react'

import Input from "@material-ui/core/Input";
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import Checkbox from '@material-ui/core/Checkbox';
import ButtonSection from '../Components/ButtonSection'
import TextField from '@material-ui/core/TextField';
import Chip from '@material-ui/core/Chip';
import Typography from '@material-ui/core/Typography'

import * as actions from '../Actions/actionStep3'
import * as Helper from '../Helper';
import SubjectsGrid from '../Components/SubjectsGrid'
import { withTranslation } from "react-i18next";

export class WizardPage3 extends Component {
    constructor(props){
        super(props);
                    
        this.state = {  
            disabledNext: this.props.store.getState().step3Reducer.disabledNext, 
            legalEntityList: this.props.store.getState().ticketReducer.legalEntityList,
            legalEntity: this.props.store.getState().ticketReducer.legalEntity,  

            ProcessList: this.props.store.getState().step3Reducer.ProcessList,
            Process : this.props.store.getState().step3Reducer.Process,            
            newProcess: this.props.store.getState().step3Reducer.newProcess, 
            newProcessActive: this.props.store.getState().step3Reducer.newProcessActive, 
            Rows : this.props.store.getState().step3Reducer.Rows,
            formErrors : {
                Process : ""            
            },
            /*
            dataService : {
                Process : api.getProcess()
            }
            */
        }

        this.classes = {
            chips: {
                display: "flex",
                flexWrap: "wrap"
              },
              chip: {
                margin: 2
              }            
        }

        const ITEM_HEIGHT = 80;
        const ITEM_PADDING_TOP = 8;
        this.MenuProps = {
          PaperProps: {
            style: {
              maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
              width: 250,
              paddingLeft: '10px'
            }
          }
        };          
    }    
    
    componentWillUnmount() {
        this.unsubscribe();
        }    
        
    componentDidMount = () => {                
        this.unsubscribe = this.props.store.subscribe(() => {              
            this.setState({   
                disabledNext: this.props.store.getState().step3Reducer.disabledNext, 
                legalEntityList: this.props.store.getState().ticketReducer.legalEntityList,
                ProcessList: this.props.store.getState().step3Reducer.ProcessList,
                Process : this.props.store.getState().step3Reducer.Process,
                newProcess: this.props.store.getState().step3Reducer.newProcess,
                newProcessActive: this.props.store.getState().step3Reducer.newProcessActive, 
                Rows : this.props.store.getState().step3Reducer.Rows,
            })     
        })
    }     
    getProcessRoot = (value) => {
        var ret = value;

        for (let item of this.state.legalEntityList) {
            if (item.key === value) {
                ret = item.processRoot;                
            }
            
        }

        return ret;
    }
    makeItemProcess = () => {        
        let data = []
        const ProcessRoot = this.getProcessRoot(this.state.legalEntity);
        if (ProcessRoot)
            data = this.state.ProcessList?.children?.filter(el => el.id === ProcessRoot);        

        const items = [];  
        const { t } = this.props;

        //console.log(data);

        for (let soc of data) {
            if (soc.children !== null) {
                for (let item of soc.children) {
                    items.push(
                        <div>
                            <FormControlLabel key={item.id}
                                control={<Checkbox disabled={this.props.disabled} value={item.id} name={item.value} key={item.id} id={item.id}
                                    onChange={this.checkBoxChanged} checked={this.state.Process.indexOf(item.id) > -1} />}
                                label={item.value}
                            />
                        </div>
                    );
                    if (item.children !== null) {

                        for (let child of item.children) {
                            items.push(
                                <MenuItem key={child.id} value={child.id} name={child.value} >
                                    <Checkbox disabled={this.props.disabled} key={child.id} value={child.id} name={child.value} inputProps={{ 'data-id': item.id }}
                                        onChange={this.checkBoxChanged}
                                        checked={this.state.Process.indexOf(child.id) > -1} />
                                    {child.value}
                                </MenuItem>
                            );
                        }
                    }
                }
            }
        }
        /*
        for (let item of data) {  
            if (item.children !== null)
            {
                for (let child of item.children) { 
                    items.push(
                        <FormControlLabel key={child.id + '#0'} 
                            control={<Checkbox disabled={this.props.disabled} value={child.id} name={child.value} key={child.id}
                                onChange={this.checkBoxChanged} checked={this.state.Process.indexOf(child.id) > -1}/>}
                            label={child.value}
                        />
                    );
                    
                    if (child.children !== null)
                    {
                        for (let proc of child.children) {
                            items.push(
                                <MenuItem key={proc.id + '#0'} value={proc.id} name={proc.value}>
                                    <Checkbox disabled={this.props.disabled} key={proc.id} value={proc.id} name={proc.value} inputProps={{ 'data-id': child.id }}
                                        onChange={this.checkBoxChanged} 
                                        checked={this.state.Process.indexOf(proc.id) > -1}/>
                                    {proc.value}
                                </MenuItem>
                            );
                        }
                    }
                }
            }
        }
        */

          // Add altro
          items.push(
            <FormControlLabel key="9999"
                control={<Checkbox value="9999" 
                onChange={this.checkBoxChanged} checked={this.state.Process.indexOf("9999") > -1}/>}
                  label={t('Label_Process_Other')}
            />
        );          
        return items;          
    };
    getLabelChip = (value) => {
        const data = this.state.ProcessList;         
        
        if(value === "9999")
            return this.props.t('Label_Process_Other');         

        if (data !== null) {
            for (let item of data.children) {
                if (item.id === value)
                    return item.value;
                if (item.children !== null) {
                    for (let child of item.children) {
                        if (child.id === value)
                            return child.value;

                        if (child.children !== null) {
                            for (let proc of child.children) {
                                if (proc.id === value)
                                    return proc.value;
                            }
                        }
                    }
                }
            }
        }
       
        return '';
    }   
    checkError = () => {
        var ret = false;
        var process = "";
        const { t } = this.props;

     
        if(this.state.Process.length === 0) 
        {
            ret = true;            
            process = t(Helper.FIELD_MANDATARY);
        }        

        this.setState({formErrors : {
                Process : process
            }
        }, () => {
            //console.log("checkError",this.state)
        });

        return ret;        
    };  
    onNext = (event) => { 
        // Validate
        if(!this.checkError())      
        {            
            this.props.nextPage(event);
        }
    };  
    onChange_newProcess = (e) => {   
        //console.log(e.target.value) ;               
        this.props.store.dispatch(actions.AddNewProcess(
            e.target.value));  

        this.checkValidate();
    }
    previousPage = (e) => {
        this.props.previousPage(e);
    };

    checkBoxChanged = (e, v) => {    
        var rootId = e.target.getAttribute("data-id");


        if (e.target.value === "9999" && e.target.checked === true) {
            this.props.store.dispatch(actions.setCheckNewProcessActive(true));
        }

        if (e.target.value === "9999" && e.target.checked === false) {
            this.props.store.dispatch(actions.setCheckNewProcessActive(false));
        }

        if(e.target.checked === true)
        {
            if (rootId !== null) {
                var isExist = false
                for (var i = 0; i <= this.state.Process.length; i++) {
                    if (this.state.Process[i] === rootId) {
                        isExist = true;
                        break;
                    }
                }
                if (!isExist) {
                    this.props.store.dispatch(actions.Add(
                        rootId
                    ));
                }
            }

            this.props.store.dispatch(actions.Add(               
                e.target.value          
            ));  
        }
        else
        {            
            this.props.store.dispatch(actions.remove(               
                e.target.value
            ));             
        }

        this.checkValidate();
    };  
    onChangeRow =(rows) => {
        // Update Rows

        this.props.store.dispatch(actions.updateRows(
            rows));         
    }          

    // Validation step
    checkValidate = () => {
        
        if (this.props.store.getState().step3Reducer.newProcessActive === false ||
            (this.props.store.getState().step3Reducer.newProcessActive === true && this.props.store.getState().step3Reducer.newProcess.length > 0)) {      
            this.props.store.dispatch(actions.setDisabled(false));
        }
        else {            
            this.props.store.dispatch(actions.setDisabled(true));
        }

        return;
    };
    render() {
        const { Process, Rows, newProcess, formErrors } = this.state 
        const { t } = this.props;

        return(
            <div>
                <div className="main">
                    <div className="row"> 
                        <FormControl className="width100">               
                            <InputLabel id="Process-select">{t('Label_Process')}</InputLabel>
                            <Select disabled={this.props.disabled} 
                                labelId="Process-select"
                                id="Process-select"                                                                                                                           
                                multiple                                
                                value={Process} 
                                input={<Input id="select-multiple-chip" />}
                                renderValue={ (selected) => (
                                    <div className="chips">
                                      {selected.map((value) => (
                                        <Chip className="chip" 
                                            size="small"
                                            key={value} 
                                            label={
                                                <InputLabel id={value}>{
                                                    this.getLabelChip(value)}
                                                </InputLabel>
                                            }                                             
                                        />
                                      ))}
                                    </div>
                                  )}                                                                                                   
                                >                                          
                                {this.makeItemProcess()}                                
                            </Select>
                        </FormControl> 
                        {formErrors.Process.length > 0 && (
                                <span className="errorMessage">{formErrors.Process}</span>
                            )}                                           
                    </div>   
                    <div className="row">
                        {this.state.Process.indexOf("9999") > -1 && 
                            <TextField disabled={this.props.disabled} className="width100" id="newProcess" label={t('Label_Process_Other')} value={newProcess} onChange={this.onChange_newProcess} onBlur={this.onChange_newProcess} />}
                    </div> 
                    <div className="row">
                        <Typography variant="h6">{t('Label_Person_Subjects')}</Typography>
                    </div>
                    <div className="row">
                        <SubjectsGrid disabled={this.props.disabled} rows={Rows} onChangeRow={this.onChangeRow}/>
                    </div>                     
                </div>   
                {this.props.previousPage.length > 0 &&           
                    <ButtonSection 
                        disabledNext={this.state.disabledNext}
                        previousPage={this.previousPage}   
                        nextPage={this.props.nextPage}                                                 
                        namePrevious={this.props.namePrevious}                            
                        nameNext={this.props.nameNext}
                    />  
                }
            </div>
        )
    }
}

export default withTranslation()(WizardPage3)