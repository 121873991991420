import { encryptedFunction, encryptedFunctionBinary } from '../Encrypted/encrypted'
import { readUploadedFileAsByte } from './manage'

/**
 * 
 * @param {any} files
 * @param {any} key
 */
export const setDocumentsAttach = async (files, key) => {
    
        var DocumentsAttach = [];        

        for (let i = 0; i < files.length; i++) {
            var file = files[i];
                        
            var fileName = await encryptedFunction(file.name, key); 

            var bytes = await readUploadedFileAsByte(file);                        
            var content = await encryptedFunctionBinary(bytes, key);            
                        
            var data = fileName + "§-§" + content            

            DocumentsAttach.push(data);    
        }

        return DocumentsAttach;
}

