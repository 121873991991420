import React, { Component } from 'react'
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import WizardPageComponents from './WizardPageComponents';
import * as Helper from '../Helper';
import { withRouter } from "react-router-dom";

import * as ticket from '../Actions/ticketActions'
import { withTranslation } from "react-i18next";

export class Wizard extends Component {
    constructor(props) {
        super(props);

        this.nextPage = this.nextPage.bind(this)
        this.previousPage = this.previousPage.bind(this)

        this.state = {
            error: this.props.store.getState().ticketReducer.error,
          currentStep: 0,
          nameNext : Helper.BUTTON_NAME_NEXT,
          namePrevious : Helper.BUTTON_NAME_CLOSE
        };        
    }

    componentWillUnmount() {
        this.unsubscribe();
    }

    componentDidMount = () => {
        this.unsubscribe = this.props.store.subscribe(() => {
            this.setState({                
                error: this.props.store.getState().ticketReducer.error,
            })
        })
    }
     nextPage = (e) => {
        e.preventDefault()        

         try {
             var lenght = Helper.Steps.length - 2;

             if (this.state.currentStep < lenght) {
                 this.setState({
                     currentStep: this.state.currentStep + 1,
                 });
             }
             else {
                 this.setState({
                     currentStep: this.state.currentStep + 1,
                 });
             }
             this.props.onStep(this.state.currentStep);
         }
         catch (err) {
             console.log(err);

             this.props.store.dispatch(ticket.error(true));
             this.props.history.push('/Error');
         }
      }
      

      previousPage = (e) => {
        e.preventDefault()   
           
        const {currentStep} = this.state;
        //console.log("previousPage",currentStep);  
        if(currentStep > 0)
        {
            this.setState({
                currentStep: currentStep - 1,
            });
        }

        this.props.onStep(this.state.currentStep);
        if(currentStep === 0){
            this.props.history.push('/Home')
        }
    }
   
    render() {        
        const {currentStep } = this.state;
        const { onSubmit } = this.props;
        const { t } = this.props;

        return (                      
            <div>
                <div className="stepperView">
                    <Stepper activeStep={currentStep} alternativeLabel>
                        {Helper.Steps.map((step, i) => (
                            <Step key={i}>
                                <StepLabel>{t(step)}</StepLabel>
                            </Step>
                        ))}
                    </Stepper> 
                </div>
                <form onSubmit={onSubmit}> 
                    <div>  
                        <WizardPageComponents 
                            store = {this.props.store}
                            step= {currentStep} 
                            nextPage={(this.state.currentStep < (Helper.Steps.length - 1) ? this.nextPage : this.props.onSubmit )} 
                            previousPage ={this.previousPage}/> 
                        <div className="divider"></div>         
                    </div>  
                </form>               
            </div>            
        )
    }
}

export default withRouter(withTranslation()(Wizard))
