
export default function consoleOverride (){
    var _log = console.log;
    var _error = console.error;
    var _warning = console.warning;

    console.error = function (errMessage) {
        _error.apply(console, arguments);
    };
    console.warning = function (warnMessage) {
        _warning.apply(console, arguments);
    };
    console.log = function (logMessage) {
        // Do something with the log message
        if (process.env.NODE_ENV !== 'production') { 
            _log.apply(console, arguments);
        }
    };
};