import * as actionTypes from '../Actions/actionTypes'

const initialState = {
    Categories: [],
    ViolationList : [],
    Category: '',
    Violazioni: [],
    newViolazione: '',
    newViolazioneActive:false,
    disabledNext: true
}


const step2Reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.CLEAN:
            {
                return {
                    ...state,
                    Category: '',
                    Violazioni: [], 
                    newViolazione: '',
                    newViolazioneActive:false,
                    disabledNext : true
                }
            }

        case "REMOVE_ALL_VIOLATIONS":
            {
                return {
                    ...state,
                    Violazioni: []
                }
            }
            

        case "UPDATE_CHECK_VIOLAZIONI":
            {
                return {
                    ...state,
                    newViolazioneActive: action.payload
                }
            }

        case "UPDATE_DISABLED_STEP3":
            {
                return {
                    ...state,
                    disabledNext: action.payload
                }
            }

        case "ADD_NEW_VIOLAZIONI":
            {
                return {
                    ...state,
                    newViolazione: action.payload,
                }
            }  
        case actionTypes.LOAD_VIOLATIONS:
            {
                //console.log("LOAD_VIOLATIONS", action.payload);
                return {
                    ...state,
                    ViolationList: action.payload
                }
            }
        case actionTypes.LOAD_CATEGORIES:
            {
                return {
                    ...state,
                    Categories: action.payload
                }
            }
        case actionTypes.REMOVE_2: 
        {          
          return {
            ...state,
            Category : state.Category,
            Violazioni : [
              ...state.Violazioni.filter(element => element !== action.payload)
            ]
          }         
        }
        case actionTypes.ADD_2: 
        {              
          return {
            ...state,
            Category : state.Category,
            Violazioni : [...state.Violazioni,action.payload]
          }          
        }
        case actionTypes.NEXT_2: 
        {              
          return {
            ...state,
            Category : action.payload.Category,
            Violazioni: action.payload.Violazioni 
          }          
        }        

        default: 
          return state;
    }

  }
  
 export default step2Reducer;