import * as ticketActions from '../Actions/ticketActions'

import configureI18n from "../i18nextConf";
/**
 * 
 * @param {any} store
 */
export const getConfiguration = async (store, culture) => {

    const apiUrl = './api/Configuration?culture=' + culture;
    await fetch(apiUrl, {
        method: 'GET',
        credentials: 'same-origin',
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            'Access-Control-Allow-Origin': '*'
        }
    })
        .then(response => response.json())
        .then((result) => {
            console.log("Configuration : ", result.value);
         
            store.dispatch(ticketActions.setDisablePrevalutation(result.value.showPrevalutation));
            store.dispatch(ticketActions.setDisablePublicAdmin(result.value.showPublicAdmin));

            store.dispatch(ticketActions.setFileMaxSize(result.value.maxSizeFile));
            store.dispatch(ticketActions.setDisableRecorder(result.value.showRecorder));
            store.dispatch(ticketActions.setEncryptedEsclude(result.value.encryptedEsclude.list));

            store.dispatch(ticketActions.setlanguage(result.value.language));
            store.dispatch(ticketActions.setCuture(result.value.culture));

            store.dispatch(ticketActions.setDataFormatlanguage(result.value.cultures.find(c => c.id == result.value.culture).shortDateFormat)); 
            store.dispatch(ticketActions.setTimeFormatlanguage(result.value.cultures.find(c => c.id == result.value.culture).shortTimePattern)); 
            store.dispatch(ticketActions.setlanguageDefault(result.value.languageDefault));
            store.dispatch(ticketActions.setLanguageInstalled(result.value.cultures));
       
            store.dispatch(ticketActions.setMaxMinutes(result.value.maxMinutes));
            store.dispatch(ticketActions.setTimeSecondsPre(result.value.timeSecondsPre));

            configureI18n(result.value.language, result.value.resources);
        })
        .catch((error) => {
            console.log("getConfiguration : ", error);
            return Promise.reject();
        });

  
}