import * as actions from '../Actions/actionStep3'

/**
 * 
 * @param {any} store - redux store
 * @description Get Process from KRC

 */
export const getProcess = async (store) => {
    const apiUrl = './api/Process?Language=' + store.getState().ticketReducer.language;

        await fetch(apiUrl, {
            method: 'GET',
            credentials: 'same-origin',
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Access-Control-Allow-Origin': '*'
            }
        })
            .then(response => response.json())
            .then((result) => {

                store.dispatch(actions.LoadProcess(result));
            })
            .catch((error) => {
                console.log('getProcess Fecth failed : ', error);
                return Promise.reject();
            });

  };