import React, { Component } from 'react'
import errorImg from '../img/error.png';
import Typography from '@material-ui/core/Typography'
import { withTranslation } from "react-i18next";

export class ErrorConfigPage extends Component {

    reload = () => {

    }
    render() {
        const { t } = this.props;
        return (
            <div>
                <div className="main">
                    <div className="error">
                        <img src={errorImg} />
                        <Typography variant="h3">{t('Error_Configuration')}</Typography>
                    </div>
                    <div className="divider" />
                </div>
            </div>
        )
    }
}

export default withTranslation()(ErrorConfigPage)
//export default ErrorConfigPage
