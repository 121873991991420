import React from 'react';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography'
import { useTranslation } from "react-i18next";

import TextField from '@material-ui/core/TextField';

export default function PersonGrid(props) {
    const { t } = useTranslation();

    //Get functions from groups
    const functions = props.groups.map( (group) => { return group.name;}).filter((v) => { return v.length > 0 });

    return (
        <React.Fragment>
            <div className="row">
                <TextField className="width100" disabled label="" value={functions} />
            </div>  
            <div className="row">
                <TableContainer component={Paper}>
                    <Table size="small">
                        <TableHead>
                            <TableRow>
                                <TableCell align="left">{t('Label_Function')}</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {
                                props.groups.map(
                                    (group) => { 
                                        return (
                                            group.showMembers !== "false" ?
                                                group.value.map((row, i) => (
                                                    <TableRow key={'row' + i} id={row.code}>
                                                        <TableCell align="left" key={'row' + i}>
                                                            <Typography variant="p">{row.name}{row.title.length > 0 ? " (" + row.title + ")" : ""}</Typography>
                                                        </TableCell>
                                                    </TableRow>)) :
                                                <TableRow>
                                                    <TableCell align="left" >
                                                        <Typography variant="p">{group.name}</Typography>
                                                    </TableCell>
                                                </TableRow>
                                        );
                                    }
                                )
                            }
                          
                        </TableBody>
                    </Table>
                </TableContainer>
            </div>
        </React.Fragment>
    );


    
}