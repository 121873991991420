
export const BUTTON_NAME_NEXT = "Button_Next";
export const BUTTON_NAME_PREVIOUS = "Button_Previous";
export const BUTTON_NAME_CLOSE = "Button_Close";
export const BUTTON_NAME_SEND = "Button_Send";
export const BUTTON_NAME_EXIT = "Button_Exit";

export const Steps  = [
    'Step0', //Inizio
    'Step1', //Canale Segnalazione
    'Step2', //Ambito della Segnalazione
    'Step3', //Dati Aziendali
    'Step4', //Descrizione Evento
    'Step5', // Allegati
    'Step6', //Documenti
    'Step7']; //Invio

export const PUBLIC_ADMIN_SCADENZA180 = "(Questa risposta abilita la scadenza a 180 giorni)"
export const PUBLIC_ADMIN_SCADENZA90 = "(Questa risposta abilita la scadenza a 90 giorni)"

export const FIELD_MANDATARY = "Label_field_Mandatary"

export const API_URL_UPLOAD = ''

export const MAX_LOADING_PROC = 4
export const MAX_SIZE_FILE = 20240 

// State of TIcket
export const ATTESA = "d54414ea-6dae-484a-9b8b-2be671f66eba";
export const INOLTRATA = "f44dda94-00ff-47dc-b257-5f168225dacd";
export const RICEVUTA_IN_VERIFICA = "f31fb7ae-5eac-43cb-85bd-cac1d5b3e8c3";

// State of Action
export const ACCETTATA = "ab824689-455b-4bc3-89e7-22982c37a724";
export const RIFIUTATA = "e5bd66b2-acfc-4e17-960e-73cea7f3b50d";
export const NUOVA_DATA_PROPOSTA = "e96a297b-361f-43cc-a446-169f53a34f64";
export const RICEVUTI_NO = "0a1795d9-22c5-4e8b-a727-266814ef175c";
export const RICEVUTI = "e7550006-26e5-4027-be16-4bf51d3fb698";
export const RICEVUTI_PARZIALMENTE = "faa38c7d-83d5-4d13-b0be-46a928d9b4c1";
export const COMPILATO = "c6f3c967-7f6a-431b-8c9c-101e124b07b9";
export const COMPILATO_NO = "6de138a5-d376-48ff-a99c-244b01e46e5d";
