import React, { Component } from 'react'
import { Link } from '@material-ui/core';
import ButtonSection from '../Components/ButtonSection'
import FormControlLabel from '@material-ui/core/FormControlLabel';

import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import RadioGroup from '@material-ui/core/RadioGroup';
import Radio from '@material-ui/core/Radio';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import * as api from '../API/getCanale'
import * as apiInfo from '../API/getInfoForLegalEntity'

import * as actionsViolations from '../Actions/actionStep2'
import Checkbox from '@material-ui/core/Checkbox';
import * as actions from '../Actions/ticketActions'
import Typography from '@material-ui/core/Typography'

import * as Helper from '../Helper';
import '../Custom.css';
import { withTranslation } from "react-i18next";


export class StepInformation extends Component {
    constructor(props){
        super(props);
                        
        this.state = {  
            //disabledNext: !this.props.store.getState().ticketReducer.privacy,
            privacy: this.props.store.getState().ticketReducer.privacy,
            identity: this.props.store.getState().ticketReducer.identity,
            link: this.props.store.getState().ticketReducer.link,
            link: this.props.store.getState().ticketReducer.link,
            Canali: this.props.store.getState().step1Reducer.Canali, 
            ExplicitConsent: this.props.store.getState().step1Reducer.ExplicitConsent, 
            legalEntityList: this.props.store.getState().ticketReducer.legalEntityList,
            legalEntity: this.props.store.getState().ticketReducer.legalEntity,
            publicAdmin: this.props.store.getState().ticketReducer.publicAdmin,
            showPublicAdmin: this.props.store.getState().ticketReducer.showPublicAdmin,
            SignallerDataObligation: this.props.store.getState().step1Reducer.SignallerDataObligation,
            SignallerIdentityConsent: this.props.store.getState().step1Reducer.SignallerIdentityConsent,

            formErrors : {
                privacy: "",              
                legalEntity: "",
                publicAdmin: "",
            }
        }
    }

    componentWillUnmount() {
        this.unsubscribe();
    }
    
    componentDidMount = () => {                
        this.unsubscribe = this.props.store.subscribe(() => {              
            this.setState({                                     
                privacy: this.props.store.getState().ticketReducer.privacy,
                identity: this.props.store.getState().ticketReducer.identity,
                Canali: this.props.store.getState().step1Reducer.Canali, 
                ExplicitConsent: this.props.store.getState().step1Reducer.ExplicitConsent, 
                legalEntityList: this.props.store.getState().ticketReducer.legalEntityList,
                legalEntity: this.props.store.getState().ticketReducer.legalEntity,
                publicAdmin: this.props.store.getState().ticketReducer.publicAdmin,
                SignallerDataObligation: this.props.store.getState().step1Reducer.SignallerDataObligation,
                SignallerIdentityConsent: this.props.store.getState().step1Reducer.SignallerIdentityConsent,

                showPublicAdmin: this.props.store.getState().ticketReducer.showPublicAdmin,
            })     
        })
    }     
            
    onChange_Privacy = (e) => {
        console.log("onChange_Privacy");

        this.props.store.dispatch(actions.updatePrivacy(e.target.checked));  
        //this.setState({ disabledNext: !e.target.checked });
                
        if (this.state.legalEntity && this.state.Canali !== null && this.state.Canali.length > 0 && (!this.state.SignallerIdentityConsent || this.state.identity) && e.target.checked)
            this.setState({ disabledNext: false });
        else
            this.setState({ disabledNext: true });

    }

    onChange_Identity = (e) => {
        console.log("onChange_Identity");

        this.props.store.dispatch(actions.updateIdentity(e.target.checked));
        //this.setState({ disabledNext: !e.target.checked });

        if (this.state.legalEntity && this.state.Canali !== null && this.state.Canali.length > 0 && this.state.privacy && e.target.checked)
            this.setState({ disabledNext: false });
        else
            this.setState({ disabledNext: true });

    }

    onNext = (event) => { 
        // Validate        
        if ((this.state.privacy && this.state.ExplicitConsent) || this.state.ExplicitConsent === false)      
        {                
            this.props.nextPage(event);
        }
    };

    renderSelectOptions_LegalEntity = () => {
        //const dataLegalEntity = api.getLegalEntity();
        return this.state.legalEntityList.map((dt, i) => {
            return (
                <MenuItem
                    label="Select "
                    value={dt.key}
                    key={i}
                    id={dt.key}>{dt.value}</MenuItem>
            );
        });
    };

    onChangeLegalEntity = (e) => {
        e.preventDefault();

        this.props.store.dispatch(actions.updatePrivacy(false));  
        this.props.store.dispatch(actions.updateIdentity(false));  

        this.props.store.dispatch(actionsViolations.removeAll());

        this.props.store.dispatch(actions.updateLegalEntity(
            e.target.value));

        if (e.target.value.length > 0) {  

            apiInfo.getInfoForLegalEntity(this.props.store, e.target.value).then(() => {


                if (this.state.ExplicitConsent) {

                    if (this.state.privacy && this.state.Canali !== null && this.state.Canali.length > 0)
                        this.setState({ disabledNext: false });
                    else
                        this.setState({ disabledNext: true });
                }
                else
                    this.setState({ disabledNext: false });
                
            });

            //api.getCanale(this.props.store, e.target.value).then(() => {  

            //    //console.log("this.state.Canali ", this.state.Canali);

            //    if (this.state.privacy && this.state.Canali !== null && this.state.Canali.length > 0)
            //        this.setState({ disabledNext: false });
            //    else
            //        this.setState({ disabledNext: true });
            //});

        }            
        else
            this.setState({ disabledNext: true });

        //console.log(e.target.value);
        
        //this.props.store.dispatch(actions.next(
        //    e.target.value,
        //    this.state.PublicAdmin));

        //api.getCanale(this.props.store, e.target.value).then(() => {
        //    if (this.state.PublicAdmin !== '')
        //        this.setState({ disabledNext: false });
        //});
    };

    onChangeRadioPublicAdmin = (event) => {
        this.props.store.dispatch(actions.setPublicAdmin(event.target.value));

        //if (this.state.LegalEntity !== '' && this.props.store.getState().step2Reducer.Canali.length > 0)
        //    this.setState({ disabledNext: false });
    };     

    render() {

        const { privacy, identity, link, legalEntity, publicAdmin, formErrors } = this.state
        const { t } = this.props;

        const linkDirettiva = this.state.legalEntity && this.state.legalEntityList && this.state.legalEntityList.find( l => l.key ==  this.state.legalEntity ).linkDirettiva;
        const linkInformativa = this.state.legalEntity && this.state.legalEntityList && this.state.legalEntityList.find( l => l.key ==  this.state.legalEntity ).linkInformativa;
        const linkModello231 = this.state.legalEntity && this.state.legalEntityList && this.state.legalEntityList.find(l => l.key == this.state.legalEntity).linkModello231;
        const linkProcedure = this.state.legalEntity && this.state.legalEntityList && this.state.legalEntityList.find(l => l.key == this.state.legalEntity).linkProcedure;

        return (
            <div>
                <div className="main">
                    <div className="row">
                        <Typography variant="h6">{t('Remind_you_that')}</Typography>
                        <Typography variant="p">{t('Message_Introduction')}</Typography>  
                    </div>
                    <div className="row">
                        <FormControl className="width100">
                            <InputLabel id="label_LegalEntity">{t('Label_LegalEntity')}</InputLabel>
                            <Select
                                disabled={this.props.disabled}
                                labelId="label_LegalEntity"
                                id="LegalEntity-select"
                                value={legalEntity}
                                onChange={(e) => this.onChangeLegalEntity(e)}
                            >
                                {this.renderSelectOptions_LegalEntity()}
                            </Select>
                        </FormControl>
                        {formErrors.legalEntity.length > 0 && (
                            <span className="errorMessage">{formErrors.legalEntity}</span>
                        )}
                    </div> 
                    {
                        linkDirettiva && 
                        <div className="row">
                            <Link target='_blank' href={linkDirettiva}>
                                <Typography variant="p">{t('Link_Directive')}</Typography>
                            </Link> 
                        </div>
                    }
                    
                    {
                        linkInformativa && 
                        <div className="row">                        
                            <Link target='_blank' href={linkInformativa}>
                                <Typography variant="p">{t('Link_Informative')}</Typography>
                            </Link>
                        </div>
                    }
                    
                    {
                        linkModello231 && 
                        <div className="row">                           
                            <Link target='_blank' href={linkModello231}>
                                <Typography variant="p">{t('Link_Model')}</Typography>
                            </Link>  
                        </div>
                    }
                    {
                        linkProcedure &&
                        <div className="row">
                            <Link target='_blank' href={linkProcedure}>
                                <Typography variant="p">{t('Link_Procedure')}</Typography>
                            </Link>
                        </div>
                    }

                    {this.state.ExplicitConsent === true && this.state.legalEntity && (                        
                        <div className="row">
                            <FormControlLabel 
                                control={<Checkbox disabled={this.props.disabled}  checked={privacy}  onChange={e => this.onChange_Privacy(e)}/>}
                                label={t('Label_Autorizzation')}  />                           
                        </div> 
                    )}

                    <div>                                                                                
                        {this.state.ExplicitConsent && this.state.privacy && this.state.showPublicAdmin && ( 
                            <div>
                                <div className="row">
                                    <FormControl component="fieldset">
                                        <FormLabel component="legend">{t('Label_PublicAdmin')}</FormLabel>
                                        <RadioGroup aria-label="PublicAdmin" name="PublicAdmin" value={publicAdmin} onChange={this.onChangeRadioPublicAdmin}>
                                            <FormControlLabel disabled={this.props.disabled} value="Si" control={<Radio />} label={t('Label_PublicAdmin_Yes')} />
                                            <FormControlLabel disabled={this.props.disabled} value="No" control={<Radio />} label={t('Label_PublicAdmin_No')} />
                                        </RadioGroup>
                                    </FormControl>
                                </div>
                                <div className="row">
                                    {formErrors.publicAdmin.length > 0 && (
                                        <span className="errorMessage">{formErrors.publicAdmin}</span>
                                    )}
                                </div>  
                            </div>
                        )}
                    </div>   
                    
                    {this.state.SignallerIdentityConsent === true && this.state.legalEntity && (
                        <div className="row">
                            <FormControlLabel
                                control={<Checkbox disabled={this.props.disabled} checked={identity} onChange={e => this.onChange_Identity(e)} />}
                                label={t('Label_Signaller_Identity_Consent')} />
                        </div>
                    )}

                </div>
                <div className="row">
                    <ButtonSection
                        disabledNext={this.state.disabledNext}
                        previousPage={this.props.previousPage}
                        nextPage={this.onNext}
                        namePrevious={this.props.namePrevious}
                        nameNext={this.props.nameNext}
                    />  
                </div>                                                  
            </div>
        )
    }
}

export default withTranslation()(StepInformation)
