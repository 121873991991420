import React from 'react'

import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography'


function MessageState(props) {  


    const handleClose = () => {
        props.handleClose();
    }
    return (
        <div>
            {(props.openDialog === true && 
            <div className="popup-box">                
                <div>
                    <div className="box-header">
                        {/*<h3 id="dbox-header">Notifica Segnalazione</h3> */} 
                        <Typography variant="h6">Notifica Segnalazione</Typography>
                    </div>
                    <div>
                        <div className="row">                  
                            <Typography variant="p">Gentile Segnalante,</Typography>
                        </div>
                        <div className="row">
                            <Typography variant="p">la tua segnalazione identificata con il codice : <b>{props.CodeTicket}</b> è stata ricevuta e presa in carico da Responsabile della segnalazione
                            che procederà ad analizzare i contenuti.</Typography>
                        </div>
                        <div className="row">
                            <Typography variant="p"></Typography>
                        </div>
                        <div className="row">
                            <Typography variant="p"><b>La invitiamo a mantenere monitorata la presente pagina in quanto unico canale  per la comunicazione di eventuali esigenze di approfondimento
                                che dovessero rendersi necessarie.</b></Typography>
                        </div>
                        <div className="row">
                            <Typography variant="p">Ai fini di una verifica nel merito della sua segnalazione, nel pieno rispetto di ogni prescrizione normativa e di un corretto trattamento
                                dei Suoi dati personali, le ricordiamo :</Typography>                       
                        </div>
                        <div className="row">
                            <ul>
                                <li>potranno essere coinvolti i soggetti specialisti in materia (cd. Istruttori)</li>
                                <li>potranno esserLe richiesti degli approfondimenti sia in formato scritto che, qualora possibile, attraverso incontri personali,</li>
                                <li>potranno esserLe richiesti ulteriori documenti o dati ritenuti utili alle indagini.</li>
                            </ul>
                        </div>
                        <div className="row">
                            <Typography variant="p">Qualora i richiesti approfondimenti non trovassero riscontro da parte Sua entro i termini utili, che le saranno indicati, per il completamento delle indagini,
                                la Segnalazione da Lei inoltrata potrà essere chiusa.</Typography>    
                        </div>
                    </div>
                    <div style={{ display: "flex" }}>
                        <Button onClick={handleClose} color="primary" autoFocus style={{marginLeft: "auto"}}>
                            Close
                        </Button> 
                     </div>                       
                </div>
            </div>
            )}
        </div>
    )
}

export default MessageState
