import { combineReducers } from 'redux'
import  step1Reducer  from './step1Reducer'
import  step2Reducer  from './step2Reducer'
import  step3Reducer  from './step3Reducer'
import  step4Reducer  from './step4Reducer'
import  step5Reducer  from './step5Reducer'
import  step6Reducer  from './step6Reducer'
import  step7Reducer  from './step7Reducer'
//import  step8Reducer  from './step8Reducer'
import  ticketReducer  from './ticketReducer'


export const rootReducer = combineReducers({
	step1Reducer,
    step2Reducer,
    step3Reducer,
    step4Reducer,
    step5Reducer,
    step6Reducer,
    step7Reducer,    
    ticketReducer
})
