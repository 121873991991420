import React, { Component } from 'react'

import ButtonSection from '../Components/ButtonSection'
import KrcAudioRecorder from '../Components/KRCAudioRecorder'

import TextField from '@material-ui/core/TextField';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';

import * as actions from '../Actions/actionStep4'
import * as actionsTicket from '../Actions/ticketActions'

import * as Helper from '../Helper';
import { withTranslation } from "react-i18next";
import Typography from '@material-ui/core/Typography'

export class WizardPage4 extends Component {
    constructor(props){
        super(props);
                    
        this.state = {           
            disabledNext: this.props.store.getState().step4Reducer.disabledNext, 
            Description: this.props.store.getState().step4Reducer.Description,
            showRecorder: this.props.store.getState().ticketReducer.showRecorder,
            recorderAudioCheck: this.props.store.getState().ticketReducer.recorderAudioCheck,
            recorderAudioFile: this.props.store.getState().ticketReducer.recorderAudioFile,
            maxMinutes: this.props.store.getState().ticketReducer.maxMinutes,
            timeSecondsPre: this.props.store.getState().ticketReducer.timeSecondsPre,
            AudioUrl: this.props.store.getState().ticketReducer.AudioUrl,
            formErrors : {
                Description : ""            
            },
        }         
    }    
    
    componentWillUnmount() {
        this.unsubscribe();
        }    
        
    componentDidMount = () => {                
        this.unsubscribe = this.props.store.subscribe(() => {              
            this.setState({    
                disabledNext: this.props.store.getState().step4Reducer.disabledNext, 
                Description: this.props.store.getState().step4Reducer.Description,
                showRecorder: this.props.store.getState().ticketReducer.showRecorder,
                recorderAudioCheck: this.props.store.getState().ticketReducer.recorderAudioCheck,
                recorderAudioFile: this.props.store.getState().ticketReducer.recorderAudioFile,
                AudioUrl: this.props.store.getState().ticketReducer.AudioUrl,
                maxMinutes: this.props.store.getState().ticketReducer.maxMinutes,
                timeSecondsPre: this.props.store.getState().ticketReducer.timeSecondsPre,
            })     
        })
    }     
  
    checkError = () => {
        var ret = false;
        var description = "";
        const { t } = this.props;

        if (this.state.Description.length === 0 && this.state.recorderAudioCheck === false) 
        {
            ret = true;            
            description = t(Helper.FIELD_MANDATARY);
        }        

        if (this.state.recorderAudioCheck === true && (this.state.recorderAudioFile === null || this.state.recorderAudioFile.length === 0)) {
            ret = true;
            description = t(Helper.FIELD_MANDATARY);
        }        

        this.setState({formErrors : {
                Description : description
            }
        }, () => {
            //console.log("checkError",this.state)
        });

        return ret;        
    };  
    onNext = (event) => { 
        // Validate
        if(!this.checkError())      
        {            
            this.props.nextPage(event);
        }
    };  
    previousPage = (e) => {            
        this.props.previousPage(e);
    };  

    onChange_description = (e) => {

        this.props.store.dispatch(actions.updateDescription(e.target.value));  

        if (this.state.Description.length > 0) 
            this.props.store.dispatch(actions.setDisabled(false));        
        else
            this.props.store.dispatch(actions.setDisabled(true));
    }      

    onChangeRecorderAudio = (e) => {
        this.props.store.dispatch(actionsTicket.updateRecorderAudio(e.target.checked));    

        if (e.target.checked === false && this.state.Description.length === 0)
            this.props.store.dispatch(actions.setDisabled(true));
    }

    loadFileRecorder = (file) => {
     
        this.props.store.dispatch(actions.setDisabled(false));
        this.props.store.dispatch(actionsTicket.updaterecorderAudioFile(file));                     
    }

    loadAudio = (audio) => {        
        this.props.store.dispatch(actionsTicket.setRecorderAudioUrl(audio));  
    }

    render() {
        const { Description, recorderAudioCheck, AudioUrl, formErrors, showRecorder } = this.state;
        const { t } = this.props;
        console.log("showRecorder ", showRecorder);
        return(
            <div>
                <div className="main">                     
                    <div className="row">
                        {showRecorder === true && (
                            <div>
                                <div className="row">
                                    <Typography variant="p">{t('Label_recorderAudioCheck_Line1')}</Typography>
                                </div>
                                <div className="row">
                                    <Typography variant="p">{t('Label_recorderAudioCheck_Line2')}</Typography>
                                </div>

                                <div className="row">
                                    <FormControlLabel
                                        control={<Checkbox checked={recorderAudioCheck} onChange={e => this.onChangeRecorderAudio(e)} />}
                                            label={t('Label_RecorderAudio')} />
                                </div>
                            </div>
                        )}
                    </div>
                    <div className="row">
                        {recorderAudioCheck === false && (
                            <TextField disabled={this.props.disabled} multiline={true} rows={20} className="width100" id="descEvent" variant="outlined" label={t('Label_Description')} value={Description} onChange={this.onChange_description} />
                        )}
                        {formErrors.Description.length > 0 && (
                                <span className="errorMessage">{formErrors.Description}</span>
                        )}                           
                        {showRecorder === true && recorderAudioCheck === true && (
                            <KrcAudioRecorder FileLoad={this.loadFileRecorder} captionLabel={t('Label_StopRecording')} Audio={AudioUrl} LoadAudio={this.loadAudio}
                                MaxMinutes={this.state.maxMinutes} TimeSecondsPre={this.state.timeSecondsPre} Message={t('Label_RegistrationClose')}
                                labelSaveRecording={t('Label_SaveRecording')}
                                labelStartRecording={t('Label_StartRecording')}
                                labelResumeRecording={t('Label_ResumeRecording')}
                                labelPauseRecording={t('Label_PauseRecording')}
                                labelDiscardRecording={t('Label_DiscardRecording')}
                            />                            
                        )}
                    </div>                     
                </div>        
                {this.props.previousPage.length > 0 &&      
                    <ButtonSection 
                        disabledNext={this.state.disabledNext}
                        previousPage={this.previousPage}   
                        nextPage={this.onNext}                                                 
                        namePrevious={this.props.namePrevious}                            
                        nameNext={this.props.nameNext}
                    />  
                }
            </div>
        )
    }
}

export default withTranslation()(WizardPage4)