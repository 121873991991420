import React, { Component } from 'react'

import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import Typography from '@material-ui/core/Typography'

import FormControl from '@material-ui/core/FormControl';
import ButtonSection from '../Components/ButtonSection'
import Button from '@material-ui/core/Button';

import PersonGrid from '../Components/PersonGrid'
import * as actions from '../Actions/actionStep1'
import * as Helper from '../Helper';
import { withTranslation } from "react-i18next";

export class WizardPage1 extends Component {
    constructor(props){
        super(props);
                        
        this.state = {                           
            disabledNext: this.props.store.getState().step1Reducer.disabledNext, 
            Canale: this.props.store.getState().step1Reducer.Canale,                
            Canali: this.props.store.getState().step1Reducer.Canali, 
            Prevalutazione: this.props.store.getState().step1Reducer.isPrevalutation,
            showPrevalutation: this.props.store.getState().ticketReducer.showPrevalutation,
            formErrors : {
                Canale: "",  
                Prevalutazione:"",
            }
        }
    }
     
    componentWillUnmount() {
        this.unsubscribe();
     }    
        
    componentDidMount = () => {                
        this.unsubscribe = this.props.store.subscribe(() => {              
            this.setState({                   
                disabledNext: this.props.store.getState().step1Reducer.disabledNext,
                Canale: this.props.store.getState().step1Reducer.Canale, 
                Canali: this.props.store.getState().step1Reducer.Canali,
                Prevalutazione: this.props.store.getState().step1Reducer.isPrevalutation,
                showPrevalutation: this.props.store.getState().ticketReducer.showPrevalutation,
            })     
        })
        window.scrollTo(0, 0);
        var myDiv = document.getElementById('main');
        myDiv.scrollTop = 0;
            
    }    
    
    onNext = (event) => { 
        // Validate
        
        if (!this.checkError()) {
            this.props.nextPage(event);
        }
        else {
            document.getElementById("pre-select").scrollIntoView();
        }
    };

    handleClick_Canale = (e, canale) => {   
        //console.log("handleClick");

        if (this.props.store.getState().ticketReducer.showPrevalutation === false)
            this.props.store.dispatch(actions.setPrevalutation(false));

        this.props.store.dispatch(actions.updateCanale(canale));

        if (this.state.Prevalutazione !== '' || this.props.store.getState().ticketReducer.showPrevalutation === false) {
            //this.setState({ disabledNext: false });
            this.props.store.dispatch(actions.setDisabled(false));            
        }
            
    }
    handleClick_NoPre = (e, iter) => {
        if (iter === 1) {
            this.props.store.dispatch(actions.setPrevalutation(true));
        }
        else {
            this.props.store.dispatch(actions.setPrevalutation(false));
        }

        if (this.state.Canale !== '') {
            //this.setState({ disabledNext: false });
            this.props.store.dispatch(actions.setDisabled(false));            
        }
            

        //this.props.store.dispatch(actions.updateCanale(''));
    }
    checkError = () => {
        
        var ret = false;        
        var canale = "";  
        var prevalutazione = "";
        const { t } = this.props; 


        if(this.state.Canale.length === 0) 
        {
            ret = true;
            canale = t(Helper.FIELD_MANDATARY);     
        }       

        if (this.state.Prevalutazione.length === 0) {
            ret = true;
            prevalutazione = t(Helper.FIELD_MANDATARY);
        }          

        this.setState({formErrors : {                
            Canale: canale, 
            Prevalutazione: prevalutazione
            }
        });

        return ret;
               
    };
    
    render() {       
        const { Canale, Canali, Prevalutazione, formErrors, showPrevalutation} = this.state
        const { t } = this.props; 
       
        return (
            <div>
                <div id="main" className="main">    
                    <div className="row">                        
                        <Typography variant="p">{t('Label_Preliminar')}</Typography>
                    </div>
                    {(showPrevalutation === true &&

                        <div>
                         <Typography variant="h6">{t('Label_ValPreliminare')}</Typography>
                            <PersonGrid {...Canali[0]} />

                        </div>
                    )}


                    <div >
                        <Typography variant="h6">{t('Label_RespSegnalazione')}</Typography>
                        <PersonGrid {...Canali[1]} />
                    </div>

                    <div className="row">
                        <Typography variant="h6">{t('Label_RespSegnalazioneAlt')}</Typography>
                        <PersonGrid {...Canali[2]} />
                    </div>                    

                    {(showPrevalutation === true &&
                        <div>
                            <div className="row">
                                <Typography variant="h6">{t('Label_GroupPrevalutation')} {Canali[0].function}</Typography>
                            </div> 
                            <div className="row">                                
                                <div className="col-4">
                                    
                                        <Button id="Button1" className="button70" color="secondary" disabled={(Canali[0].function.length === 0)} variant="contained" autoFocus onClick={e => this.handleClick_NoPre(e, 1)}>
                                            {t('Label_GroupPrevalutation_no')}
                                        </Button>

                                </div>
                                
                                <div className="col-4">
                                    <Button id="Button2" className="button70" color="secondary" variant="contained" autoFocus onClick={e => this.handleClick_NoPre(e, 2)}>
                                        {t('Label_GroupPrevalutation_yes')}
                                    </Button>
                                </div>
                                
                            </div>
                    
                            <div className="row">
                                <FormControl className="width50">
                                    <InputLabel id="label_Pre">{t('Label_Prevalutation')}</InputLabel>
                                    <Select disabled
                                        labelId="label_Pre"
                                        id="pre-select"
                                        value={Prevalutazione}
                                    >
                                        <MenuItem value={''}></MenuItem>
                                        <MenuItem value={true}>{t('Label_PrevalutationMenu_Yes')}</MenuItem>
                                        <MenuItem value={false}>{t('Label_PrevalutationMenu_No')}</MenuItem>
                                    </Select>
                                </FormControl>
                            </div>           
                        </div>
                    )}

                    {formErrors.Prevalutazione.length > 0 && (
                        <span className="errorMessage">{formErrors.Prevalutazione}</span>
                    )}                     
                    <div className="divider"></div>
                    <div className="row">
                        <Typography variant="h6">{t('Label_Canal_Direct')} {Canali[1].function}</Typography>
                    </div>  
                    <div className="row">
                        <div className="col-4">                            
                                <Button id="Button3" className="button70" color="secondary" disabled={(Canali[1].function.length === 0)}  variant="contained" autoFocus onClick={e => this.handleClick_Canale(e, 2)}>
                                    {t('Label_Canal_Direct_No')}
                                </Button>                            
                        </div>   
                        <div className="col-4">                            
                                <Button id="Button4" className="button70" color="secondary" disabled={(Canali[2].function.length === 0)}  variant="contained" autoFocus onClick={e => this.handleClick_Canale(e, 3)}>
                                    {t('Label_Canal_Direct_Yes')}
                                </Button>
                        </div> 
                    </div> 
                    <div className="divider"></div>
                    <div className="row">
                        <FormControl className="width50">      
                            <InputLabel id="label_Canale">{t('Label_Canal')}</InputLabel>
                            <Select disabled                                    
                                labelId="label_Canale"
                                id="Canale-select"
                                value={Canale}                                                              
                                >
                                <MenuItem value={1}>{t('Label_Canal_1')}</MenuItem>
                                <MenuItem value={2}>{t('Label_Canal_2')}</MenuItem>
                                <MenuItem value={3}>{t('Label_Canal_3')}</MenuItem>
                            </Select>
                        </FormControl>                                                                            
                    </div>             
                    {formErrors.Canale.length > 0 && (
                            <span className="errorMessage">{formErrors.Canale}</span>
                    )}                                                                                                        
                </div>
                {this.props.previousPage.length > 0 &&
                    <ButtonSection 
                        disabledNext={this.state.disabledNext}
                        previousPage={this.props.previousPage}   
                        nextPage={this.onNext}                                                 
                        namePrevious={this.props.namePrevious}                            
                        nameNext={this.props.nameNext}
                    /> 
                } 
            </div>
        )
    }
}

export default withTranslation()(WizardPage1)
