import * as actionTypes from './actionTypes';

export function updateDescription(description) {    
    return {
       type: actionTypes.UPDATE_DESCRIPTION,
       payload : description
    }
}

export function clean() {
    return {
        type: actionTypes.CLEAN,
    }
}

export function setDisabled(values) {
    return {
        type: "UPDATE_DISABLED_STEP5",
        payload: values
    }
}

