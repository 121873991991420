import React, { Component } from 'react'

import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import RadioGroup from '@material-ui/core/RadioGroup';
import Radio from '@material-ui/core/Radio';
import ButtonSection from '../Components/ButtonSection'
import TextField from '@material-ui/core/TextField';
import * as actions from '../Actions/actionStep6'
import { withTranslation } from "react-i18next";
import Typography from '@material-ui/core/Typography'


export class WizardPage6 extends Component {
    constructor(props){
        super(props);
                    
        this.state = {                     
            DocumentiSegnalati : this.props.store.getState().step6Reducer.DocumentiSegnalati,
            DocumentiPercorso : this.props.store.getState().step6Reducer.DocumentiPercorso,
            DocumentiAltraModalita : this.props.store.getState().step6Reducer.DocumentiAltraModalita,
            SegnalazioneAutorita: this.props.store.getState().step6Reducer.SegnalazioneAutorita,
            QualeAutorita: this.props.store.getState().step6Reducer.QualeAutorita,
            //DocumentiAssenti : this.props.store.getState().step7Reducer.DocumentiAssenti,
            
            formErrors : {
                Description : ""            
            },
        }         
    }    
    
    componentWillUnmount() {
        this.unsubscribe();
        }    
        
    componentDidMount = () => {                
        this.unsubscribe = this.props.store.subscribe(() => {              
            this.setState({                                     
                DocumentiSegnalati : this.props.store.getState().step6Reducer.DocumentiSegnalati,
                DocumentiPercorso : this.props.store.getState().step6Reducer.DocumentiPercorso,
                DocumentiAltraModalita : this.props.store.getState().step6Reducer.DocumentiAltraModalita,
                SegnalazioneAutorita: this.props.store.getState().step6Reducer.SegnalazioneAutorita,
                QualeAutorita: this.props.store.getState().step6Reducer.QualeAutorita,
                //DocumentiAssenti : this.props.store.getState().step7Reducer.DocumentiAssenti,
            })     
        })
    }     
  
    onNext = (event) => { 
                  
        this.props.nextPage(event);
        
    };  
    previousPage = (e) => {            
        this.props.previousPage(e);
    };  
    onTextChange = (e) => {        
        switch(e.target.name)
        {
            case "DocumentiSegnalati":{

                this.props.store.dispatch(actions.updateDocSegnalati(
                    e.target.value));                   
                break;
            }     
            case "DocumentiPercorso":{

                this.props.store.dispatch(actions.updateDocPercorso(
                    e.target.value));                   
                break;
            }   
            case "DocumentiAltraModalita":{

                this.props.store.dispatch(actions.updateAltraModalita(
                    e.target.value));                   
                break;
            }
            default:
                break;                                 
        }       
    }   
    onChange_DocAssenti = (e) => { 
        this.props.store.dispatch(actions.updateDocAssenti(
            e.target.checked));         
      }; 
    onChange_SegnalazioneAutorita = (e) => {
        this.props.store.dispatch(actions.updateSegAutorita(
            e.target.value));              
    }
    onChange_QualeAutorita = (e) => {
        this.props.store.dispatch(actions.updateQualeAutorita(
            e.target.value));
    }
    
    render() {
        const { DocumentiSegnalati, DocumentiPercorso, DocumentiAltraModalita, SegnalazioneAutorita, QualeAutorita } = this.state;
        const { t } = this.props;
        return(
            <div>
                <div className="main">                             
                    <div className="row">
                        <Typography variant="h6">{t('Label_Reported_Document')}</Typography>
                        <TextField disabled={this.props.disabled} className="width100" id="DocumentiSegnalati" name="DocumentiSegnalati" label={t('Label_Document_Reported')} value={DocumentiSegnalati} onChange={this.onTextChange} />      
                    </div>  
                    <div className="row">
                        <Typography variant="h6">{t('Label_Reported_Document_Mod')}</Typography>
                        <TextField disabled={this.props.disabled} className="width100" id="DocumentiPercorso" name="DocumentiPercorso" label={t('Label_Document_Path')} value={DocumentiPercorso} onChange={this.onTextChange} />
                        <TextField disabled={this.props.disabled} className="width100" id="DocumentiAltraModalita" name="DocumentiAltraModalita" label={t('Label_Document_Modality')} value={DocumentiAltraModalita} onChange={this.onTextChange} />                      
                        {/*<FormControlLabel */}
                        {/*    control={<Checkbox disabled={this.props.disabled}  checked={DocumentiAssenti}  onChange={this.onChange_DocAssenti}/>}*/}
                        {/*    label="Non ci sono documenti a supporto di mia conoscenza"  />                      */}
                    </div> 
                    <div className="row">
                        <Typography variant="h6">{t('Label_Ticket_Autority')}</Typography>
                        <FormControl component="fieldset">
                            <FormLabel component="legend"></FormLabel>
                            <RadioGroup aria-label="SegnalazioneAutorita" name="SegnalazioneAutorita" value={SegnalazioneAutorita} onChange={this.onChange_SegnalazioneAutorita}>
                                <FormControlLabel disabled={this.props.disabled} value="Y" control={<Radio />} label={t('Label_PublicAdmin_Yes')}/>
                                <FormControlLabel disabled={this.props.disabled} value="N" control={<Radio />} label={t('Label_PublicAdmin_No')} />
                            </RadioGroup>    
                        </FormControl> 
                        {this.state.SegnalazioneAutorita === "Y" &&
                            <TextField disabled={this.props.disabled} className="width100" id="QualeAutorita" name="QualeAutorita" label={t('Label_Document_Authority')} value={QualeAutorita} onChange={this.onChange_QualeAutorita} />
                        }
                    </div>                                                           
                </div>    
                {this.props.previousPage.length > 0 &&          
                <ButtonSection 
                        previousPage={this.previousPage}   
                        nextPage={this.props.nextPage}                                                 
                        namePrevious={this.props.namePrevious}                            
                        nameNext={this.props.nameNext}
                    />  
                }
            </div>
        )
    }
}

export default withTranslation()(WizardPage6)