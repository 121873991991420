import React, { Component } from 'react'
//import { connect } from "react-redux";
import IconButton from '@material-ui/core/IconButton';
import AlarmIcon from '@material-ui/icons/Alarm';

import * as actionsTicket from '../Actions/ticketActions';

import * as api from '../API/manage';
import { getTranslate } from '../API/getTranslate'
import * as Helper from '../Helper';

//import DocAddicionalGrid from '../Components/DocAddicionalGrid'
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Typography from '@material-ui/core/Typography';

import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import TextField from '@material-ui/core/TextField';
import MessageState from '../Components/MessageState'
import MessageAppoint from '../Components/MessageAppoint'
import MessageDoc from '../Components/MessageDoc'
import MessageInfo from '../Components/MessageInfo'
import { withTranslation } from "react-i18next";

export class ViewTicketDetails extends Component {
    constructor(props){
        super(props);

        this.state = {
            language: this.props.store.getState().ticketReducer.language,
            appoint: this.props.store.getState().ticketReducer.appoint,
            information: this.props.store.getState().ticketReducer.information,
            documents: this.props.store.getState().ticketReducer.documents,
            CodeTicket: this.props.store.getState().ticketReducer.Code,
            stateTicketList: this.props.store.getState().ticketReducer.stateTicketList,
            stateTicket: this.props.store.getState().ticketReducer.stateTicket,
            closed: this.props.store.getState().ticketReducer.closed,
            closeNotes: this.props.store.getState().ticketReducer.closeNotes,
            notesToReporting: this.props.store.getState().ticketReducer.notesToReporting,
            showDialogState: this.props.store.getState().ticketReducer.showDialogState,
            showDialogConvocation: this.props.store.getState().ticketReducer.showDialogConvocation,
            showDialogDocument: this.props.store.getState().ticketReducer.showDialogDocument,
            showDialogInfo: this.props.store.getState().ticketReducer.showDialogInfo,
        };                     
    }  

    componentWillUnmount() {
        this.unsubscribe();
    }

    componentDidMount = () => {                
        this.unsubscribe = this.props.store.subscribe(() => {
            this.setState({
                language: this.props.store.getState().ticketReducer.language,
                appoint: this.props.store.getState().ticketReducer.appoint,
                information: this.props.store.getState().ticketReducer.information,
                documents: this.props.store.getState().ticketReducer.documents,
                CodeTicket: this.props.store.getState().ticketReducer.Code,
                stateTicketList: this.props.store.getState().ticketReducer.stateTicketList,
                stateTicket: this.props.store.getState().ticketReducer.stateTicket,
                closed: this.props.store.getState().ticketReducer.closed,
                closeNotes: this.props.store.getState().ticketReducer.closeNotes,
                notesToReporting: this.props.store.getState().ticketReducer.notesToReporting,
                showDialogState: this.props.store.getState().ticketReducer.showDialogState,
                showDialogConvocation: this.props.store.getState().ticketReducer.showDialogConvocation,
                showDialogDocument: this.props.store.getState().ticketReducer.showDialogDocument,
                showDialogInfo: this.props.store.getState().ticketReducer.showDialogInfo,
            })
        })
        window.scrollTo(0, 0);
        var myDiv = document.getElementById('main');
        myDiv.scrollTop = 0;        
    } 

    handleClose_MessageState = () => {
        this.props.store.dispatch(actionsTicket.showDialogState(false));
    }
 
    handleClose_MessageInfo = () => {
        this.props.store.dispatch(actionsTicket.showDialogInfo(false)); 

        // send to krc
        //api.setActionRead(this.props.store, this.props.store.getState().ticketReducer.information.idAction);

        var newState = Helper.COMPILATO_NO;
        api.setActionStateChange(this.props.store, newState, this.props.store.getState().ticketReducer.information.idAction);
    }
    handleSend_MessageInfo = (response) => {
        this.props.store.dispatch(actionsTicket.showDialogInfo(false)); 

        //console.log("Send info");
        api.setActionInfo(this.props.store, response)
            .then(() => {
                this.props.store.dispatch(actionsTicket.cleanInfo());
            });
                
        //var idAction = this.state.information.idAction
        //var newState = Helper.COMPILATO;

        //api.setActionStateChange(this.props.store, newState, idAction);  
    } 

    handleClose_MessageAppoint = () => {
        this.props.store.dispatch(actionsTicket.showDialogConvocation(false));
        
        // send to krc
        if (this.state.appoint.isApproved === false || this.state.appoint.isChange === true)
            api.setActionRead(this.props.store, this.props.store.getState().ticketReducer.appoint.idAction);
    }   

    handleApproved_Appoint = (isChange, date, time, notes) => {
        this.props.store.dispatch(actionsTicket.showDialogConvocation(false));

        //var month = (+date.getMonth()) + 1; // because the function getMonth is zero-based

        //console.log("getDate : " + date.getDate() + "getFullYear : " + date.getFullYear() + "getMonth : " + date.getMonth());

        var appointTmp = {
            date: date,
            hours : time,
            isChanged: isChange,
            idAction: this.state.appoint.idAction,
            isVisible: true,
            isApproved: true,
            notes: notes
        }

        //this.setState({appoint: appointTmp});
        this.props.store.dispatch(actionsTicket.setAppoint(appointTmp));

        // send to krc
        if (isChange)
            api.setActionApppointChange(this.props.store, notes);

        var newState = Helper.ACCETTATA;
        var idAction = this.state.appoint.idAction

        //api.setActionRead(this.props.store, idAction);
        api.setActionStateChange(this.props.store, newState, idAction);            

        //alert("Messaggio inviato"); 
          
    }

    handleReject_Apppoint = (isChange, date, time, notes) => {
        this.props.store.dispatch(actionsTicket.showDialogConvocation(false)); 

        var appointTmp = {
            date: date,
            hours: time,
            isChanged: isChange,
            idAction: this.state.appoint.idAction,
            isVisible: true,
            isApproved: true,
            notes: notes
        }

        //this.setState({appoint: appointTmp});
        this.props.store.dispatch(actionsTicket.setAppoint(appointTmp));

        // send to krc
        if (isChange)
            api.setActionApppointChange(this.props.store, notes);

        var newState = Helper.RIFIUTATA;
        var idAction = this.state.appoint.idAction

        //api.setActionRead(this.props.store, idAction);
        api.setActionStateChange(this.props.store, newState, idAction);

        //alert("La convocazione è stata rifiutata");
    }

    handleClose_MessageDoc = () => {
        this.props.store.dispatch(actionsTicket.showDialogDocument(false));

        // send to krc
        api.setActionRead(this.props.store, this.props.store.getState().ticketReducer.documents.idAction);

        var upload = false;
        for (let item of this.props.store.getState().ticketReducer.documents.list) {
            if (item.isUpload === true) {
                upload = true;
                break;
            }
        }

        // Set State
        if (!upload) {
            var newState = Helper.RICEVUTI_NO;
            api.setActionStateChange(this.props.store, newState, this.props.store.getState().ticketReducer.documents.idAction);
        }
    }
    handleActionView = (e, event) => {
        //console.log(event);
        if (event === "INFO")            
            this.props.store.dispatch(actionsTicket.showDialogInfo(true)); 
        if (event === "APPOINT")
            this.props.store.dispatch(actionsTicket.showDialogConvocation(true)); 
        if (event === "DOC") {
            this.props.handle_ChangeTab(e,1);            
            //this.props.store.dispatch(actionsTicket.showDialogDocument(true)); 
        }
            
    }
    renderSelectOptions_State = () =>{ 
        return this.state.stateTicketList.map((dt, i) => {
            return (
                <MenuItem
                    label="Select "
                    value={dt.key}
                    key={i}
                    name={dt.value}>{dt.value}</MenuItem>
            );
        });
    };
    handle_ChangeTab = () => {
        this.props.onTextChangeHandle(1);
    };

    getStatoTicketTostring = () => {
        var element = document.getElementById('stateTicket-select');
        //console.log(element);
        return 'Stato : ' + (element === null ? '' : element.innerText)
    }

    render() {
        const { showDialogState, showDialogConvocation, showDialogDocument, showDialogInfo } = this.state        
        const { t } = this.props;
        
        return (
            <div>
                <div id="main" className="main">  
                    <div className="row">
                        <div className="col-4">
                            <TextField className="width100" disabled={true} id="codeTicket" label={t('Label_TicketNumber')} value={this.state.CodeTicket} />
                        </div>
                        <div className="col-8"> 
                            <FormControl className="width100">               
                                <InputLabel id="label_stateTicket">{t('Label_ViewTicket_State')}</InputLabel>
                                <Select  disabled={true}                                                                
                                    labelId="label_stateTicket"
                                    id="stateTicket-select"
                                    value={this.state.stateTicket}                            
                                    >
                                    {this.renderSelectOptions_State()}
                                </Select>
                            </FormControl>                   
                        </div>
                    </div>
                    
                    <div className="row">
                        <React.Fragment>
                            <div className="row">
                                <label class="MuiFormLabel-root MuiInputLabel-root MuiInputLabel-formControl MuiInputLabel-animated MuiInputLabel-shrink Mui-disabled Mui-disabled MuiFormLabel-filled">{t('Label_NotesToReporting')}</label>
                                <Typography style={{ width: '100%' }} variant="div" class="MuiInputBase-root MuiInput-root MuiInput-underline Mui-disabled Mui-disabled MuiInputBase-formControl MuiInput-formControl MuiInputBase-multiline MuiInput-multiline">{<span dangerouslySetInnerHTML={{ __html: this.state.notesToReporting.replaceAll("\n", "<br>") }} />}</Typography>
                            </div>
                        </React.Fragment>
                    </div>

                    {
                        this.state.closed === true &&
                        <React.Fragment>
                            <div className="row">
                                <label class="MuiFormLabel-root MuiInputLabel-root MuiInputLabel-formControl MuiInputLabel-animated MuiInputLabel-shrink Mui-disabled Mui-disabled MuiFormLabel-filled">{t('Label_CloseCausal')}</label>
                                <Typography style={{ width: '100%' }} variant="div" class="MuiInputBase-root MuiInput-root MuiInput-underline Mui-disabled Mui-disabled MuiInputBase-formControl MuiInput-formControl MuiInputBase-multiline MuiInput-multiline">{<span dangerouslySetInnerHTML={{ __html: this.state.closeNotes }} />}</Typography>
                            </div>
                        </React.Fragment>
                    }

                    <div className="row">
                        {this.state.appoint !== null && this.state.appoint.idAction !== null && this.state.appoint.isVisible && !this.state.appoint.isRefused && this.state.closed === false && 
                            <div className="row">
                            <IconButton key="APPOINT" color="secondary" aria-label="Comunication" onClick={e => this.handleActionView(e,"APPOINT")}>
                                    <AlarmIcon />
                            </IconButton>
                            <p>{t('Label_ViewTicket_Appoint')}</p>                                
                            </div>                            
                        }

                        {this.state.information !== null && this.state.information.idAction !== null && this.state.information.isVisible && this.state.closed === false &&
                            <div className="row">
                            <IconButton key="INFO" color="secondary" aria-label="Convocation" onClick={e => this.handleActionView(e,"INFO")}>
                                    <AlarmIcon />
                                </IconButton>                            
                            <p>{t('Label_ViewTicket_Message')}</p>
                            </div>
                        }

                        {this.state.documents !== null && this.state.documents.idAction !== null && this.state.documents.isVisible && this.state.closed === false &&
                            <div className="row">
                            <IconButton key="DOC" color="secondary" aria-label="Documents" onClick={e => this.handleActionView(e, "DOC")}>
                                    <AlarmIcon />
                                </IconButton>
                                <p>{t('Label_ViewTicket_Doc')}</p>
                            </div>
                        }
                    </div>
                    <div className="divider"></div>
                    {this.state.stateTicket == Helper.RICEVUTA_IN_VERIFICA &&
                        <div className="row">
                            <MessageState openDialog={showDialogState} CodeTicket={this.state.CodeTicket} header={t('Label_ViewTicket_State_Header')} handleClose={this.handleClose_MessageState} />
                        </div>
                    }

                    {showDialogInfo && this.state.closed === false &&
                        <div className="row">
                            <MessageInfo openDialog={showDialogInfo} CodeTicket={this.state.CodeTicket} Store={this.props.store}  dateExpiring={this.state.information.dateExpiring} Information={this.state.information} header={t('Label_ViewTicket_Message_Header')} handleClose={this.handleClose_MessageInfo} handleSend={this.handleSend_MessageInfo} />
                        </div>
                    }
                    {showDialogConvocation && this.state.closed === false &&
                        <div className="row">
                            <MessageAppoint openDialog={showDialogConvocation} Store={this.props.store} Language={this.state.language} CodeTicket={this.state.CodeTicket} Appoint={this.state.appoint} handleClose={this.handleClose_MessageAppoint} handleApproved={this.handleApproved_Appoint} handleReject={this.handleReject_Apppoint} />
                        </div>
                    }
                    {showDialogDocument && this.state.closed === false &&
                        <div className="row">
                            <MessageDoc openDialog={showDialogDocument} CodeTicket={this.state.CodeTicket} Store={this.props.store}  dateExpiring={this.state.documents.dateExpiring} handleClose={this.handleClose_MessageDoc} />
                        </div>
                    }
                </div>  

            </div>

        )
    }
}

//const mapStateToProps = state => ({
//    showDialogState: state.ticketReducer.showDialogState,
//    showDialogConvocation: state.ticketReducer.showDialogConvocation,
//    showDialogDocument: state.ticketReducer.showDialogDocument,
//    dateAppoint : state.ticketReducer.dateAppoint,
//    hoursAppoint : state.ticketReducer.hoursAppoint,
//    linkAppoint : state.ticketReducer.linkAppoint,
//    dateDocAdd : state.ticketReducer.dateDocAdd
//  });

//export default connect(mapStateToProps)(ViewTicketDetails)
export default withTranslation()(ViewTicketDetails)
