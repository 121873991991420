import React, { Component } from 'react'

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';
import ButtonSection from '../Components/ButtonSection'
import * as actions from '../Actions/actionStep5'
import { MAX_SIZE_FILE } from '../Helper';
import { withTranslation } from "react-i18next";
import Typography from '@material-ui/core/Typography'

export class WizardPage5 extends Component {
    constructor(props){
        super(props);
                    
        this.state = {                     
            Files : this.props.store.getState().step5Reducer.Files,
        }         
    }    
    
    componentWillUnmount() {
        this.unsubscribe();
        }    
        
    componentDidMount = () => {                
        this.unsubscribe = this.props.store.subscribe(() => {              
            this.setState({                                     
                Files : this.props.store.getState().step5Reducer.Files,
            })     
        })
    }     
      
    onNext = (event) => {          
        this.props.nextPage(event);
    };  
    previousPage = (e) => {            
        this.props.previousPage(e);
    };  

    handleUploadChange = (e) => {
        var maxFileSize = this.props.store.getState().ticketReducer.maxFileSize;      
        const files = Array.from(e.target.files)        
        //console.log("files : ", files);
        files.forEach((file, i) => {

            if (file.size > maxFileSize) {
                var message = "File : " + file.name + " too large ";
                alert(message);
            }
            else {
                this.props.store.dispatch(actions.addFile(file)); 
            }
        })                       
    };

    handleDeleteRow = (file) => {  
        this.props.store.dispatch(actions.removeFile(file)); 
    }         
    render() {
        const { Files } = this.state;
        const { t } = this.props;
        return(
            <div>
                <div className="main">
                    <div className="row">
                        <Typography variant="h6">{t('Label_Upload')}</Typography>
                    </div>                       
                    <div className="row">
                             
                    </div>
                    <div className="row">
                        <TableContainer component={Paper}>
                            <Table aria-label="">
                                <TableHead>
                                <TableRow>               
                                    <TableCell align="left" style={{ width: "5%" }}>
                                        <div style={{
                                            display: 'flex',
                                            margin: 'auto',
                                            width: 400,
                                            flexWrap: 'wrap',
                                        }}>
                                            <input
                                                disabled={this.props.disabled}
                                                id="contained-button-file"
                                                type="file"
                                                accept="*"
                                                multiple 
                                                onChange={this.handleUploadChange}
                                                style={{ display: 'none' }}
                                            />
                                            <label htmlFor="contained-button-file">
                                                <Button disabled={this.props.disabled}  variant="contained" color="primary" component="span">
                                                    {t('Label_Button_Upload')}
                                                </Button>
                                            </label>     
                                        </div>                                          
                                    </TableCell>
                                    <TableCell align="left" style={{ width: "95%" }}></TableCell>
                                </TableRow>
                                </TableHead>
                                <TableBody>
                                {Files.map((file,i) => (
                                    <TableRow key={i}>  
                                        <TableCell align="left">            
                                            <IconButton aria-label="delete" onClick={(e) => this.handleDeleteRow(file)}>
                                                <DeleteIcon />
                                            </IconButton>                    
                                        </TableCell>
                                        <TableCell align="left">
                                            {file.name}
                                        </TableCell>                                                                      
                                    </TableRow>
                                ))}
                                </TableBody>
                            </Table>
                        </TableContainer>                        
                    </div>                     
                </div>        
                {this.props.previousPage.length > 0 &&      
                <ButtonSection 
                        previousPage={this.previousPage}   
                        nextPage={this.props.nextPage}                                                 
                        namePrevious={this.props.namePrevious}                            
                        nameNext={this.props.nameNext}
                    />  
                }
            </div>
        )
    }
}

export default withTranslation()(WizardPage5)