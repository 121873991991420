import React, {useState} from 'react';

import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Button from '@material-ui/core/Button';
import Paper from '@material-ui/core/Paper';
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';

import TextField from '@material-ui/core/TextField';
import { useTranslation, withTranslation } from "react-i18next";

const useStyles = makeStyles({
  table: {
    minWidth: 650,
  },
});

function createData(id, name, surname, role) {
    return { 
      id : id,
      Name : name, 
      Surname : surname, 
      Role: role};
}


export default function SubjectsGrid(props) {
  
    const classes = useStyles();

    const [rows, setRows] = useState(props.rows);  
    const [num, setNum] = useState(0);  
    const { t } = useTranslation();

    React.useEffect(() => {
        props.onChangeRow(rows);
    }, [rows]);

  function addRow (num) {
    setRows(item  => [
        ...item,
        createData(num,'', '', '')
      ]);
  }

  function onClick_ButtonAdd(e){
    
    var index = Math.max(num, props.rows.length) + 1;

    setNum(index);
    addRow(index);
  }
    
  function inputChangeHandler(e,i){  
    let result = rows.map((row) =>{        
        return row.id === i ? {...row, [e.target.name]:e.target.value} : {...row}
       })
       setRows(result) 
       
      
  }
  function handleDeleteRow(e,i){
    setRows([...rows.filter(element => element.id !== i)]);

  }
  

  function getid(index,key){
      var newKey = index + key;
    
      return newKey;
  }
    
  return (      
    <TableContainer component={Paper}>
      <Table className={classes.table} aria-label="">
        <TableHead>
          <TableRow>
            <TableCell align="left" style={{ width: "10%" }}>
                          <Button disabled={props.disabled} variant="outlined" color="primary" href="#outlined-buttons" key='buttonAdd' onClick={onClick_ButtonAdd}>{t('Label_Add_SubjectGrid')}</Button> 
            </TableCell>
            <TableCell align="left" style={{ width: "30%" }}>{t('Label_Name')}</TableCell>
            <TableCell align="left" style={{ width: "30%" }}>{t('Label_Surname')}</TableCell>
            <TableCell align="left" style={{ width: "30%" }}>{t('Label_Role')}</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row,i) => (
            <TableRow key={row.id}>  
                <TableCell align="left">{row.id}             
                    <IconButton disabled={props.disabled} aria-label="delete" key={getid(row.id,'delete')} onClick={(e) => handleDeleteRow(e,row.id)}>
                        <DeleteIcon />
                    </IconButton>                    
                </TableCell>
                <TableCell align="left">
                  <TextField disabled={props.disabled} className="width100" key={getid(row.id,'name')}  label="" value={row.Name} name="Name" variant="outlined" onChange={(e) => inputChangeHandler(e,row.id)} />
                </TableCell>
                <TableCell align="left">
                  <TextField disabled={props.disabled} className="width100" key={getid(row.id,'surname')}  label="" value={row.Surname} name="Surname" variant="outlined" onChange={(e) => inputChangeHandler(e,row.id)}/>
                </TableCell>
                <TableCell align="left">
                  <TextField disabled={props.disabled} className="width100" key={getid(row.id,'role')}   label="" value={row.Role} name="Role" variant="outlined" onChange={(e) => inputChangeHandler(e,row.id)}/>
                </TableCell>                                             
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}