import React, {useEffect} from 'react';
import PropTypes from 'prop-types';
import SwipeableViews from 'react-swipeable-views';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
//import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
/*
import WizardPage1 from './WizardPage1';
import WizardPage2 from './WizardPage2';
import WizardPage3 from './WizardPage3';
import WizardPage4 from './WizardPage4';
import WizardPage5 from './WizardPage5';
import WizardPage6 from './WizardPage6';
import WizardPage7 from './WizardPage7';
*/
import ViewTicketDetails from './ViewTicketDetails'
import DocAddicionalGrid from '../Components/DocAddicionalGrid'
import { useTranslation, withTranslation } from "react-i18next";

//import * as api from '../API/getTicket'

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={2}>
          {children}
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `full-width-tab-${index}`,
    'aria-controls': `full-width-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.paper,
    marginTop:"10px",
    width: "100%"
  },
}));

function ViewTicket(props) {
  useEffect(() => {
  
  }, [])  

  const { t } = useTranslation();
  const classes = useStyles();
  const theme = useTheme();
  const [value, setValue] = React.useState(0);
  const isTabVisible = !props.store.getState().ticketReducer.documents.isVisible;
    
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  //  const handleChangeIndex = (index) => {
  //      console.log("index", index);
  //  setValue(index);
  //};

  return (
    <div className={classes.root}>
      <AppBar position="static" color="default">
        <Tabs
          value={value}
          onChange={handleChange}
          indicatorColor="primary"
          textColor="primary"
          variant="fullWidth"          
          aria-label="full width tabs example"
        >          
                  <Tab label={t('Label_General')} value={0} />
                  <Tab label={t('Label_AdditionalDocuments')} value={1} disabled={isTabVisible}/>
        </Tabs>
      </AppBar>
      <SwipeableViews
        axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
        index={value}        
      >
        <TabPanel value={value} index={0} dir={theme.direction}>  
            <ViewTicketDetails CodeTicket={props.CodeTicket} store={props.store} handle_ChangeTab={handleChange}/>          
        </TabPanel>
        <TabPanel value={value} index={1} dir={theme.direction}>  
            <DocAddicionalGrid store={props.store} />        
        </TabPanel>              
      </SwipeableViews>
    </div>
  );
}

export default withTranslation()(ViewTicket)