import React, { useState } from 'react'
import moment from 'moment'

import Button from '@material-ui/core/Button';
import {
    MuiPickersUtilsProvider,
    KeyboardTimePicker,
    KeyboardDatePicker,
  } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import { useTranslation, withTranslation } from "react-i18next";
import Typography from '@material-ui/core/Typography'
import { validateDate } from '../Functions/functions'
import TextField from '@material-ui/core/TextField';

//import { localeMap, localeFormatMap } from '../Countries/Countries';
//import { localeMap } from '../Countries/Countries';

function MessageAppoint(props) {  
    const { t } = useTranslation();

    const Appoint = props.Appoint;
    const language = props.Language;
    
    const localeFormatMap = props.Store.getState().ticketReducer.dataFormatLanguage;
    const localFormatTime = props.Store.getState().ticketReducer.timeFormatLanguage;

    const dateAppoint = Appoint.date;
    const hoursAppoint = Appoint.hours;
    const temp = dateAppoint + " " + hoursAppoint;

    const [dateTime, setDataTime] = useState(moment.utc(temp, 'yyyy/MM/DD hh:mm:ss').toDate());
    const [date, setData] = useState(moment.utc(temp, 'yyyy/MM/DD hh:mm:ss').toDate());// new Date(temp).toUTCString());

    const [isChange, setChange] = useState(false);
    const [isDateValid, setDateValid] = useState(true);
    const [notes, setNotes] = useState('');

    const handleApproved = () => {

        var time = dateTime.getUTCHours() + ":" + dateTime.getUTCMinutes();    
        var dateTemp = date.getUTCFullYear() + "/" + date.getUTCMonth() + "/" + date.getUTCDate();     

        props.handleApproved(isChange, dateTemp, time, notes);
    }

    const handleReject = () => {
        var time = dateTime.getUTCHours() + ":" + dateTime.getUTCMinutes();
        var dateTemp = date.getUTCFullYear() + "/" + date.getUTCMonth() + "/" + date.getUTCDate();     

        props.handleReject(isChange, dateTemp, time, notes);
    }

    const handleClose = () => {
        props.handleClose();
        
        // Set date into database
    }

    const handleNotesChange = (e) => {
        setNotes(e.target.value);
        setChange(true);
    }
   
    //console.log("new Date(temp) : " + new Date(temp));
    //console.log("new Date() : " + new Date());
    //console.log("date : " + date);
    //console.log("language : " + language);
    //console.log("localeFormatMap : " + localeFormatMap);
    //console.log("localFormatTime : " + localFormatTime);
    
    //console.log("moment 1: " + moment(props.Appoint.date, "yyyy/MM/DD"));
    //console.log("moment 2: " + moment(props.Appoint.date, "yyyy/MM/DD").locale(language));
    //console.log("localeFormatMap : " + localeFormatMap.replace("d", "D"));
    return (
        <div>
            {(props.openDialog === true && 
            <div className="popup-box">                
                <div >
                    <div className="box-header">
                        {/*<h3 id="dbox-header">{t('Label_Appoint_Header')}</h3>  */}
                        <Typography variant="h6">{t('Label_Appoint_Header')}</Typography>  
                    </div>
                    <div className="box-header">
                        <div className="row">
                            <Typography variant="p">{t('Label_Gentilissimo')},</Typography>
                        </div>
                        <div className="row">
                            <Typography variant="p">{t('Label_Appoint_Row1')} <b>{props.CodeTicket}</b></Typography>
                        </div>
                        <div className="row">
                            <Typography variant="p">{t('Label_Appoint_Row2')}</Typography>
                        </div>
                        <div className="row">
                            <Typography variant="p">{t('Label_Appoint_Row3')}</Typography>
                        </div>
                        <div className="row">
                            <Typography variant="p">{t('Label_Appoint_Row4')}</Typography>
                        </div>
                        <div className="row">
                                <br></br> 
                                <Typography variant="p"><b>{t('Label_Appoint_Row5')} {moment(date).format(localeFormatMap.replace("dd", "DD").replace("d", "D"))} {t('Label_Appoint_Row6')} {moment(date).format(localFormatTime)}</b>
                                {/*<Typography variant="p"><b>{t('Label_Appoint_Row5')} {moment(date, "yyyy/MM/DD").format(localeFormatMap.replace("dd", "DD").replace("d", "D"))} {t('Label_Appoint_Row6')} {props.Appoint.hours}</b>*/}
                            {/*<Typography variant="p"><b>{t('Label_Appoint_Row5')} {moment(props.Appoint.date, "yyyy/MM/DD").format(localeFormatMap[language].replace("d","D"))} {t('Label_Appoint_Row6')} {props.Appoint.hours}</b>*/}
                                {/*<Typography variant="p"><b>{t('Label_Appoint_Row5')} {format(props.Appoint.date,localeFormatMap[language])} {t('Label_Appoint_Row6')} {props.Appoint.hours}</b>*/}
                                {/*collegandosi al seguente link :  */}
                                {/*<Link to={{ pathname: props.linkAppoint }} target="_blank">clicca qui</Link>*/}
                            </Typography> 
                        </div>

                        <div className="row">
                            <div className="row">
                                <Typography variant="p">{<span dangerouslySetInnerHTML={{ __html: props.Appoint.details }} />}</Typography>
                            </div> 
                            <div className="row">
                                    <TextField disabled={props.Appoint.isApproved} multiline={true} rows={4} className="width100" id="notes" label={t('Label_NotesToConvocation')} value={notes} onChange={e => handleNotesChange(e)}  />
                            </div> 
                        </div>

                        <div className="row">
                            <Typography variant="p">{t('Label_Appoint_Row8')}</Typography>
                            </div>

                    </div>
                    <div style={{ display: "flex" }}>
                        {(props.Appoint.isApproved === false && 
                            <div>
                                {(isDateValid === true &&
                                    <Button onClick={handleApproved} color="primary" autoFocus style={{ marginLeft: "auto" }}>
                                        {t('Button_Conferm')}
                                    </Button>
                                )}
                                <Button onClick={handleReject} color="primary" autoFocus style={{ marginLeft: "auto" }}>                                
                                    {t('Button_Reject')}
                                </Button>
                            </div>
                        )}
                        <Button onClick={handleClose} color="primary" autoFocus style={{marginLeft: "auto"}}>
                            {t('Button_Close')}
                        </Button> 
                     </div>                       
                </div>
            </div>
            )}
        </div>
    )
}

export default withTranslation()(MessageAppoint)
