import * as actionTypes from './actionTypes';

export function updateCanale(Canale) {
    return {
       type: actionTypes.UPDATE_CANALE,
       payload : Canale
    }
}

export function loadCanali(values) {
    return {
        type: actionTypes.LOAD_CANALI,
        payload: values
    }
}

export function setPrevalutation(values) {
    return {
        type: actionTypes.SET_PREVALUTATION,
        payload: values
    }
}

export function setDisabled(values) {
    return {
        type: "UPDATE_DISABLED_STEP2",
        payload: values
    }
}

export function setExplicitConsent(values) {
    return {
        type: "EXPLICIT_CONSENT",
        payload: values
    }
}

export function clean() {
    return {
        type: actionTypes.CLEAN,
    }
}

export function setSignallerDataObligation(values) {
    return {
        type: actionTypes.SIGNALLERDATA_OBLIGATION,
        payload: values
    }
}

export function setSignallerIdentityConsent(values) {
    return {
        type: actionTypes.SIGNALLERIDENTITY_CONSENT,
        payload: values
    }
}

