
import * as ticket from '../Actions/ticketActions'

export const getState =  async (store) => {
    const apiUrl = './api/State?Language=' + store.getState().ticketReducer.language;

        await fetch(apiUrl, {
            method: 'GET',
            credentials: 'same-origin',
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Access-Control-Allow-Origin': '*'
            }
        })
        .then(response => response.json())
        .then((result) => {
            store.dispatch(ticket.loadState(result));
        })
        .catch((error) => {
            console.log('getState Fecth failed : ', error)
            return Promise.reject();
        });
  
};
