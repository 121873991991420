import * as actions from '../Actions/ticketActions'
import * as  functions from '../Functions/functions'
import { encryptedFunction } from '../Encrypted/encrypted'
import { readKey } from '../Encrypted/encrypted'
import { setDocumentsAttach } from './setDocumentsAttach'
import { getEncrypedValue, getEncrypedValueStd, getRecorderAudioCrypted } from './manage'

/**
 * Make ticket to KRC
 * @param {any} store
 */
export const setTicket = (store) => {
    return new Promise(function (resolve, reject) {

        readKey().then(async (key) => {
            
            var legalEntityLabel = functions.getLabelFromList(
                store.getState().ticketReducer.legalEntity,
                store.getState().ticketReducer.legalEntityList);

            //var LegalEntity = await encryptedFunction(legalEntityLabel, key);
            var LegalEntity = await getEncrypedValue(store, key, "LegalEntity", legalEntityLabel, store.getState().ticketReducer.legalEntity);

            //var PublicAdmin = await encryptedFunction(store.getState().step1Reducer.PublicAdmin, key);
            var PublicAdmin = "";
            if (store.getState().ticketReducer.showPublicAdmin === true)
                PublicAdmin = await getEncrypedValue(store, key, "PublicAdmin", store.getState().ticketReducer.publicAdmin, "");

            var numberDaysToExpiration = (store.getState().ticketReducer.publicAdmin === "Si" || store.getState().ticketReducer.publicAdmin === "Yes" ? "180" : "90");
            //console.log("PublicAdmin ", PublicAdmin);
            //console.log("store.getState().step1Reducer.PublicAdmin ", store.getState().step1Reducer.PublicAdmin);

            var ReportingFromOtherChannel = store.getState().step1Reducer.reportingFromOtherChannel

            const People = [];
            var index = store.getState().step1Reducer.Canale - 1;
            if (store.getState().step1Reducer.Canali[index].value !== null) {
                for (let item of store.getState().step1Reducer.Canali[index].value) {
                    var Person = {
                        Name: item.name.split(" ")[0],
                        Surname: item.name.split(" ")[1],
                    }
                    People.push(Person);
                }
            }
            var CanaleIndex = store.getState().step1Reducer.Canale;

            var Canale = {
                Function: store.getState().step1Reducer.Canali[index].function,
                People: People
            }
            
            var isPrevalutation = true;
            if (store.getState().ticketReducer.showPrevalutation === false)
                isPrevalutation = false;
            else
                isPrevalutation = store.getState().step1Reducer.isPrevalutation;

            var CategoryLabel = functions.getLabelFromList(
                store.getState().step2Reducer.Category,
                store.getState().step2Reducer.Categories);
            //var Category = await encryptedFunction(CategoryLabel, key);
            var Category = await getEncrypedValue(store, key, "Category", CategoryLabel, store.getState().step2Reducer.Category);

            var ViolazioniLabel = functions.getLabelListFromList(
                store.getState().step2Reducer.Violazioni,
                store.getState().step2Reducer.ViolationList.children);
            //var Violazioni = await encryptedFunction(ViolazioniLabel, key);
            var Violazioni = await getEncrypedValue(store, key, "Violazioni", ViolazioniLabel, "");            
            var newViolazione = await encryptedFunction(store.getState().step2Reducer.newViolazione, key);

            //var ProcessRoot = functions.getProcessRoot(store.getState().step1Reducer.LegalEntity, store.getState().step1Reducer.LegalEntityList);
            //var ProcessList = store.getState().step4Reducer.ProcessList.children.filter(el => el.id === ProcessRoot);
            //var ProcessLabel = functions.getLabelListFromList(
            //    store.getState().step4Reducer.Process,
            //    ProcessList);

            //var ProcessLabel = functions.getLabelListFromList(
            //    store.getState().step4Reducer.Process,
            //    store.getState().step4Reducer.ProcessList.children);
            //console.log("Process : ", store.getState().step4Reducer.Process);

            var Process = store.getState().step3Reducer.Process;
            //var Process = await getEncrypedValue(store, key, "Process", ProcessLabel, ProcessLabel);

            var newProcess = await encryptedFunction(store.getState().step3Reducer.newProcess, key);
            //var newProcess = await getEncrypedValue(store, key,"newProcess",store.getState().step4Reducer.newProcess, "");

            //var Description = await encryptedFunction(store.getState().step5Reducer.Description, key);
            var Description = await getEncrypedValue(store, key, "Description", store.getState().step4Reducer.Description, "");
            var DescriptionOriginal = await getEncrypedValueStd(key, store.getState().step4Reducer.Description);

            // Recording Audio            
            var recorderAudioCheck = store.getState().ticketReducer.recorderAudioCheck;
            var recorderAudioFile = '';
            if (recorderAudioCheck === true)
                recorderAudioFile = await getRecorderAudioCrypted(store.getState().ticketReducer.recorderAudioFile, key);

            //console.log("recorderAudioCheck ", recorderAudioCheck);
            //console.log("recorderAudioFile ", recorderAudioFile);

            //var DocumentiSegnalati = store.getState().step7Reducer.DocumentiSegnalati;
            var DocumentiSegnalati = await getEncrypedValue(store, key, "DocumentiSegnalati", store.getState().step6Reducer.DocumentiSegnalati, "");

            //var DocumentiPercorso = await encryptedFunction(store.getState().step7Reducer.DocumentiPercorso, key);
            var DocumentiPercorso = await getEncrypedValue(store, key,"DocumentiPercorso",store.getState().step6Reducer.DocumentiPercorso, "");

            //var DocumentiAltraModalita = await encryptedFunction(store.getState().step7Reducer.DocumentiAltraModalita, key);
            var DocumentiAltraModalita = await getEncrypedValue(store, key,"DocumentiAltraModalita",store.getState().step6Reducer.DocumentiAltraModalita, "");

            var SegnalazioneAutorita = store.getState().step6Reducer.SegnalazioneAutorita;
            //var QualeAutorita = await encryptedFunction(store.getState().step7Reducer.QualeAutorita, key);
            var QualeAutorita = await getEncrypedValue(store, key,"QualeAutorita",store.getState().step6Reducer.QualeAutorita, "");

            //var Name = await encryptedFunction(store.getState().step8Reducer.Name, key);
            var fullName = store.getState().step7Reducer.Name + " " + store.getState().step7Reducer.Surname
            fullName = await getEncrypedValue(store, key, "fullName", fullName, "");

            //var Name = await getEncrypedValue(store,key,"Name",store.getState().step8Reducer.Name, "");

            //var Surname = await encryptedFunction(store.getState().step8Reducer.Surname, key);
            //var Surname = await getEncrypedValue(store, key, "Surname", store.getState().step8Reducer.Surname, "");

            //var Email = await encryptedFunction(store.getState().step8Reducer.Email, key);
            var Email = await getEncrypedValue(store, key, "Email", store.getState().step7Reducer.Email, "");

            //var Phone = await encryptedFunction(store.getState().step8Reducer.Phone, key);
            var Phone = await getEncrypedValue(store, key, "Phone", store.getState().step7Reducer.Phone, "");

            //var isSend = await encryptedFunction(store.getState().step8Reducer.isSend, key);
            var isSend = await getEncrypedValue(store, key, "isSend", store.getState().step7Reducer.isSend, "");

            var idWantTransmitYourContacts = store.getState().step7Reducer.idWantTransmitYourContacts;

            var SignallerDataObligation = store.getState().step1Reducer.SignallerDataObligation;


            //Foreach Involved Subjects crypt data and unpdate  array
            var InvolvedSubjects = [...store.getState().step3Reducer.Rows];
            for (let i = 0; i < InvolvedSubjects.length; i++) {
                let subject ={ ...InvolvedSubjects[i] };
                subject.Name = await encryptedFunction(subject.Name, key);
                subject.Surname = await encryptedFunction(subject.Surname, key);
                subject.Role = await encryptedFunction(subject.Role, key);

                InvolvedSubjects[i] = subject;
            }
            

             setDocumentsAttach(store.getState().step5Reducer.Files, key).then((docs) => {
                 
                var ticket = {
                    Code: '',
                    LegalEntity: LegalEntity,
                    ReportingFromOtherChannel: ReportingFromOtherChannel,
                    PublicAdmin: PublicAdmin,
                    Canale: CanaleIndex,
                    Canali: Canale,
                    isPrevalutation: isPrevalutation,
                    Category: Category,
                    Violazioni: Violazioni,
                    newViolazione: newViolazione,
                    Process: Process,
                    newProcess: newProcess,
                    Description: Description,
                    DescriptionOriginal: DescriptionOriginal,
                    RecorderAudioCheck: recorderAudioCheck,
                    RecorderAudio: recorderAudioFile,                    
                    DocumentiSegnalati: DocumentiSegnalati,
                    DocumentiPercorso: DocumentiPercorso,
                    DocumentiAltraModalita: DocumentiAltraModalita,
                    SegnalazioneAutorita: SegnalazioneAutorita,
                    QualeAutorita: QualeAutorita,
                    DocumentsAttach: docs,
                    Name: fullName,
                    NumberDaysToExpiration: numberDaysToExpiration,
                    Surname: '',
                    Email: Email,
                    Phone: Phone,
                    isSend: (!SignallerDataObligation) ? isSend : "",
                    idWantTransmitYourContacts: (!SignallerDataObligation) ? idWantTransmitYourContacts : "",
                    People: InvolvedSubjects
                }

                //console.log("docs : ", docs);
                console.log("ticket : ", ticket);
                //console.log("SON.stringify(docs) : ", JSON.stringify(docs));
                
                const apiUrl = './api/ticket';

                fetch(apiUrl, {
                    method: 'POST',
                    credentials: 'same-origin',
                    headers: {
                        'Content-Type': 'application/json',
                        'Accept': 'application/json',
                        'Access-Control-Allow-Origin': '*'
                    },
                    body: JSON.stringify(ticket)
                })
                .then(response => response.json())
                    .then((result) => {

                        if (result.details !== null || result.details.length > 0) {
                             var array = result.details.split("&");
                             var code = array[0];
                             var key = array[1];

                            store.dispatch(actions.updateCode(code, key));
                        }

                    resolve();
                })
                .catch((error) => {
                    console.log('Fecth failed POST: ', error)

                    reject();
                });
            })       
        });   
    });
};
