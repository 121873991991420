import React, { useState } from 'react';

import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Tooltip from "@material-ui/core/Tooltip";
import { useTranslation, withTranslation } from "react-i18next";

//import { MAX_SIZE_FILE } from '../Helper';
//import TextField from '@material-ui/core/TextField';

import * as api from '../API/manage';
import * as ticket from '../Actions/ticketActions'


//import * as actionsTicket from '../Actions/ticketActions'

const useStyles = makeStyles({
    table: {
      minWidth: 650,
    },
  });


function DocAddicionalGrid(props) {

    const { t } = useTranslation();
    const classes = useStyles();        
    const docs = props.store.getState().ticketReducer.docAdditional; 
    const [files, setFiles] = useState([]);   
    const [isSendDisabled, setSendDisabled] = useState(true);        

    /**
     * 
     * @param {any} e
     */
    const handleSubmission = (e) => {

        props.store.dispatch(ticket.loading(true));         

        var idAction = props.store.getState().ticketReducer.documents.idAction;      
  
        var docs = {
            idAction: idAction,
            files: files
        }

        if (files.length > 0) {
            api.sendActionDocumentsFiles(props.store, docs).then(() => {
                props.store.dispatch(ticket.loading(false));
                props.store.dispatch(ticket.showDialogDocument(false));
            }).catch((error) => {
                //console.log('sendActionFiles - Fecth failed POST');

                props.store.dispatch(ticket.loading(false));
            });
        }

        setSendDisabled(true);
    }
    /**
     * 
     * @param {any} e
     * @param {any} id
     */
    const handleFileInput = async (e, id) => {
        
        const file = e.target.files[0];
        var maxFileSize = props.store.getState().ticketReducer.maxFileSize;      

        console.log(file);

        if (file === undefined)
            return;

        if (file.size > maxFileSize) {
            var message = "File : " + file.name + " too large ";
            alert(message);
        }
        else {

 
            var fileData = new Blob([file]);
            var data = await api.readUploadedFileAsByte(fileData);

            var item = {
                data: data,
                name: file.name,
                id: id
            }

            let newElement = [...files];        

            if (newElement.length > 0)
                newElement = removeItemOnce(newElement, item);

            newElement.push(item);

            setFiles(newElement);

            for (let el of docs) {
                if (el.id === item.id) {
                    el.nameFile = item.name;
                    props.store.dispatch(ticket.addAdditionalDocument(el));
                    break;
                }
            }

            setSendDisabled(false);
  

            //var promise = new Promise(getBuffer(fileData));
            //var promise = new Promise(api.readUploadedFileAsByte(fileData));
            

            //promise.then(function (data) {
            //    var item = {
            //        data: data,
            //        name: file.name,
            //        id: id
            //    }

            //    let newElement = [...files];

            //    if (newElement.length > 0)
            //        newElement = removeItemOnce(newElement, item);

            //    newElement.push(item);

            //    setFiles(newElement);  
            //});            
        }
    };   
   /**
   * 
   * @param {any} fileData
   */
    function getBuffer(fileData) {
        return function (resolve) {
            var reader = new FileReader();
            reader.readAsText(fileData);
            reader.onload = function () {
                var arrayBuffer = reader.result
                //var bytes = new Uint8Array(arrayBuffer);
                resolve(arrayBuffer);
            }
        }
    }
    /**
     * 
     * @param {any} id
     */
    const getFile = (id) => {
        
        for (let item of files) {
            if (item.id === id) {
                return item.name;
            }                
        }
    }
    /**
     * 
     * @param {any} arr
     * @param {any} value
     */
    function getIndex(arr, value) {
        var index = -1;
        for (let i = 0; i < arr.length; i++ )
            if (arr[i].id === value.id)
                index = i;

        //console.log("index : ", index);
        return index;
    }
    /**
     * 
     * @param {any} arr
     * @param {any} value
     */
    function removeItemOnce(arr, value) {
        var index = getIndex(arr, value);

        //console.log("Index :", index);
        //console.log("arr first :", arr);

        if (index > -1) {
            arr.splice(index, 1);

            //console.log("arr splice :", arr);
        }
        return arr;
    }

    return (
        <React.Fragment>
            <TableContainer component={Paper}>
                <Table className={classes.table} aria-label="">
                    <TableHead>
                        <TableRow>
                            {/*<TableCell align="left" style={{ width: "10%" }}>id</TableCell>*/}
                            <TableCell align="left" style={{ width: "40%" }}>{t('DescDoc')}</TableCell>
                            <TableCell align="left" style={{ width: "1%" }}>{t('UploadDoc')}</TableCell>
                            <TableCell align="left" style={{ width: "39%" }}>{t('FileDoc')}</TableCell>
                            <TableCell align="left" style={{ width: "20%" }}>                                
                                <Tooltip  title=''>
                                    <span>{t('Label_Document_Uploaded')}</span>
                                </Tooltip>
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {                            

                            docs.map((item, i) => (
                                <TableRow key={item.id}>
                                    {/*<TableCell align="left">*/}
                                    {/*    <p>{item.id}</p>*/}
                                    {/*</TableCell>  */}
                                    <TableCell align="left">
                                        <p>{item.name}</p>
                                    </TableCell>
                                    <TableCell align="left" style={{ width: "5%" }}>
                                        <div style={{
                                            display: 'flex',
                                            margin: 'auto',
                                            width: 400,
                                            flexWrap: 'wrap',
                                        }}>
                                            {!item.isUpload && 
                                                <div>
                                                   <input                                
                                                        id={'input' + item.id}
                                                        type="file"
                                                        accept="*"                                 
                                                        onChange={e => handleFileInput(e, item.id)}
                                                        style={{ display: 'none' }}
                                                    />
                                                    <label htmlFor={'input' + item.id}>
                                                        <Button
                                                            variant="contained"
                                                            color="primary"
                                                            component="span"                                      
                                                        >
                                                            {t('UploadDoc')}
                                                        </Button>
                                                    </label> 
                                                </div>
                                            }
     
                                        </div>                                          
                                    </TableCell> 
                                    <TableCell align="left">   
                                        {/*<p>{getFile(item.id)}</p>  */}
                                        <p>{item.nameFile}</p>                                        
                                    </TableCell>
                                    <TableCell align="center">                                
                                        <div>
                                            <FormControlLabel
                                                control={<Checkbox disabled={true} checked={item.isUpload} />}
                                            />
                                        </div>                                
                                    </TableCell>                              
                                </TableRow>
                            ))
                        }                                  
                    </TableBody>
                </Table>
            </TableContainer>
            <div className="divider"></div>
            <div className="center">
                {docs.length > 0 && 
                    <div>
                    <Button disabled={isSendDisabled} onClick={e => handleSubmission(e)} color="primary" variant="contained" autoFocus>                        
                            {t('SendFilesDoc')}
                        </Button>
                    </div>
                }
            </div>

        </React.Fragment>
    )
}

export default withTranslation()(DocAddicionalGrid)
