import * as actionTypes from './actionTypes';

export function updateName(item) {    
    return {
       type: actionTypes.UPDATE_NAME,
       payload : item
    }
 }

 export function updateSurname(item) {    
    return {
       type: actionTypes.UPDATE_SURNAME,
       payload : item
    }
 }

 export function updateEmail(item) {    
    return {
       type: actionTypes.UPDATE_EMAIL,
       payload : item
    }
 }

 export function updatePhone(item) {    
    return {
       type: actionTypes.UPDATE_PHONE,
       payload : item
    }
 }

 export function updateSend(item) {    
    return {
       type: actionTypes.UPDATE_SEND,
       payload : item
    }
}

export function clean() {
    return {
        type: actionTypes.CLEAN,
    }
}
export function setDisabled(values) {
    return {
        type: actionTypes.UPDATE_DISABLED_STEP8,
        payload: values
    }
}

