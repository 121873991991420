import * as actionTypes from '../Actions/actionTypes'

const initialState = {
    ProcessList : [],
    Process : [],
    newProcess: '',
    newProcessActive: false,
    Rows: [],
    disabledNext: false
}


const step3Reducer = (state = initialState, action) => {
    switch (action.type) {

        case actionTypes.CLEAN:
            {
                return {
                    ...state,
                    newProcess: '',
                    newProcessActive:false,
                    Process: [],
                    Rows: [],
                    disabledNext:false
                }
            }

        case "UPDATE_DISABLED":
            {
                return {
                    ...state,
                    disabledNext: action.payload
                }
            }
        case "UPDATE_CHECK_NEW_PROCESS":
            {
                return {
                    ...state,
                    newProcessActive: action.payload
                }
            }

        case actionTypes.LOAD_PROCESS:
            {
                //console.log("LOAD_PROCESS", action.payload);
                return {
                    ...state,
                    ProcessList: action.payload
                }
            }
        case actionTypes.REMOVE_3: 
        {          
          return {
            ...state,
            Process : [
              ...state.Process.filter(element => element !== action.payload)
            ]
          }         
        }
        case actionTypes.ADD_3: 
        {              
          return {
            ...state,
            Process : [...state.Process,action.payload]
          }          
        }
        case actionTypes.NEXT_3: 
        {              
          return {
            ...state,            
            Process: action.payload.Process           
          }          
        }   
        case actionTypes.ADD_NEW_PROCESS: 
        {                     
          return {
            ...state,
            newProcess : action.payload,
            Process : [...state.Process],
            //Rows : [...state.Rows],
          }          
        }  
        case actionTypes.UPDATE_ROWS: 
        {                     
          return {
            ...state,
           // newProcess : state.newProcess,
            //Process : [...state.Process],
            Rows : action.payload
          }          
        }                       

        default: 
          return state;
    }

  }
  
 export default step3Reducer;