import { useState, useEffect, ReactElement } from "react";
//import { Props } from "./interfaces";
import useAudioRecorder from "../hooks/useAudioRecorder";

import micSVG from "../icons/mic.svg";
import pauseSVG from "../icons/pause.svg";
import resumeSVG from "../icons/play.svg";
import saveSVG from "../icons/save.svg";
import discardSVG from "../icons/stop.svg";
import "../styles/audio-recorder.css";
import * as React from "react";

/**
 * @prop labelSaveRecording
 * @prop labelStartRecording
 * @prop labelResumeRecording
 * @prop labelPauseRecording
 * @prop labelDiscardRecording
 * @prop `onRecordingComplete` Method that gets called when save recording option is clicked
 * @prop `recorderControls` Externally initilize hook and pass the returned object to this param, this gives your control over the component from outside the component. 
 * @prop `classes` Is an object with attributes representing classes for different parts of the component
 */
export const AudioRecorder = ({
    labelSaveRecording,
    labelStartRecording,
    labelResumeRecording,
    labelPauseRecording,
    labelDiscardRecording,
    onRecordingComplete,
    recorderControls,
    classes,
}) => {
    const {
        startRecording,
        stopRecording,
        togglePauseResume,
        recordingBlob,
        isRecording,
        isPaused,
        recordingTime,
        // eslint-disable-next-line react-hooks/rules-of-hooks
    } = recorderControls ?? useAudioRecorder();

    const [shouldSave, setShouldSave] = useState(false);
    const stopAudioRecorder = (save) => {
        
        setShouldSave(save);
        stopRecording();
    };

    useEffect(() => {
        if (
            shouldSave  &&
            recordingBlob != null &&
            onRecordingComplete != null
        ) {
            onRecordingComplete(recordingBlob);
        }
    }, [recordingBlob]);

    return (
        <div className={`audio-recorder ${isRecording ? "recording" : ""} ${classes?.AudioRecorderClass ?? ""}`} data-testid="audio_recorder">

            <img
                src={isRecording ? saveSVG : micSVG}
                className={`audio-recorder-mic ${classes?.AudioRecorderStartSaveClass ?? ""
                    }`}
                onClick={isRecording ? () => stopAudioRecorder(true) : startRecording}
                data-testid="ar_mic"
                title={isRecording ? labelSaveRecording : labelStartRecording}
            />
            <span
                className={`audio-recorder-timer ${!isRecording ? "display-none" : ""
                    } ${classes?.AudioRecorderTimerClass ?? ""}`}
                data-testid="ar_timer"
            >
                {Math.floor(recordingTime / 60)}:
                {String(recordingTime % 60).padStart(2, "0")}
            </span>
            <span
                className={`audio-recorder-status ${!isRecording ? "display-none" : ""
                    } ${classes?.AudioRecorderStatusClass ?? ""}`}
            >
                <span className="audio-recorder-status-dot"></span>
                Recording
            </span>
            <img
                src={isPaused ? resumeSVG : pauseSVG}
                className={`audio-recorder-options ${!isRecording ? "display-none" : ""
                    } ${classes?.AudioRecorderPauseResumeClass ?? ""}`}
                onClick={togglePauseResume}
                title={isPaused ? labelResumeRecording : labelPauseRecording}
                data-testid="ar_pause"
            />
            <img
                src={discardSVG}
                className={`audio-recorder-options ${!isRecording ? "display-none" : ""} ${classes?.AudioRecorderDiscardClass ?? ""}`}
                onClick={() => stopAudioRecorder(false)}
                title={labelDiscardRecording}
                data-testid="ar_cancel"
            />
        </div>
    );
};
export default AudioRecorder;
