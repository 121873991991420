//import * as actions1 from '../Actions/actionStep1'
import * as actions from '../Actions/ticketActions'

/**
 * @param {any} store - redux store
 * @description Get LegalEntity from KRC
 */

export const getLegalEntity = async (store) => {
    const apiUrl = './api/LegalEntity?language=' + store.getState().ticketReducer.language;     
        await fetch(apiUrl, {
            method: 'GET',
            credentials: 'same-origin',
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Access-Control-Allow-Origin': '*'
            }
        })
        .then(response => response.json())
            .then((result) => {
                console.log("LegalEntity : ", result);
                store.dispatch(actions.LoadLegalEntity(result));
        })
        .catch((error) => {
            //console.log('Fecth failed : ', error);
            return Promise.reject();
        });
     
  };