import * as actions from '../Actions/actionStep2'

/**
 * Get Categories from KRC
 * @param {any} store - redux store 
 * @description 
 */

export const getCategory = async (store) => {
   
    const apiUrl = './api/Categories?language=' + store.getState().ticketReducer.language;

        await fetch(apiUrl, {
            method: 'GET',
            credentials: 'same-origin',
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Access-Control-Allow-Origin': '*'
            }
        })
        .then(response => response.json())
        .then((result) => {
            store.dispatch(actions.LoadCategories(result));
        })
        .catch((error) => {
            console.log('getCategory Fecth failed : ', error);
            return Promise.reject();
        });

  };