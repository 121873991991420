import { getLocalTicket } from '../Functions/getLocalTicket'
import { getTranslate } from './getTranslate'
import { encryptedFunction, encryptedFunctionBinary } from '../Encrypted/encrypted'
import { readKey } from '../Encrypted/encrypted'

/**
 * 
 * @param {any} store
 */
export const setActionRead = async (store, idAction) => {

    const apiUrl = './api/ActionRead';

    var ActionResp = {
        Code: store.getState().ticketReducer.Code,
        idAction: idAction
    };

    await fetch(apiUrl, {
        method: 'POST',
        credentials: 'same-origin',
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            'Access-Control-Allow-Origin': '*'
        },
        body: JSON.stringify(ActionResp)
    })
        .then(response => response.json())
        .then((result) => {

        })
        .catch((error) => {
            //console.log('Fecth failed POST: ', error)
        });
}

/**
 * 
 * @param {any} store
 */
export const setActionApppointChange = async (store, notes) => {

    return new Promise(async function (resolve, reject) {

        readKey().then(async (key) => {
            const apiUrl = './api/ChangeAppoint';

            var ticket = getLocalTicket(store);

            ticket.appoint.notes = await getEncrypedValue(store, key, "FurtherInfoInserted_EncryptedReadOnlyContent", notes, "");

            console.log("setActionApppointChange ", ticket);

            await fetch(apiUrl, {
                method: 'POST',
                credentials: 'same-origin',
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json',
                    'Access-Control-Allow-Origin': '*'
                },
                body: JSON.stringify(ticket)
            })
                .then(response => response.json())
                .then((result) => {
                    console.log('setActionApppointChange result: ', result)
                    resolve();
                })
                .catch((error) => {
                    console.log('setActionApppointChange error: ', error)

                    reject();
                });
        });

    })        
    
}
/**
 * 
 * @param {any} store
 * @param {any} state
 */
export const setActionStateChange = async (store, state, idAction) => {
    return new Promise(async function (resolve, reject) {
        const apiUrl = './api/ActionState';
    
        var ActionResp = {        
            Code: store.getState().ticketReducer.Code,        
            idAction: idAction,
            state: state,
            legalEntity: store.getState().ticketReducer.legalEntity,
            isPrevalutation: store.getState().ticketReducer.isPrevalutation,
            canale: store.getState().ticketReducer.canale
        };
    
        //console.log("setActionStateChange ", ActionResp);

        await fetch(apiUrl, {
            method: 'POST',
            credentials: 'same-origin',
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Access-Control-Allow-Origin': '*'
            },
            body: JSON.stringify(ActionResp)
        })
            .then(response => response.json())
            .then((result) => {
                //console.log('setActionStateChange result: ', result)
                resolve();
            })
            .catch((error) => {
                //console.log('setActionStateChange error: ', error)
                reject();
            });
    })
}
/**
 * 
 * @param {any} store
 * @param {any} response
 */
export const setActionInfo = (store, response) => {
    return new Promise(async function (resolve, reject) {

        readKey().then(async (key) => {

            const apiUrl = './api/ActionInfo';

            var ticket = await getLocalTicket(store);

            //console.log("setActionInfo ", ticket);
            //ticket.reqInformation.response = await encryptedFunction(response, key);                        
            ticket.reqInformation.response = await getEncrypedValue(store, key, "FurtherInfoInserted_EncryptedReadOnlyContent", response, "");
            
            await fetch(apiUrl, {
                method: 'POST',
                credentials: 'same-origin',
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json',
                    'Access-Control-Allow-Origin': '*'
                },
                body: JSON.stringify(ticket)
            })
                .then(response => response.json())
                .then((result) => {
                    resolve();
                })
                .catch((error) => {
                    //console.log('Fecth failed POST: ', error)

                    reject();
                });
        });
 
    })        
}
/**
 * 
 * @param {any} store
 * @param {any} files
 * @param {any} formData
 * @param {any} idAction
 */
export const sendActionDocumentsFiles = (store, docs) => {
    return new Promise(function (resolve, reject) {

        readKey().then(async (key) => {

            const apiUrl = './api/ActionUpload';            

            //console.log("store.getState().ticketReducer.Code : ", store.getState().ticketReducer.Code);
            docs.code = store.getState().ticketReducer.Code;

          
            for (let i = 0; i < docs.files.length; i++) {

                var fileName = await encryptedFunction(docs.files[i].name, key);
                var content = await encryptedFunctionBinary(docs.files[i].data, key);

                var data = fileName + "§-§" + content
                docs.files[i].data = data;
            }

            await fetch(apiUrl, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json',
                    'Access-Control-Allow-Origin': '*'
                },
                body: JSON.stringify(docs)
            })
                .then(response => response.json())
                .then((res) => {
                    resolve();
                })
                .catch((error) => {
                    //console.log('sendActionFiles - Fecth failed POST: ', error);

                    reject();
                });
        })
    })
}
/**
 * The function crypted file audio
 * @param {any} audio
 * @param {any} key
 */
export const getRecorderAudioCrypted = async (file, key) => {

    //console.log("First : ", audio);

    //var fileName = "audiofile.mp3";
    //fileName = await encryptedFunction(fileName, key); 
    var fileName = await encryptedFunction(file.name, key); 

    var bytes = await readUploadedFileAsByte(file);         
    var content = await encryptedFunctionBinary(bytes, key);   

    //var content = await encryptedFunctionBinary(audio, key);
    //var content = await encryptedFunction(audio, key);
    //console.log("before : ", content);

    var data = fileName + "§-§" + content

    return data;
}
/**
 * 
 * @param {any} store
 * @param {any} key
 * @param {any} nameField
 * @param {any} valueField
 * @param {any} id - if id not tranlsate
 */
export const getEncrypedValue = async (store, key, nameField, valueField, id) => {
    var ret = "";

    //console.log("getEncrypedValue nameField : ", nameField);
    //console.log("getEncrypedValue valueField : ", valueField);

    try {
        var encrypedEsl = store.getState().ticketReducer.fieldsEncryptedEsclude;
        var isEncryped = true;

        if (encrypedEsl !== null) {
            for (let i = 0; i < encrypedEsl.length; i++) {
                if (encrypedEsl[i] === nameField) {
                    isEncryped = false;
                    break;
                }
            }
        }

        if (isEncryped) {
            if (valueField !== null && valueField !== '') {

                await getTranslate(valueField,false,store).then( async (result) =>  {

                    //console.log('valueField : ', valueField);
                    //console.log('result : ', result);

                    ret = await encryptedFunction(result, key);
                    //console.log("getEncrypedValue encryptedFunction : ", ret);
                });
            }
        }
        else {
            if (id !== null && id !== "")
                ret = id;
            else {
                await getTranslate(valueField,false,store).then(async (result) => {

                    ret = result;
                });                
            }                
        }

        //console.log("getEncrypedValue isEncryped : ", isEncryped);
        //console.log("getEncrypedValue id : ", id);
        //console.log("getEncrypedValue valueField : ", valueField);
        //console.log("getEncrypedValue nameField : ", nameField);
        //console.log("getEncrypedValue Value : ", ret);
        //console.log("---------------------");
        
        return ret;         
            
    }
    catch (error) {
        //console.log("getEncrypedValue : ", error);
        return valueField;
    }
}

/**
 *  
 * @param {any} key
 * key to encryped
 * @param {any} valueField 
 * value to encryped
 */
export const getEncrypedValueStd = async (key, valueField) => {
    var ret = "";

    try
    {
        ret = await encryptedFunction(valueField, key);
            
        return ret;

    }
    catch (error) {
        //console.log("getEncrypedValue : ", error);
        return valueField;
    }
}
/**
 * 
 * @param {any} inputFile
 */
export const readUploadedFileAsByte = async (inputFile) => {

    const temporaryFileReader = new FileReader();

    return new Promise((resolve, reject) => {

        temporaryFileReader.onerror = () => {

            temporaryFileReader.abort();

            reject(new DOMException("Problem parsing input file."));

        };

        temporaryFileReader.onload = () => {

            var bytes = new Uint8Array(temporaryFileReader.result);            

            resolve(bytes);
        };

        temporaryFileReader.readAsArrayBuffer(inputFile);
    });
};