
export const getLocalTicket = (store) => {

    var Code = store.getState().ticketReducer.Code;
    
    var appoint = store.getState().ticketReducer.appoint;
    var information = store.getState().ticketReducer.information;
    
    var ticket = {
        Code: Code, 
        appoint: appoint,
        reqInformation: information,
        legalEntity: store.getState().ticketReducer.legalEntity,
        isPrevalutation: store.getState().ticketReducer.isPrevalutation,
        canale: store.getState().ticketReducer.canale
        /*
        State: "",
        LegalEntity: "",
        ReportingFromOtherChannel: "",
        DirectChannelInvolved: "",
        PublicAdmin: "",
        Canale: "",
        Canali: "",
        isPrevalutation: "",
        Category: "",
        Violazioni: "",
        Process: "",
        newProcess: "",
        Description: "",
        DocumentiSegnalati: "",
        DocumentiPercorso: "",
        DocumentiAltraModalita: "",
        SegnalazioneAutorita: "",
        QualeAutorita: "",
        Name: "",
        Surname: "",
        Email: "",
        Phone: "",
        isSend: "",
        People:"",        

        reqDocument: "",
        DocumentsAttach: "",
        Message:""
        */
    }

    console.log("getLocalTicket : ", ticket);

    return ticket;
};
