import * as actionTypes from '../Actions/actionTypes'

const initialState = {
    Files : [],
}


const step5Reducer = (state = initialState, action) => {
    switch (action.type) {

        case actionTypes.CLEAN:
            {
                return {
                    ...initialState,
                }
            }


        case actionTypes.ADD_FILE: 
        {          
          return {
            ...state, 
            Files : [...state.Files,action.payload]            
          }         
        }
        case actionTypes.REMOVE_FILE: 
        {          
          return {
            ...state, 
            Files : [...state.Files.filter(element => element !== action.payload)  
            ]         
          }                  
        }        
                            
        default: 
          return state;
    }

  }
  
 export default step5Reducer;