import React from 'react'

import Button from '@material-ui/core/Button';
import { useTranslation, withTranslation } from "react-i18next";
import Typography from '@material-ui/core/Typography'
import moment from 'moment'


function MessageDoc(props) {  
    const { t } = useTranslation();

    const localeFormatMap = props.Store.getState().ticketReducer.dataFormatLanguage;
    //const dateTime = new Date(props.dateExpiring).toUTCString();
    const dateTime = moment.utc(props.dateExpiring, 'yyyy/MM/DD hh:mm:ss').toDate();


    const handleClose = () => {
        props.handleClose();
    }
    return (
        <div>
            {(props.openDialog === true && 
            <div className="popup-box">                
                <div>
                    <div className="box-header">
                        {/*<h3 id="dbox-header">{t('Label_MessageDoc_Header')}</h3> */} 
                        <Typography variant="h6">{t('Label_MessageDoc_Header')}</Typography>
                    </div>
                    <div className="box-header">
                        <div className="Row">
                            <Typography variant="p">{t('Label_Gentilissimo')},</Typography>                         
                        </div>
                        <div className="Row">
                            <Typography variant="p">{t('Label_MessageDoc_Row1')} <b>{props.CodeTicket}</b></Typography>
                        </div>
                        <div className="Row">
                                <Typography variant="p">{t('Label_MessageDoc_Row2')} <b>{moment(dateTime).format(localeFormatMap.replace("dd", "DD").replace("d", "D"))}</b> {t('Label_MessageDoc_Row3')}</Typography>
                        </div>
                        <div className="Row">
                            <Typography variant="p">{t('Label_MessageDoc_Row4')}</Typography>
                        </div>
                        <div className="Row">
                            <Typography variant="p">{t('Label_MessageDoc_Row5')}</Typography>                       
                        </div>
                        <div className="Row">
                            <Typography variant="p">{t('Label_MessageDoc_Row6')}</Typography>    
                        </div>
                    </div>
                    <div style={{ display: "flex" }}>
                        <Button onClick={handleClose} color="primary" autoFocus style={{marginLeft: "auto"}}>
                            {t('Button_Close')}
                        </Button> 
                     </div>                       
                </div>
            </div>
            )}
        </div>
    )
}

export default withTranslation()(MessageDoc)
