import React, { Component } from 'react'

import Input from "@material-ui/core/Input";
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import Checkbox from '@material-ui/core/Checkbox';
import TextField from '@material-ui/core/TextField';
import ButtonSection from '../Components/ButtonSection'
import Chip from '@material-ui/core/Chip';
import * as actions from '../Actions/actionStep2'
import * as Helper from '../Helper';
import { withTranslation } from "react-i18next";
import Typography from '@material-ui/core/Typography'

export class WizardPage2 extends Component {
    constructor(props){
        super(props);
            
        this.classes = {
            chips: {
                display: "flex",
                flexWrap: "wrap"
              },
              chip: {
                margin: 2
              }            
        }

        this.state = { 
            disabledNext: this.props.store.getState().step2Reducer.disabledNext,
            legalEntityList: this.props.store.getState().ticketReducer.legalEntityList,
            legalEntity: this.props.store.getState().ticketReducer.legalEntity,  
            ViolationList: this.props.store.getState().step2Reducer.ViolationList,
            Categories: this.props.store.getState().step2Reducer.Categories,
            Category : this.props.store.getState().step2Reducer.Category,
            Violazioni: this.props.store.getState().step2Reducer.Violazioni,
            newViolazione: this.props.store.getState().step2Reducer.newViolazione,  
            newViolazioneActive: this.props.store.getState().step2Reducer.newViolazioneActive,
            formErrors : {
                Category : "",
                Violazioni : ""            
            },
            ViolationRoot: this.getViolationRoot(this.props.store.getState().ticketReducer.legalEntity)
        }
    }
  
    componentWillUnmount() {
        this.unsubscribe();
        }    
        
    componentDidMount = () => {                
        this.unsubscribe = this.props.store.subscribe(() => {              
            this.setState({
                disabledNext: this.props.store.getState().step2Reducer.disabledNext, 
                legalEntityList: this.props.store.getState().ticketReducer.legalEntityList,
                legalEntity: this.props.store.getState().ticketReducer.legalEntity,  
                ViolationList: this.props.store.getState().step2Reducer.ViolationList,
                Categories: this.props.store.getState().step2Reducer.Categories,
                Category : this.props.store.getState().step2Reducer.Category,
                Violazioni: this.props.store.getState().step2Reducer.Violazioni,
                newViolazione: this.props.store.getState().step2Reducer.newViolazione, 
                newViolazioneActive: this.props.store.getState().step2Reducer.newViolazioneActive,
            })     
        })
    } 
    
    renderSelectOptions_Category = () =>{
        //const dataCategory = api.getCategory();
        //console.log(this.state.Categories);
        return this.state.Categories.map((dt,i) => {
            return(
                <MenuItem 
                    label="Select " 
                    value={dt.key} 
                    key={i} 
                    name={dt.value}>{dt.value}</MenuItem>
            );
        });
    };  

    getViolationRoot = (value) => {
        var ret = value;

        for (let item of this.props.store.getState().ticketReducer.legalEntityList) {
            if (item.key === value) {
                ret = item.violationRoot;
            }
        }

        return ret;
    }

    makeItemViolazioni = () => {
   
        let data = []
        const items = [];
        const { t } = this.props;

        if (this.state.ViolationRoot.length > 0)
        {
            data = this.state.ViolationList?.children?.filter(el => el.id === this.state.ViolationRoot);

            for (let soc of data) {
                if (soc.children !== null) {
                    for (let item of soc.children) {
                        items.push(
                            <div>
                                <FormControlLabel key={item.id}
                                    control={<Checkbox disabled={this.props.disabled} value={item.id} name={item.value} key={item.id} id={item.id}
                                        onChange={this.checkBoxChanged} checked={this.state.Violazioni.indexOf(item.id) > -1} />}
                                    label={item.value}
                                />
                            </div>
                        );
                        if (item.children !== null) {

                            for (let child of item.children) {
                                items.push(
                                    <MenuItem key={child.id} value={child.id} name={child.value} >
                                        <Checkbox disabled={this.props.disabled} key={child.id} value={child.id} name={child.value} inputProps={{ 'data-id': item.id }}
                                            onChange={this.checkBoxChanged}
                                            checked={this.state.Violazioni.indexOf(child.id) > -1} />
                                        {child.value}
                                    </MenuItem>
                                );
                            }
                        }
                    }
                }
            }
        }            
        else
        {
            data = this.state.ViolationList.children;


            for (let item of data) {
                items.push(
                    <div>
                        <FormControlLabel key={item.id}
                            control={
                                <Checkbox
                                    disabled={this.props.disabled}
                                    value={item.id}
                                    name={item.value}
                                    key={item.id}
                                    id={item.id}
                                    onChange={this.checkBoxChanged} checked={this.state.Violazioni.indexOf(item.id) > -1}
                                />
                            }
                            label={item.value}
                        />
                    </div>
                );
                if (item.children !== null) {
                    for (let child of item.children) {
                        items.push(
                            <MenuItem key={child.id} value={child.id} name={child.value} >
                                <Checkbox disabled={this.props.disabled} key={child.id} value={child.id} name={child.value} inputProps={{ 'data-id': item.id }}
                                    onChange={this.checkBoxChanged}
                                    checked={this.state.Violazioni.indexOf(child.id) > -1} />
                                {child.value}
                            </MenuItem>
                        );
                    }
                }
            }
        }

        // Add altro
        items.push(
            <FormControlLabel key="9999"
                control={<Checkbox value="9999"
                    onChange={this.checkBoxChanged} checked={this.state.Violazioni.indexOf("9999") > -1} />}
                label={t('Label_Violation_Other')}
            />
        );           
        return items;             
    };

    getLabelChip = (value) => {
        const data = this.state.ViolationList;

        if (value === "9999")
            return this.props.t('Label_Violation_Other') ;

        if (this.state.ViolationRoot.length > 0)
        {
            if (data !== null) {
                for (let item of data.children) {
                    if (item.id === value)
                        return item.value;
                    if (item.children !== null) {
                        for (let child of item.children) {
                            if (child.id === value)
                                return child.value;

                            if (child.children !== null) {
                                for (let proc of child.children) {
                                    if (proc.id === value)
                                        return proc.value;
                                }
                            }
                        }
                    }
                }
            }
        }
        else {
            if (data !== null && data.children !== null) {
                for (let item of data.children) {
                    if (item.id === value)
                        return item.value;

                    if (item.children !== null) {
                        for (let child of item.children) {
                            if (child.id === value)
                                return child.value;
                        }
                    }
                }
            }
        }


        return '';
    }

    checkBoxChanged = (e, v) => {
        var rootId = e.target.getAttribute("data-id");

        if (e.target.value === "9999" && e.target.checked === true) {                                    
            this.props.store.dispatch(actions.setCheckNewViolazioneActive(true)); 
        }

        if (e.target.value === "9999" && e.target.checked === false) {            
            this.props.store.dispatch(actions.setCheckNewViolazioneActive(false)); 
        }
              
        if(e.target.checked === true)
        {
            if(rootId !== null)
            {
                var isExist = false
                for(var i =0; i <= this.state.Violazioni.length; i++){
                    if(this.state.Violazioni[i] === rootId){
                        isExist = true;
                        break;
                    }                              
                }  
                if(!isExist)
                {
                    this.props.store.dispatch(actions.Add(               
                        rootId          
                    ));  
                }               
            }
            this.props.store.dispatch(actions.Add(               
                e.target.value          
            ));  
        }
        else
        {      
            if(rootId !== null)
            {
                var isNUll = this.getChildsNull(rootId);                
                if(isNUll){
                    this.props.store.dispatch(actions.remove(               
                        rootId));
                }                
            }

            this.props.store.dispatch(actions.remove(               
                e.target.value
            ));             
        }
     
        this.checkValidate();
      };        
    
    getChildsNull = (rootId) => {        
        for(var i =0; i <= this.state.Violazioni.length; i++){
            if(this.state.Violazioni[i] === rootId){
                return false;
            }                              
        }          
          
        return true;
    }

    onChangeCategory = (ev) => {
        ev.preventDefault();

        this.props.store.dispatch(actions.next(
            ev.target.value,
            this.state.Violazioni
        ));   

        this.checkValidate();
    };  

    onChangeViolazioni = (ev, obj) => {
        ev.preventDefault();                
    };         
    checkError = () => {
        var ret = false;
        var category = "";
        var violazioni = "";
        const { t } = this.props;

        if(this.state.Category.length === 0) 
        {
            ret = true;            
            category = t(Helper.FIELD_MANDATARY);
        }

        if(this.state.Violazioni.length === 0) 
        {
            ret = true;            
            violazioni = t(Helper.FIELD_MANDATARY);
        }        

        this.setState({formErrors : {
                Category : category,
                Violazioni : violazioni
            }
        }, () => {
            //console.log("checkError",this.state)
        });

        return ret;        
    };    

    onNext = (event) => { 
        // Validate
        if(!this.checkError())      
        {            
            this.props.store.dispatch(actions.next(
                this.state.Category,
                this.state.Violazioni));
                    
            this.props.nextPage(event);
        }
    };    

    onChange_newViolazione = (e) => {
        //console.log(e.target.value);           
        this.props.store.dispatch(actions.AddNewViolazione(
            e.target.value));

        this.checkValidate();
    }


    // Validation step
    checkValidate = () => {
        
        if (this.props.store.getState().step2Reducer.Category.length > 0 && this.props.store.getState().step2Reducer.Violazioni.length > 0) {            
            if (this.props.store.getState().step2Reducer.newViolazioneActive === false || (this.props.store.getState().step2Reducer.newViolazioneActive === true && this.props.store.getState().step2Reducer.newViolazione.length > 0)) {                
                this.props.store.dispatch(actions.setDisabled(false));
            }
            else {                
                this.props.store.dispatch(actions.setDisabled(true));
            }
        }
        else {            
            this.props.store.dispatch(actions.setDisabled(true));
        }

        return;
    };

    render() {
        const { Category, Violazioni, newViolazione,formErrors} = this.state   
        const { t } = this.props; 

        return(
            <div>
                <div className="main">
                    <div className="row"> 
                        <FormControl className="width100">               
                            <InputLabel id="label_Category">{t('Label_Category')}</InputLabel>
                                <Select  disabled={this.props.disabled}                                                                
                                    labelId="label_Category"
                                    id="Category-select"
                                    value={Category}
                                    onChange={this.onChangeCategory}               
                                    >
                                    {this.renderSelectOptions_Category()}

                                </Select>
                            </FormControl>
                            {formErrors.Category.length > 0 && (
                                <span className="errorMessage">{formErrors.Category}</span>
                            )}                    
                    </div>
                    <div className="row">
                        <Typography variant="h6">{t('Label_Object_Ticket')}</Typography>
                    </div>                    
                    <div className="row"> 
                        <FormControl className="width100">               
                            <InputLabel id="Violazioni-select">{t('Label_Violation')}</InputLabel>
                            <Select
                                disabled={this.props.disabled} 
                                labelId="Violazioni-select"
                                id="Violazioni-select"                                                                                                                           
                                multiple                                
                                value={Violazioni} 
                                input={<Input id="select-multiple-chip" />}
                                renderValue={ (selected) => (
                                    <div className="chips">
                                      {selected.map((value) => (
                                        <Chip className="chip" 
                                            size="small"
                                            key={value} 
                                            label={
                                                <InputLabel id={value}>{
                                                    this.getLabelChip(value)}
                                                </InputLabel>
                                            }                                             
                                        />
                                      ))}
                                    </div>
                                  )}                                                                                                         
                                >                                          
                                {this.makeItemViolazioni()}                                
                            </Select>
                        </FormControl> 
                        {formErrors.Violazioni.length > 0 && (
                                <span className="errorMessage">{formErrors.Violazioni}</span>
                            )}                                           
                    </div>
                    <div className="row">
                        {this.state.Violazioni.indexOf("9999") > -1 &&
                            <TextField disabled={this.props.disabled} className="width100"
                                id="newViolazione"
                                label={t('Label_Violation_Other')}
                                value={newViolazione}
                                onChange={this.onChange_newViolazione}
                                onBlur={this.onChange_newViolazione} />}
                    </div>                    
                </div>
                {this.props.previousPage.length > 0 &&
                    <ButtonSection 
                        disabledNext={this.state.disabledNext}
                        previousPage={this.props.previousPage}   
                        nextPage={this.onNext}
                        namePrevious={this.props.namePrevious}                            
                        nameNext={this.props.nameNext}
                    /> 
                } 
            </div>
        )
    }
}

export default withTranslation()(WizardPage2)