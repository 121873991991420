const axios = require('axios').default;
const { v4: uuidv4 } = require('uuid');

export const getTranslate = async (text, isBackEnd, store) => {

    console.log("getTranslate", text, isBackEnd);

    return new Promise(function (resolve, reject) {

        if (!text || text.length === 0)
            resolve(text);

        let key = "0e106193a55d43dcb22357dc2e76d60b";
        let endpoint = "https://api.cognitive.microsofttranslator.com";

        // location, also known as region.
        // required if you're using a multi-service or regional (not global) resource. It can be found in the Azure portal on the Keys and Endpoint page.
        let location = "westeurope";

        axios({
            baseURL: endpoint,
            url: '/translate',
            method: 'post',
            headers: {
                'Ocp-Apim-Subscription-Key': key,
                // location required if you're using a multi-service or regional (not global) resource.
                'Ocp-Apim-Subscription-Region': location,
                'Content-type': 'application/json',
                'X-ClientTraceId': uuidv4().toString()
            },
            params: {
                'api-version': '3.0',
                'from': isBackEnd === false ? store.getState().ticketReducer.language : store.getState().ticketReducer.languageBackEnd,
                'to': isBackEnd === false ? store.getState().ticketReducer.languageBackEnd : store.getState().ticketReducer.language
            },
            data: [{
                'text': text
            }],
            responseType: 'json'
        }).then(function (response) {
            resolve( response.data[0].translations[0].text );
        }).catch(err => {
            reject();
        });
        
       
    });

};