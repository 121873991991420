import * as actionTypes from '../Actions/actionTypes'

const initialState = {    
    Code: '',
    KeyCode: '',
    legalEntity: '',
    legalEntityList: [],
    publicAdmin: false,
    isPrevalutation: '',
    canale:'',
    loading: true,
    error: false,
    privacy: false,
    identity: false,
    showDialogState : false,
    showDialogConvocation : false,
    showDialogDocument: false,
    showDialogInfo: false,
    showPrevalutation: true,
    showPublicAdmin: true,
    showRecorder: false,
    stateTicket: '',
    closed:'',
    closeNotes: '',
    notesToReporting: '',
    stateTicketList: [],
    docAdditional: [],
    resources: '',
    recorderAudioCheck: false,    
    recorderAudioFile: '',
    AudioUrl: null,
    numberDaysToExpiration: '',
    maxMinutes: 5,
    timeSecondsPre:20,
    appoint: {
        idAction: '',
        date: '',
        hours: '',
        details: '',
        isApproved: false,
        isChanged: false,
        isRefused: false,
        notes: ''
    },
    information: {
        idAction: '',
        request: '',
        response: '',
        isVisible : false
    },
    documents: {
        idAction: '',
        list: [],
        dateExpiring: '',
        isVisible: false
    },
    key: '',
    fieldsEncryptedEsclude: [],
    link: {
        linkDirettiva: '',
        linkInformativa: '',
        linkPrivacy: '',
        linkModello231:''
    },
    maxFileSize: 0,
    language: '',
    culture: '',
    languageInstalled: [],
    languageBackEnd:'',
    dataFormatLanguage: 'd MMM yyyy',
    timeFormatLanguage: ''

    //dateAppoint: '',
    //hoursAppoint : '',
    //linkAppoint : '',
    //dateDocAdd : ''
}


const ticketReducer = (state = initialState, action) => {


    console.log(action.type, action.payload);
    switch (action.type) {

        case actionTypes.CLEAN:
            {
                return {
                    ...state,
                    Code: '',
                    KeyCode: '',
                    legalEntity: '',
                    isPrevalutation: '',
                    canale: '',
                    loading: true,
                    error: false,
                    privacy: false,
                    identiy: false,
                    showDialogState: false,
                    showDialogConvocation: false,
                    showDialogDocument: false,
                    showDialogInfo: false,
                    stateTicket: '',                  
                    docAdditional: [],
                    numberDaysToExpiration: '',  
                    recorderAudioCheck: false,
                    recorderAudioFile: '',
                    AudioUrl:null,
                    appoint: {
                        idAction: '',
                        date: '',
                        hours: '',
                        details: '',
                        isApproved: false,
                        isChanged: false,
                        isRefused: false,
                        notes: ''
                    },
                    information: {
                        idAction: '',
                        request: '',
                        response: '',
                        isVisible: false
                    },
                    documents: {
                        idAction: '',
                        list: [],
                        dateExpiring: '',
                        isVisible: false
                    },                    
                }
            }

        case "DISABLE_PREVALUTATION":
            {
                return {
                    ...state,
                    showPrevalutation: action.payload
                }
            }

        case "DISABLE_PUBLIC_ADMIN":
            {
                return {
                    ...state,
                    showPublicAdmin: action.payload
                }
            }

        case "DISABLE_RECORDER":
            {
                return {
                    ...state,
                    showRecorder: action.payload
                }
            }
            
        case "LANGUAGE":
            {
                return {
                    ...state,
                    language: action.payload
                }
            }
        case "CULTURE":
            {
                return {
                    ...state,
                    culture: action.payload
                }
            }
        case "LANGUAGE_DEFAULT":
            {
                return {
                    ...state,
                    languageBackEnd: action.payload
                }
            }

            
        case "DATA_FORMAT_LANGUAGE":
            {
                return {
                    ...state,
                    dataFormatLanguage: action.payload
                }
            }

        case "TIME_FORMAT_LANGUAGE":
            {
                return {
                    ...state,
                    timeFormatLanguage: action.payload
                }
            }

            

        case "LANGUAGE_INSTALLED":
            {
                return {
                    ...state,
                    languageInstalled: action.payload
                }
            }            
            
        case "CLEAN_INFO": {
            return {
                ...state,
                information: {
                    idAction: '',
                    request: '',
                    response: '',
                    isVisible: false
                }
            }
        }
        case "UPDATE_LEGAL_ENTITY":
            {
                return {
                    ...state,
                    legalEntity: action.payload
                }
            }

        case "UPDATE_PUBLIC_ADMIN":
            {
                return {
                    ...state,
                    publicAdmin: action.payload
                }
            }
            
        case "UPDATE_PREVALUTATION":
            {
                return {
                    ...state,
                    isPrevalutation: action.payload
                }
            }

        case "UPDATE_CANALE":
            {
                return {
                    ...state,
                    canale: action.payload
                }
            }

        case actionTypes.ERROR:
            {
                return {
                    ...state,
                    error: action.payload
                }
            } 
        case actionTypes.SET_ENCRYPTED_ESC:
            {
                return {
                    ...state,
                    fieldsEncryptedEsclude: action.payload
                }
            } 
        case actionTypes.SET_LINK:
            {                
                return {
                    ...state,
                    link: action.payload
                }
            } 
        case actionTypes.SET_FILE_MAX_SIZE:
            {
                return {
                    ...state,
                    maxFileSize: action.payload
                }
            } 
        case actionTypes.LOADING:
            {
                return {
                    ...state,
                    loading: action.payload
                }
            } 
        case actionTypes.READ_KEY:
            {
                return {
                    ...state,
                    key: action.payload
                }
            } 
        case actionTypes.SET_INFO:
            {
                return {
                    ...state,
                    information: action.payload
                }
            } 
        case actionTypes.SET_DOCUMENTS:
            {
                return {
                    ...state,
                    documents: action.payload
                }
            } 

        case actionTypes.SET_APPOINT:
            {
                return {
                    ...state,
                    appoint: action.payload
                }
            } 
        case actionTypes.LOAD_STATE:
            {
                return {
                    ...state,
                    stateTicketList: action.payload
                }
            }
        case actionTypes.UPDATE_STATE:
            {
                return {
                    ...state,
                    stateTicket: action.payload.state,
                    closed: action.payload.closed,
                    closeNotes: action.payload.closeNotes,
                    notesToReporting: action.payload.notesToReporting
                }
            }
            
        //case actionTypes.LOAD: 
        //{          
        //  return {
        //    ...state, 
        //    stateTicket : action.payload,
        //    dateAppoint : "2021/07/01",
        //    hoursAppoint : "20:34:00",
        //    linkAppoint : "http://teams.appoint.it",
        //    dateDocAdd : "01/07/2021"
        //  }         
        //}     
        
        case actionTypes.SHOW_DIALOG_STATE: 
        {          
          return {
            ...state, 
            showDialogState : action.payload
          }         
        }  
        
        case actionTypes.UPDATE_PRIVACY: 
        {          
          return {
            ...state, 
            privacy : action.payload
          }         
        } 

        case actionTypes.UPDATE_IDENTITY:
            {
                return {
                    ...state,
                    identity: action.payload
                }
            } 


        case actionTypes.SHOW_DIALOG_INFO:
            {
                return {
                    ...state,
                    showDialogInfo: action.payload
                }
            }  

        case actionTypes.SHOW_DIALOG_CONVOCATION: 
        {          
          return {
            ...state, 
            showDialogConvocation : action.payload
          }         
        }          
               
        case actionTypes.SHOW_DIALOG_DOCUMENT: 
        {          
          return {
            ...state, 
            showDialogDocument : action.payload
          }         
        }    
        
        case actionTypes.ADD_ADDITIONAL_DOC: 
        {                    
            return {
                ...state, 
                docAdditional: [...state.docAdditional.filter(p => p.id !== action.payload.id), action.payload]                
            }         
        }           

        case "LOAD_RESOURCES":
            {
                return {
                    ...state,
                    resources: action.payload
                }
            }      

        case "SET_MAX_MINUTES_TO_REGISTRATION":
            {
                return {
                    ...state,
                    maxMinutes: action.payload
                }
            }

        case "SET_TIME_SECONDS_PRE_TO_REGISTRATION":
            {
                return {
                    ...state,
                    timeSecondsPre: action.payload
                }
            }

        case "SET_RECORDER_AUDIO":
            {
                return {
                    ...state,
                    recorderAudioCheck: action.payload
                }
            }         

        case "SET_RECORDER_AUDIO_FILE":
            {
                return {
                    ...state,
                    recorderAudioFile: action.payload
                }
            }         

        case "SET_AUDIO_URL":
            {
                return {
                    ...state,
                    AudioUrl: action.payload
                }
            }       

            
        case "LOAD_LEGALENTITY":
            {
                return {
                    ...state,
                    legalEntityList: action.payload
                }
            }

        case "UPDATE_CODE":
            {
                return {
                    ...state,
                    Code: action.payload.code,
                    KeyCode: action.payload.keyCode,
                }
            }

      

        default: 
          return state;
    }



  }
  
 export default ticketReducer;