import i18next from 'i18next';
import { initReactI18next } from 'react-i18next';
import Backend from 'i18next-xhr-backend';
//import Backend from "i18next-http-backend";
import LanguageDetector from "i18next-browser-languagedetector";


export default function configureI18n( language, resources ) {

    let i18nextRes = {}
    i18nextRes[language] = { translation: { ...resources } }
    console.log("configureI18n language : ", language);
    console.log("configureI18n resources : ", i18nextRes);

    i18next
        .use(Backend)           // load translations using http (default public/assets/locals/en/translations)
        .use(LanguageDetector)  // detect user language
        .use(initReactI18next)  // pass the i18n instance to react-i18next.
        .init({
            debug: false,
            interpolation: { escapeValue: false },  // React already does escaping
            lng: language,
            fallbackLng: language,
            resources: i18nextRes,
            react: {
                useSuspense: false
            },
        });

    return i18next;
}



//const resources = {
//    it: {
//        translation: {
//            Click_Insert: "INSERIMENTO IT ",
//        },
//    },
//    en: {
//        translation: {
//            Click_Insert: "INSERIMENTO EN ",
//        },
//    },
//};

//i18next
//    .use(Backend)           // load translations using http (default public/assets/locals/en/translations)
//    .use(LanguageDetector)  // detect user language
//    .use(initReactI18next)  // pass the i18n instance to react-i18next.
//    .init({
//        debug: false,
//        interpolation: { escapeValue: false },  // React already does escaping
//        lng: 'it',
//        fallbackLng: 'en',
//        whitelist: availableLanguages,
//        //resources,
//        //backend: backendOptions,
//        backend: {
//            loadPath: '/Assets/locales/{{lng}}/translation.json',
//        },

//        react: {
//            useSuspense: false
//        },        
//    });

//export default i18next;