import * as actions0 from '../Actions/ticketActions'
import * as actions1 from '../Actions/actionStep1'
import * as actions2 from '../Actions/actionStep2'
import * as actions3 from '../Actions/actionStep3'
import * as actions4 from '../Actions/actionStep4'
import * as actions5 from '../Actions/actionStep5'
import * as actions6 from '../Actions/actionStep6'
import * as actions7 from '../Actions/actionStep7'
//import * as actions8 from '../Actions/actionStep8'
import * as ticket from '../Actions/ticketActions'

export const CleanStore = (store) => {

    //console.log("CleanStore");

    store.dispatch(actions0.clean()); 
    store.dispatch(actions1.clean());
    store.dispatch(actions2.clean());
    store.dispatch(actions3.clean());
    store.dispatch(actions4.clean());
    store.dispatch(actions5.clean());
    store.dispatch(actions6.clean());
    store.dispatch(actions7.clean());    
    //store.dispatch(actions8.clean());
    store.dispatch(ticket.clean());

    store.dispatch(ticket.loading(false));
};
