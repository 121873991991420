import {createStore} from 'redux';  
import { rootReducer } from '../Reducers/roorReducer';  
 
/*Create a function called configureStore */

export default function configureStore() {  
    if (process.env.NODE_ENV === 'production') {        
        return createStore(rootReducer);
    }
    else {
       
        const store = createStore(
            rootReducer,
            window.__REDUX_DEVTOOLS_EXTENSION__ &&
            window.__REDUX_DEVTOOLS_EXTENSION__());

        return store;
    }
}
