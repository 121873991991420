import React, { Component } from 'react'
import errorImg from '../img/error.png';
import { withTranslation } from "react-i18next";
//import Button from '@material-ui/core/Button';


export class ErrorPage extends Component {

    reload = () => {
        //window.location.reload();
        //this.props.history.push('/Home')
    }
    render() {    
        const { t } = this.props;
        return (
            <div>
                <div className="main">
                    <div className="error">
                        <img src={errorImg} />
                        <h3>{t('Error')}</h3>
                    </div>
                    <div className="divider" />
                    {/*<div className="center">*/}
                    {/*    <Button type="button" variant="contained" style={{ width: "20%" }} onClick={this.reload.bind(this)}>*/}
                    {/*        <span>Reload</span>*/}
                    {/*    </Button>*/}
                    {/*</div>*/}
                </div>
            </div>
        )
    }
}

export default withTranslation()(ErrorPage)
//export default  ErrorPage
