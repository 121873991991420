import React, { Component } from 'react'
import { PDFDownloadLink } from '@react-pdf/renderer';
import { Page, Text, View, Document, StyleSheet, PDFViewer } from '@react-pdf/renderer';
import Button from '@material-ui/core/Button';
import {Link} from "react-router-dom";
import { CleanStore } from '../Actions/clean';
import { withTranslation } from "react-i18next";
import Typography from '@material-ui/core/Typography'
import moment from 'moment'
import { concatTreeLabels } from '../Functions/functions'
// Create styles
const styles = StyleSheet.create({
    page: {
        backgroundColor: '#FFFFFF',
    },
    section: {
        display: 'flex',
        flexDirection: 'row',
        textAlign: 'center',
        width: "100%",
        verticalAlign: 'middle'
    },
    table: {
        width: '100%',
        margin: 10,
        padding: 10,
    },
    row: {
        display: 'flex',
        flexDirection: 'row',
        borderTop: '1px solid #EEE',
        paddingTop: 8,
        paddingBottom: 8,
    },
    key: {
        marginLeft: 20,
        width: "40%",
        fontSize: 14,
    },
    value: {
        marginLeft: 20,
        width: "60%",
        fontSize: 12,
    },
});

// Create Document Component
const MyDocument = (props) => {

    const t = props.t;
    const code = props?.state?.ticketReducer?.Code;
    const key = props.state?.ticketReducer?.KeyCode;
    const date = moment(Date.now()).format( props.state?.ticketReducer?.dataFormatLanguage.replace("dd", "DD").replace("d", "D"));
    const time = moment(Date.now()).format( props.state?.ticketReducer?.timeFormatLanguage);
    const legalEntity = props.state?.ticketReducer?.legalEntityList?.find( l => l.key ===  props.state?.ticketReducer?.legalEntity)?.value;
    const category = props.state?.step2Reducer?.Categories?.find(x => x.key === props.state?.step2Reducer?.Category)?.value;
    const area = concatTreeLabels(props.state?.step3Reducer?.Process, props.t('Label_Process_Other'), "9999", props.state?.step3Reducer?.newProcess, props.state?.step3Reducer?.ProcessList.children);
    const subject = concatTreeLabels(props.state?.step2Reducer?.Violazioni, props.t('Label_Process_Other'), "9999", props.state?.step2Reducer.newViolazione, props.state?.step2Reducer?.ViolationList.children);
    const text = props.state?.step4Reducer?.Description.length > 0 ? props.state?.step4Reducer?.Description : t('txtPDFText');
    const involvedSubject = props.state?.step3Reducer.Rows.map(r => r.Name + ' ' + r.Surname);
    const attachments = props.state?.step5Reducer?.Files?.map(f => f.name);
    const documents = t('txtPBDDocuments').replace('{0}', props.state?.step6Reducer?.DocumentiSegnalati.length > 0 ? props.state?.step6Reducer?.DocumentiSegnalati : t('txtPBDDocumentsNoData'))
        .replace('{1}', props.state?.step6Reducer?.DocumentiPercorso.length || props.state?.step6Reducer?.DocumentiAltraModalita.length > 0 ? props.state?.step6Reducer?.DocumentiPercorso.length > 0 ? props.state?.step6Reducer?.DocumentiPercorso.concat('\n', props.state?.step6Reducer?.DocumentiAltraModalita) : props.state?.step6Reducer?.DocumentiAltraModalita : t('txtPBDDocumentsNoData'))
       
    const alreadySegnaled = props.state?.step6Reducer?.SegnalazioneAutorita ==="Y" ? "Si" : "No";
    const personalData = { ...props.state?.step7Reducer };
  
   //console.log(props?.state)

    return (
        <Document>
            <Page size="A4" style={styles.table}>
                <View style={styles.section}>
                    <Text>{t('lblPDFHeader')}</Text>
                 </View>

                <View style={styles.row}>
                    <Text style={styles.key}>{t('lblPDFCode')}</Text>
                    <Text style={styles.value}>{code}</Text>
                </View>

                <View style={styles.row}>
                    <Text style={styles.key}>{t('lblPDFKey') }</Text>
                    <Text style={styles.value}>{key}</Text>
                </View>

                <View style={styles.row}>
                    <Text style={styles.key}>{t('lblPDFDate')}</Text>
                    <Text style={styles.value}>{date}</Text>
                </View>

                <View style={styles.row}>
                    <Text style={styles.key}>{t('lblPDFHour')}</Text>
                    <Text style={styles.value}>{time}</Text>
                </View>

                <View style={styles.row}>
                    <Text style={styles.key}>{t('lblPDFLegalEntity')}</Text>
                    <Text style={styles.value}>{legalEntity}</Text>
                </View>

                <View style={styles.row}>
                    <Text style={styles.key}>{t('lblPDFArea')}</Text>
                    <Text style={styles.value}>{area}</Text>
                </View>

                <View style={styles.row}>
                    <Text style={styles.key}>{t('lblPDFCategoty')}</Text>
                    <Text style={styles.value}>{category}</Text>
                </View>

                <View style={styles.row}>
                    <Text style={styles.key}>{t('lblPDFSubject')}</Text>
                    <Text style={styles.value}>{subject}</Text>
                </View>

                <View style={styles.row}>
                    <Text style={styles.key}>{t('lblPDFText')}</Text>
                    <Text style={styles.value}>{text}</Text>
                </View>

                <View style={styles.row}>
                    <Text style={styles.key}>{t('lblPDFInvolvedSubjects')}</Text>
                    <Text style={styles.value}>{involvedSubject}</Text>
                </View>

                <View style={styles.row}>
                    <Text style={styles.key}>{t('lblPDFAttachments')}</Text>
                    <Text style={styles.value}>{ attachments}</Text>
                </View>
                
                <View style={styles.row}>
                    <Text style={styles.key}>{t('ldlPBDDocuments')}</Text>
                    <Text style={styles.value}>{ documents}</Text>
                </View>
 
                <View style={styles.row}>
                    <Text style={styles.key}>{t('lblPDFAlreadySegnaled')}</Text>
                    <Text style={styles.value}>{ alreadySegnaled}</Text>
                </View>

                <View style={styles.row}>
                    <Text style={styles.key}>{t('lblPDFPersonalData')}</Text>
                    <Text style={styles.value}>{personalData ?
                        
                        t('txtPDFPersonalData').replace('{0}', personalData.Name)
                            .replace('{1}', personalData.Surname)
                            .replace('{2}', personalData.Email)
                            .replace('{3}', personalData.Phone)
                        

                        : null}</Text>
                </View>
         
            </Page>
        </Document>
    );
}
export class WizardPage8 extends Component {
    constructor(props){
        super(props);

        this.state = {
            CodiceTicket: this.props.store.getState().ticketReducer.Code,
            keyCodeTicket: this.props.store.getState().ticketReducer.KeyCode
        }       
    }   

    componentWillUnmount() {
        this.unsubscribe();
    }

    componentDidMount = () => {
        this.unsubscribe = this.props.store.subscribe(() => {
            this.setState({
                CodiceTicket: this.props.store.getState().ticketReducer.Code,
                keyCodeTicket: this.props.store.getState().ticketReducer.KeyCode
            })
        })
    }

    onClick_Fine = () => {
        CleanStore(this.props.store);
    }

    render() {
        const { CodiceTicket, keyCodeTicket } = this.state;
        const { t } = this.props;
        return(
            <div>
                <div className="main">
                    <div className="row">
                        <Typography variant="h5">{t('Label_Identify_Code')}</Typography> 
                    </div>                            
                    <div className="row">
                        <Typography variant="h6">{t('Label_Identify_Code_1')}</Typography>
                    </div>  
                    <div className="row">
                        <Typography variant="h6">{t('lblCode')} : <b style={{ color: 'red' }}>{CodiceTicket}</b></Typography>
                    </div> 
                    <div className="row">
                        <Typography variant="h6">{t('lblKey')} : <b style={{ color: 'red' }}>{keyCodeTicket}</b></Typography>
                    </div> 
                    <div className="row">
                        <Typography variant="h6">{t('Label_Identify_Code_2')}</Typography>

                    </div>  
                   
                    <div className="row">
                        <Typography variant="h6">{t('Label_Identify_Code_3')}</Typography>
                    </div>   
                    <div className="row">
                        <ul style={{marginLeft:'30px'}}>
                            <li>{t('Label_Identify_Code_3_1')}</li>
                            <li>{t('Label_Identify_Code_3_2')}</li>
                        </ul>
                    </div>   
                    <div className="row" style={{color:'red'}}>
                        <Typography variant="h6">{t('Label_Attention')}</Typography>
                        <Typography variant="h6">{t('Label_Attention_1')}</Typography>
                    </div>     
                    <div className="row" style={{ color: 'red' }}>
                        <Typography variant="h6">{t('txtDownloadPdf')}</Typography>

                    </div>     
                    <div className="row">
                     
                        <PDFDownloadLink document={<MyDocument state={this.props.store.getState()} t={t} />} fileName={t('txtPDFFileName')}>
                            <Button
                                style={{ width: "10%", marginLeft: "45%" }}
                                color="primary"
                                variant="contained">{t('btnDownloadPdf')}
                            </Button>

                        </PDFDownloadLink>

                    </div>
                </div> 
             
            </div>
        )
    }
}

export default withTranslation()(WizardPage8)