import * as actionTypes from './actionTypes';

export function updateDocSegnalati(item) {    
    return {
       type: actionTypes.ADD_DOC_SEGNALATI,
       payload : item
    }
 }

 export function updateDocPercorso(item) {    
    return {
       type: actionTypes.ADD_DOC_PERCORSO,
       payload : item
    }
 }

 export function updateAltraModalita(item) {    
    return {
       type: actionTypes.ADD_DOC_ALTRA_MODALITA,
       payload : item
    }
 }

 export function updateSegAutorita(item) {    
    return {
       type: actionTypes.ADD_SEGNALA_AUTORITA,
       payload : item
    }
}

export function updateQualeAutorita(item) {
    return {
        type: actionTypes.ADD_QUALE_AUTORITA,
        payload: item
    }
}

 export function updateDocAssenti(item) {    
    return {
       type: actionTypes.ADD_DOC_ASSENTI,
       payload : item
    }
}

export function clean() {
    return {
        type: actionTypes.CLEAN,
    }
}
