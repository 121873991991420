/**
 * 
 * @param {any} value
 * @param {any} LegalEntityList
 */
export const getProcessRoot = (value, LegalEntityList) => {
    var ret = value;

    for (let item of LegalEntityList) {
        if (item.key === value) {
            return item.processRoot;
        }

    }

    return ret;
}
/**
 * 
 * @param {any} id
 * @param {any} list
 */
export const getLabelFromList = (id, list) => {

    for (let item of list) {
        if (item.key === id)
            return item.value;
    }

    return id;
}
/**
 * 
 * @param {any} listSelect
 * @param {any} list
 */
export const getLabelListFromList = (listSelect, list) => {
    var ret = '';

    //console.log("listSelect  : ", listSelect);
    //console.log("list  : ", list);

    if (listSelect !== null) {
        for (let id of listSelect) {
            if (list !== null) {
                for (let item of list) {
                    if (id === item.id) {
                        ret = ret + item.value + '; ';
                        continue;
                    }

                    if (item.children !== null) {
                        var listTemp = new Array(id);
                        var tmp = getLabelListFromList(listTemp, item.children);
                        ret = ret + tmp;
                    }
                }
            }
        }
    }

    return ret;
}

/**
 * check if date is valid
 * 
 * @param {any} date
 * @returns
 */
export const validateDate = (date) => {
    var todayDate = new Date();
    todayDate.setHours(0, 0, 0, 0);

    return date > todayDate;

}

/**
 * CONCAT SELECTED NODE LABELS IN TREE
 * 
 * @param {any} idSelectionList
 * @param {any} customLabel
 * @param {any} customId
 * @param {any} customValue
 * @param {any} idTree
 * @returns
 */
export const concatTreeLabels = (idSelectionList, customLabel, customId, customValue, idTree) => {
    var label = '';
    var nodes = getTreeLinearNodes(idTree, customLabel, customId, customValue);
    for (var j = 0; j < nodes.length; j++) {
        if (!idSelectionList.includes(nodes[j].id)) continue
        label = label + nodes[j].value + ', ';
    }

    // remove tail separator
    if (label.length > 2) label = label.substring(0, label.length - 2)

    return label;
}

/**
 * LINEARIZES NODE TREE (MAX 3 LEVELS), ADDING CUSTOM NODE
 * 
 * @param {any} tree
 * @param {any} customNodeLabel
 * @param {any} customNodeId
 * @param {any} customNodeValue
 * @returns
 */
export const getTreeLinearNodes = (tree, customNodeLabel, customNodeId, customNodeValue) => {
    const nodes = [];

    if (tree !== null) {
        //LEVEL1
        for (let nodeL1 of tree) {
            nodes.push(nodeL1);
            if (nodeL1.children == null) continue;

            //LEVEL2
            for (let nodeL2 of nodeL1.children) {
                nodes.push(nodeL2);
                if (nodeL2.children == null) continue;

                //LEVEL3
                for (let nodeL3 of nodeL2.children) {
                    nodes.push(nodeL3);
                }
            }
        }
    }

    // ADD CUSTOM NODE
    if (customNodeId !== null) {
        const customNode = { id: customNodeId, value: customNodeLabel, children: null };
        customNode.value = customNodeLabel + ': ' + customNodeValue;
        nodes.push(customNode);
    }

    return nodes;
}