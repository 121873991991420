import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux'
import configureStore from './Store/createStore'
import './index.css';
import App from './App';
import { BrowserRouter, } from "react-router-dom";


import configureI18n from "./i18nextConf"
import { I18nextProvider } from "react-i18next";

const store = configureStore();
const i18next = configureI18n("", {});

ReactDOM.render(
    <Provider store={store}>
        <BrowserRouter>
            <I18nextProvider i18n={i18next}>
                <App store={store} />
            </I18nextProvider>
        </BrowserRouter>
    </Provider>, document.getElementById('root')
);
