import * as actions from '../Actions/actionStep2'

//Violazioni che rientrano nell’ambito di applicazione degli atti dell’Unione di cui all’allegato relativamente ai seguenti settori
export const getViolazioni =  async (store) => {
 
    const apiUrl = './api/Violation?Language=' + store.getState().ticketReducer.language;

        await fetch(apiUrl, {
            method: 'GET',
            credentials: 'same-origin',
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Access-Control-Allow-Origin': '*'
            }
        })
        .then(response => response.json())
        .then((result) => {
            store.dispatch(actions.LoadViolations(result));

        })
        .catch((error) => {
            console.log('getViolazioni Fecth failed : ', error);
            return Promise.reject();
        });
    
  };


  