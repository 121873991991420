import Button from '@material-ui/core/Button';
import { useTranslation, withTranslation } from "react-i18next";

function ButtonSection(props) {
    const { previousPage, nextPage, namePrevious, nameNext, disabledNext } = props;
    const { t } = useTranslation();

    return(
        <div>
            <div className="Button">
                <div className="col-6">
                    <div className="center">
                        <Button 
                            style={{ width: "60%" }}
                            color="primary" 
                            variant="contained" 
                            onClick={previousPage}>{t(namePrevious)}
                        </Button>                    
                    </div>
                </div>
                <div className="col-6">
                    <div className="center">
                        <Button 
                            disabled={disabledNext}
                            style={{ width: "60%" }}
                            color="primary" 
                            variant="contained" 
                            onClick={nextPage}>{t(nameNext)}
                        </Button>                    
                    </div> 
                </div>                
            </div> 
        </div>
    )
}

export default withTranslation()(ButtonSection)