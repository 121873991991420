import React, { Component } from 'react'

import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import RadioGroup from '@material-ui/core/RadioGroup';
import Radio from '@material-ui/core/Radio';
import ButtonSection from '../Components/ButtonSection'
import TextField from '@material-ui/core/TextField';
import * as actions from '../Actions/actionStep7'
import { withTranslation } from "react-i18next";
import Typography from '@material-ui/core/Typography'
import * as Helper from '../Helper';


export class WizardPage7 extends Component {
    constructor(props){
        super(props);
                    
        this.state = {                     
            Name : this.props.store.getState().step7Reducer.Name,
            Surname : this.props.store.getState().step7Reducer.Surname,
            Email : this.props.store.getState().step7Reducer.Email,
            Phone : this.props.store.getState().step7Reducer.Phone,
            isSend : this.props.store.getState().step7Reducer.isSend,
            SignallerDataObligation: this.props.store.getState().step1Reducer.SignallerDataObligation,
            disabledNext: this.props.store.getState().step7Reducer.disabledNext && !this.props.store.getState().step1Reducer.SignallerDataObligation, 

            formErrors: {
                Name: "",
                Surname: "",
                Email: "",
                Phone: "",
            },
        }         
    }    
    
    componentWillUnmount() {
        this.unsubscribe();
        }    
        
    componentDidMount = () => {                
        this.unsubscribe = this.props.store.subscribe(() => {              
            this.setState({                                     
                Name : this.props.store.getState().step7Reducer.Name,
                Surname : this.props.store.getState().step7Reducer.Surname,
                Email : this.props.store.getState().step7Reducer.Email,
                Phone : this.props.store.getState().step7Reducer.Phone,
                isSend : this.props.store.getState().step7Reducer.isSend,
                SignallerDataObligation: this.props.store.getState().step1Reducer.SignallerDataObligation,
                disabledNext: this.props.store.getState().step7Reducer.disabledNext && !this.props.store.getState().step1Reducer.SignallerDataObligation, 
            })
        })
    }     
   
    onNext = (event) => { 
     
        this.props.nextPage(event);

    };  
    previousPage = (e) => {            
        this.props.previousPage(e);
    };  
    onTextChange = (e) => { 
        const { t } = this.props;

        //console.log(e.target.name);
        switch(e.target.name)
        {
            case "Name":{

                this.props.store.dispatch(actions.updateName(
                    e.target.value));                   
                break;
            }     
            case "Surname":{

                this.props.store.dispatch(actions.updateSurname(
                    e.target.value));                   
                break;
            }   
            case "Email":{

                this.props.store.dispatch(actions.updateEmail(
                    e.target.value));                   
                break;
            }
            case "Phone":{

                this.props.store.dispatch(actions.updatePhone(
                    e.target.value));                   
                break;
            }
            default:
                break;                                                
        }  

        this.setErrors(e);

        } 

    setErrors = (e) => {
        const { t } = this.props;
        const { Name, Surname, Email, Phone, formErrors, isSend, SignallerDataObligation } = this.state;

        var tmpErrors = formErrors;
        if ((e.target.name === "SendPersonalData" && e.target.value === "Si") || isSend === "Si" || SignallerDataObligation === true) {
            formErrors.Name = (Name == "") ? t(Helper.FIELD_MANDATARY) : "";
            formErrors.Surname = (Surname == "") ? t(Helper.FIELD_MANDATARY) : "";
            formErrors.Email = (Email == "") ? t(Helper.FIELD_MANDATARY) : "";
            formErrors.Phone = (Phone == "") ? t(Helper.FIELD_MANDATARY) : "";

            switch (e.target.name) {
                case "Name":
                    formErrors.Name = (e.target.value == "") ? t(Helper.FIELD_MANDATARY) : "";
                    break;
                case "Surname":
                    formErrors.Surname = (e.target.value == "") ? t(Helper.FIELD_MANDATARY) : "";
                    break;
                case "Email":
                    formErrors.Email = (e.target.value == "") ? t(Helper.FIELD_MANDATARY) : "";
                    break;
                case "Phone":
                    formErrors.Phone = (e.target.value == "") ? t(Helper.FIELD_MANDATARY) : "";
                    break;
            }
        }
        else {
            formErrors.Name =  "";
            formErrors.Surname = "";
            formErrors.Email = "";
            formErrors.Phone = "";
        }

        this.setState({ formErrors: formErrors });

        this.props.store.dispatch(actions.setDisabled(formErrors.Name != '' || formErrors.Surname != '' || formErrors.Email != '' || formErrors.Phone != '' ));

    }

    onChange_DocAssenti = (e) => { 
        /*
        this.props.store.dispatch(actions.updateDocAssenti(
            e.target.checked));       */  
    } 

    onChange_SendPersonalData = (e) => {

        this.props.store.dispatch(actions.updateSend(
            e.target.value));

        if (e.target.value === 'No') {
            this.props.store.dispatch(actions.setDisabled(false));
            this.props.store.dispatch(actions.updateName(""));                   
            this.props.store.dispatch(actions.updateSurname(""));                   
            this.props.store.dispatch(actions.updateEmail(""));                   
            this.props.store.dispatch(actions.updatePhone(""));                   
        }
        else
            this.setErrors(e);
    }
    
    renderPersonalDataConsent = () => {
        const { t } = this.props;
        const { Name, Surname, Email, Phone, formErrors, isSend, SignallerDataObligation } = this.state;

        this.state.disabledNext = SignallerDataObligation && (Name === '' || Surname === '' || Email === '' || Phone === '');

        if (SignallerDataObligation === false)
            return (
                <div className="row">
                    <Typography variant="h6">{t('Label_Send_Your_Data')}</Typography>
                    <FormControl component="fieldset">
                        <FormLabel component="legend"></FormLabel>
                        <RadioGroup aria-label="SendPersonalData" name="SendPersonalData" value={isSend} onChange={this.onChange_SendPersonalData}>
                            <FormControlLabel value="Si" control={<Radio />} label={t('Label_PublicAdmin_Yes')} />
                            <FormControlLabel value="No" control={<Radio />} label={t('Label_PublicAdmin_No')} />
                        </RadioGroup>
                    </FormControl>
                </div>
            );

        return (
            <div className="row">
                <Typography variant="h6">{t('Label_Send_Your_Data_Obligation')}</Typography>
            </div>
        );

    }
    renderPersonalData = () => { 
        const { Name, Surname, Email, Phone, isSend, SignallerDataObligation, formErrors } = this.state;
        const { t } = this.props;

        if (isSend === 'Si' || SignallerDataObligation === true)
            return (
                <div>
                    <TextField disabled={this.props.disabled} className="width100" id="Name" name="Name" label={t('Label_Name')} value={Name} onChange={this.onTextChange} />
                    {SignallerDataObligation === true && formErrors.Name && formErrors.Name.length > 0 && (
                        <div className="errorMessage">{formErrors.Name}</div>
                    )}                           

                    <TextField disabled={this.props.disabled} className="width100" id="Surname" name="Surname" label={t('Label_Surname')} value={Surname} onChange={this.onTextChange} />
                    {SignallerDataObligation === true && formErrors.Surname && formErrors.Surname.length > 0 && (
                        <div className="errorMessage">{formErrors.Surname}</div>
                    )}                           

                    <TextField disabled={this.props.disabled} className="width100" id="Email" name="Email" label={t('Label_Mail')} value={Email} onChange={this.onTextChange} />
                    {SignallerDataObligation === true && formErrors.Email && formErrors.Email.length > 0 && (
                        <div className="errorMessage">{formErrors.Email}</div>
                    )}                           

                    <TextField disabled={this.props.disabled} className="width100" id="Phone" name="Phone" label={t('Label_Phone')} value={Phone} onChange={this.onTextChange} />
                    {SignallerDataObligation === true && formErrors.Phone && formErrors.Phone.length > 0 && (
                        <div className="errorMessage">{formErrors.Phone}</div>
                    )}                           
                </div>
            );

            return ''
    }
    render() {
        const { isSend } = this.state;
        const { t } = this.props;
        return(
            <div>
                <div className="main">                             
                    <div className="row">
                        <Typography variant="h6">{t('Label_Identity_Confidentiality')}</Typography>
                    </div>
                    {this.renderPersonalDataConsent()}
                    <div className="row">
                        {this.renderPersonalData()}
                    </div>                                                                                                         
                </div>    
                {this.props.previousPage.length > 0 &&          
                <ButtonSection 
                        disabledNext={this.state.disabledNext}
                        previousPage={this.previousPage}   
                        nextPage={this.props.nextPage}                                                 
                        namePrevious={this.props.namePrevious}                            
                        nameNext={this.props.nameNext}
                    />  
                }
            </div>
        )
    }
}

export default withTranslation()(WizardPage7)