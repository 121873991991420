export const RETURN = 'RETURN'

export const NEXT_1 = 'NEXT_1'
export const NEXT_2 = 'NEXT_2'
export const NEXT_3 = 'NEXT_3'

export const REMOVE_2 = 'REMOVE_2'
export const REMOVE_3 = 'REMOVE_3'
export const ADD_2 = 'ADD_2'
export const ADD_3 = 'ADD_3'
export const ADD_NEW_PROCESS = 'ADD_NEW_PROCESS'
export const UPDATE_ROWS = 'UPDATE_ROWS'
export const UPDATE_DESCRIPTION = 'UPDATE_DESCRIPTION'
export const ADD_FILE = 'ADD_FILE'
export const REMOVE_FILE = 'REMOVE_FILE'
export const ADD_DOC_SEGNALATI = 'ADD_DOC_SEGNALATI'
export const ADD_DOC_PERCORSO = 'ADD_DOC_PERCORSO'
export const ADD_DOC_ALTRA_MODALITA = 'ADD_DOC_ALTRA_MODALITA'
export const ADD_SEGNALA_AUTORITA = 'ADD_SEGNALA_AUTORITA'
export const ADD_QUALE_AUTORITA = 'ADD_QUALE_AUTORITA'
export const ADD_DOC_ASSENTI = 'ADD_DOC_ASSENTI'
export const UPDATE_CODE = 'UPDATE_CODE'
export const UPDATE_KEYCODE = 'UPDATE_KEYCODE'
export const UPDATE_NAME = 'UPDATE_NAME'
export const UPDATE_SURNAME = 'UPDATE_SURNAME'
export const UPDATE_EMAIL = 'UPDATE_EMAIL'
export const UPDATE_PHONE = 'UPDATE_PHONE'
export const UPDATE_SEND = 'UPDATE_SEND'

export const LOAD = 'LOAD'
export const SHOW_DIALOG_STATE = 'SHOW_DIALOG_STATE'
export const SHOW_DIALOG_CONVOCATION = 'SHOW_DIALOG_CONVOCATION'
export const SHOW_DIALOG_DOCUMENT = 'SHOW_DIALOG_DOCUMENT'
export const SHOW_DIALOG_INFO = "SHOW_DIALOG_INFO"
export const ADD_ADDITIONAL_DOC = 'ADD_ADDITIONAL_DOC'
export const UPDATE_PRIVACY = 'UPDATE_PRIVACY'
export const UPDATE_IDENTITY = 'UPDATE_IDENTITY'
export const UPDATE_CANALE = 'UPDATE_CANALE'

export const LOAD_CATEGORIES = 'LOAD_CATEGORIES'
export const LOAD_LEGALENTITY = 'LOAD_LEGALENTITY'
export const LOAD_PROCESS = 'LOAD_PROCESS'
export const LOAD_VIOLATIONS = 'LOAD_VIOLATIONS'
export const LOAD_CANALI = 'LOAD_CANALI'
export const CLEAN = 'CLEAN'
export const LOAD_STATE = 'LOAD_STATE'
export const UPDATE_STATE = 'UPDATE_STATE'
export const LEGAL_LABEL = 'LEGAL_LABEL'
export const CATEGORY_LABEL = 'CATEGORY_LABEL'
export const SET_APPOINT = 'SET_APPOINT'
export const SET_DOCUMENTS = 'SET_DOCUMENTS'
export const SET_INFO = "SET_INFO"
export const READ_KEY = "READ_KEY"
export const SET_PREVALUTATION = "SET_PREVALUTATION"
export const LOADING = "LOADING"
export const ERROR = "ERROR"
export const UPDATE_REPORT_FROM_OTHER_CHANEL = "UPDATE_REPORT_FROM_OTHER_CHANEL"
export const SET_ENCRYPTED_ESC = "SET_ENCRYPTED_ESC"
export const SET_LINK = "SET_LINK"
export const SET_FILE_MAX_SIZE = "SET_FILE_MAX_SIZE"
export const UPDATE_DISABLED = "UPDATE_DISABLED"
export const SIGNALLERDATA_OBLIGATION = 'SIGNALLERDATA_OBLIGATION'
export const UPDATE_DISABLED_STEP8 = 'UPDATE_DISABLED_STEP8'
export const SIGNALLERIDENTITY_CONSENT = 'SIGNALLERIDENTITY_CONSENT'
