import * as openpgp from 'openpgp';
import * as ticket from '../Actions/ticketActions'

/*
 * 
 * @param {any} value
 */
export const encryptedFunction = async (value, key) => {

    try {
        //return value;
        //console.log("KEY :", key);

        if (value !== "" && key !== "") {
            var publicKeyArmored = key;

            var publicKey = await openpgp.readKey({ armoredKey: publicKeyArmored });

            //console.log("publicKeyArmored :  ", publicKeyArmored);
            var options = {
                message: await openpgp.createMessage({ text: value }),
                encryptionKeys: publicKey
            };

            //console.log("options :  ", options);
            const encrypted = await openpgp.encrypt(options);
            //console.log("encrypted :  ", encrypted);
            return encrypted;
        }
        else
            return value;
    }
    catch (error) {
        console.error(error);
    }
    
    return "#ERROR#";    
};

export const encryptedFunctionBinary = async (value, key) => {

    try {
        //return value;
        //console.log("KEY :", key);

        if (value !== "" && key !== "") {
            var publicKeyArmored = key;

            var publicKey = await openpgp.readKey({ armoredKey: publicKeyArmored });

            //console.log("publicKeyArmored :  ", publicKeyArmored);
            var options = {
                message: await openpgp.createMessage({ binary: value }),
                encryptionKeys: publicKey
            };

            //console.log("options :  ", options);
            const encrypted = await openpgp.encrypt(options);
            //console.log("encrypted :  ", encrypted);
            return encrypted;
        }
        else
            return value;
    }
    catch (error) {
        console.error(error);
    }

    return "#ERROR#";
};
/*
 * 
 * @description Get public key form WB Api
 */
export const readKey = async () => {
    return new Promise(function (resolve, reject) {
         
        const apiUrl = './api/key';

        fetch(apiUrl, {
            method: 'GET',
            credentials: 'same-origin',
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Access-Control-Allow-Origin': '*'
            }
        })
        .then(response => response.json())
        .then((res) => {
            //console.log(res);
            resolve(res);
        })
        .catch((error) => {
            console.log("Key reading error : ", error);

            reject(error);
        });
    })
}
