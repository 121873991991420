import * as actionTypes from './actionTypes';

export function next(Category,Violazioni) {
    return {
       type: actionTypes.NEXT_2,
       payload : {Category,Violazioni}
    }
 }

 export function remove(Violazione) {
   return {
      type: actionTypes.REMOVE_2,
      payload : Violazione
   }
} 

export function removeAll() {
    return {
        type: "REMOVE_ALL_VIOLATIONS",
        payload: ""
    }
}
export function Add(Violazione) {
   return {
      type: actionTypes.ADD_2,
      payload : Violazione
   }
} 

export function LoadCategories(Categories) {
    return {
        type: actionTypes.LOAD_CATEGORIES,
        payload: Categories
    }
}

export function LoadViolations(value) {
    return {
        type: actionTypes.LOAD_VIOLATIONS,
        payload: value
    }
}

export function setDisabled(values) {
    return {
        type: "UPDATE_DISABLED_STEP3",
        payload: values
    }
}

export function AddNewViolazione(newProcess) {
    return {
        type: "ADD_NEW_VIOLAZIONI",
        payload: newProcess
    }
} 

export function setCheckNewViolazioneActive(value) {
    return {
        type: "UPDATE_CHECK_VIOLAZIONI",
        payload: value
    }
}

export function clean() {
    return {
        type: actionTypes.CLEAN,
    }
}


